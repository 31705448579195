import Checklist from "@editorjs/checklist"
import Code from "@editorjs/code"
import Delimiter from "@editorjs/delimiter"
import EditorJs, { type OutputData, type API } from "@editorjs/editorjs"
import Embed from "@editorjs/embed"
import Header from "@editorjs/header"
import InlineCode from "@editorjs/inline-code"
import Link from "@editorjs/link"
import List from "@editorjs/list"
import Marker from "@editorjs/marker"
import Paragraph from "@editorjs/paragraph"
import Quote from "@editorjs/quote"
import Raw from "@editorjs/raw"
import SimpleImage from "@editorjs/simple-image"
import Table from "@editorjs/table"
import Warning from "@editorjs/warning"

type Args = {
	holder: HTMLElement | string
	data?: OutputData
	onChange?(api: API): void
	placeholder?: string
	readOnly?: boolean
}
export default function editorJs(args: Args) {
	return new EditorJs({
		holder: args.holder,
		data: args.data,
		onChange: args.onChange,
		readOnly: args.readOnly,
		placeholder: args.placeholder,
		tools: {
			embed: { class: Embed, inlineToolbar: true },
			table: { class: Table, inlineToolbar: true },
			paragraph: { class: Paragraph, inlineToolbar: true },
			list: { class: List, inlineToolbar: true },
			warning: { class: Warning, inlineToolbar: true },
			code: { class: Code, inlineToolbar: true },
			linkTool: { class: Link, inlineToolbar: true },
			raw: { class: Raw, inlineToolbar: true },
			header: { class: Header, inlineToolbar: true },
			quote: { class: Quote, inlineToolbar: true },
			marker: { class: Marker, inlineToolbar: true },
			checklist: { class: Checklist, inlineToolbar: true },
			delimiter: { class: Delimiter, inlineToolbar: true },
			inlineCode: { class: InlineCode, inlineToolbar: true },
			simpleImage: { class: SimpleImage, inlineToolbar: true },
		},
	})
}
