import { useTheme } from "styled-components"

const Thumbnail: React.FC = () => {
	const theme = useTheme()

	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 787.82738 453.11195">
			<circle cx="406.69135" cy="6.46689" r="6.46689" fill="#f2f2f2" />
			<circle cx="406.69135" cy="32.24019" r="6.46689" fill="#f2f2f2" />
			<circle cx="433.69135" cy="6.46689" r="6.46689" fill="#f2f2f2" />
			<circle cx="433.69135" cy="32.24019" r="6.46689" fill="#f2f2f2" />
			<circle cx="460.69135" cy="6.46689" r="6.46689" fill="#f2f2f2" />
			<circle cx="460.69135" cy="32.24019" r="6.46689" fill="#f2f2f2" />
			<circle cx="460.69135" cy="58.01349" r="6.46689" fill="#f2f2f2" />
			<circle cx="460.69135" cy="83.7868" r="6.46689" fill="#f2f2f2" />
			<circle cx="487.69135" cy="6.46689" r="6.46689" fill="#f2f2f2" />
			<circle cx="487.69135" cy="32.24019" r="6.46689" fill="#f2f2f2" />
			<circle cx="487.69135" cy="58.01349" r="6.46689" fill="#f2f2f2" />
			<circle cx="487.69135" cy="83.7868" r="6.46689" fill="#f2f2f2" />
			<circle cx="514.69135" cy="6.46689" r="6.46689" fill="#f2f2f2" />
			<circle cx="514.69135" cy="32.24019" r="6.46689" fill="#f2f2f2" />
			<circle cx="541.69135" cy="6.46689" r="6.46689" fill="#f2f2f2" />
			<circle cx="541.69135" cy="32.24019" r="6.46689" fill="#f2f2f2" />
			<path
				d="M945.20043,564.70241H897.19067c0,1.38-49.48,2.5-49.48,2.5a12.85957,12.85957,0,0,0-2.10987,2,12.41031,12.41031,0,0,0-2.90039,8v2a12.50461,12.50461,0,0,0,12.5,12.5h90a12.51087,12.51087,0,0,0,12.5-12.5v-2A12.51734,12.51734,0,0,0,945.20043,564.70241Z"
				transform="translate(-206.08631 -223.44402)"
				fill="#3f3d56"
			/>
			<rect x="688.10933" y="367.76179" width="13" height="84" fill="#3f3d56" />
			<path
				d="M944.19564,674.74913c0,1.40462-19.69947.5433-44,.5433s-44,.86132-44-.5433,19.69947-12.54331,44-12.54331S944.19564,673.3445,944.19564,674.74913Z"
				transform="translate(-206.08631 -223.44402)"
				fill="#3f3d56"
			/>
			<polygon
				points="629.1 440.257 616.84 440.256 611.007 392.968 629.102 392.969 629.1 440.257"
				fill="#ffb8b8"
			/>
			<path
				d="M608.08269,436.75319h23.64384a0,0,0,0,1,0,0v14.88687a0,0,0,0,1,0,0H593.19581a0,0,0,0,1,0,0v0A14.88688,14.88688,0,0,1,608.08269,436.75319Z"
				fill="#2f2e41"
			/>
			<polygon
				points="609.962 433.414 598.265 429.743 606.857 382.878 624.121 388.296 609.962 433.414"
				fill="#ffb8b8"
			/>
			<path
				d="M794.615,648.19284h23.64385a0,0,0,0,1,0,0v14.88687a0,0,0,0,1,0,0H779.72813a0,0,0,0,1,0,0v0A14.88687,14.88687,0,0,1,794.615,648.19284Z"
				transform="translate(26.87877 -432.59753) rotate(17.42262)"
				fill="#2f2e41"
			/>
			<path
				d="M912.36679,556.50519a37.87092,37.87092,0,0,0-1.52-7.02l-2.29-2.88-5.96-7.48-.25976.01c-.44043.02-2.27051.12-5.1001.34h-.03027c-1.75.14-3.89014.32-6.31983.55-.64014.07-1.31006.13-2,.2v10.44h12.5a2.5044,2.5044,0,0,1,2.48,2.18c.00977.11.02.21.02.32a2.501,2.501,0,0,1-2.5,2.5h-72.6001a28.4056,28.4056,0,0,0-2.66015,2,14.88157,14.88157,0,0,0-5.25,7.86c-4.23975,18.64-11.96973,53.4-14.25,63.61005a4.53816,4.53816,0,0,0,1.35009,4.28,4.43615,4.43615,0,0,0,1.47022.91l9.02,3.38a4.79293,4.79293,0,0,0,1.1499.27c.04981,0,.08985.01.14014.01a2.6135,2.6135,0,0,0,.27978.01,4.53212,4.53212,0,0,0,4.21-2.9l7.12012-18.68,10.97022-28.8,4.33984-11.39c5.02979,1.18,47.23,10.52,61.35986-4.53a16.06759,16.06759,0,0,0,3.24024-5.11005A20.80163,20.80163,0,0,0,912.36679,556.50519Z"
				transform="translate(-206.08631 -223.44402)"
				fill="#2f2e41"
			/>
			<path
				d="M924.60654,559.22516c-.21973-.89-.46973-1.81-.75977-2.74l-8.25-10.36-.25976.01c-.52051.03-2.99024.16-6.78028.47-4.2998.36-10.31006.94-17.06006,1.85-.1997.02-.40966.05-.60986.08-.66016.09-1.33008.18-2,.28v1.85h12.5a2.5044,2.5044,0,0,1,2.48,2.18c.00977.11.02.21.02.32a2.501,2.501,0,0,1-2.5,2.5H857.20664c-1.92969.63-3.79,1.29-5.56983,2-9.33984,3.7-16.33008,8.55-17.76025,14.86-.48,2.12-1.00977,4.45-1.56983,6.93-2.08008,9.22-4.60986,20.51-6.8999,30.77v.01c-.47021,2.12-.93994,4.18-1.39014,6.18-1.91015,8.6-3.52978,15.89-4.39013,19.72-.02.11-.03955.21-.06006.32v.02a4.66711,4.66711,0,0,0,.28027,2.29l.00977.00994v.01a4.42979,4.42979,0,0,0,1.30029,1.79,4.59324,4.59324,0,0,0,1.29.75l9.02,3.38a4.5805,4.5805,0,0,0,1.56982.29,4.435,4.435,0,0,0,2.14991-.55,4.501,4.501,0,0,0,2.06005-2.35l19.41993-50.96,3.01025-7.91c5.02979,1.18,47.23,10.51,61.35986-4.53a16.89106,16.89106,0,0,0,4.48-11.07A29.6861,29.6861,0,0,0,924.60654,559.22516Z"
				transform="translate(-206.08631 -223.44402)"
				fill="#2f2e41"
			/>
			<path
				d="M923.49667,471.00519c-.15966-.19-.33007-.38-.50976-.56a24.47215,24.47215,0,0,0-20.97022-7.39,79.36185,79.36185,0,0,0-9.91015,1.95,24.981,24.981,0,0,0-11.17969,6.26995,24.41423,24.41423,0,0,0-7.41016,18.2c.04,1.37.04981,2.77.03028,4.19,0,.66-.01026,1.33-.02,2h9.85986a5.32005,5.32005,0,0,1,1.27.15,5.511,5.511,0,0,1,4.23,5.35v46.52c.66992.19,1.33984.38,2,.58.21.06994.41016.12994.60986.18994.23.07.46.14.68018.21q3.04469.945,5.94971,2h3.26025a2.5044,2.5044,0,0,1,2.48,2.18c.68018.27,1.33985.54,1.98975.81,2.31006.96,4.5,1.92,6.51025,2.85,2.71,1.24,5.12012,2.42,7.14014,3.45a4.58719,4.58719,0,0,0,2.0498.5,4.404,4.404,0,0,0,2.6001-.86,5.531,5.531,0,0,0,.44971-.37,4.5045,4.5045,0,0,0,1.37011-3.98,128.08706,128.08706,0,0,1-.8999-23.7c.27979-6.65.87989-13.47,1.66016-20.06.41992-3.57.88965-7.07,1.37988-10.44.47022-3.22.97022-6.31,1.46-9.23A24.607,24.607,0,0,0,923.49667,471.00519Z"
				transform="translate(-206.08631 -223.44402)"
				fill={theme.primaryColor}
			/>
			<path
				d="M882.50693,471.82519a11.8589,11.8589,0,0,0-1.58008-.55,11.49858,11.49858,0,0,0-13.54,6.53l-6.87012,15.86-.85986,2h23.73a5.32005,5.32005,0,0,1,1.27.15l.79981-1.85,3.04-7.02A11.51151,11.51151,0,0,0,882.50693,471.82519Z"
				transform="translate(-206.08631 -223.44402)"
				fill={theme.primaryColor}
			/>
			<circle cx="697.75644" cy="207.24158" r="24.56103" fill="#ffb8b8" />
			<path
				d="M928.11679,501.04516l-14.02.38-1.02,9.85-.23,2.28-21.96,9.86-2,.9v17.53l2-.57,6.31983-1.8h.03027l27.83984-7.93.31983-.09a10.879,10.879,0,0,0,7.86035-10.35l.06982-11.63.06006-8.57Z"
				transform="translate(-206.08631 -223.44402)"
				fill="#ffb8b8"
			/>
			<path
				d="M932.67685,481.56518a11.50161,11.50161,0,0,0-22.96,1.41l1.52978,24.96a4.48639,4.48639,0,0,0,1.83008,3.34,4.4354,4.4354,0,0,0,2.93994.87l10.72022-.66,3.25-.2a4.385,4.385,0,0,0,3.1001-1.51,3.13661,3.13661,0,0,0,.23974-.3,4.46893,4.46893,0,0,0,.87989-2.96Z"
				transform="translate(-206.08631 -223.44402)"
				fill={theme.primaryColor}
			/>
			<path
				d="M892.17685,548.66516h-1.29v-.13c-.66016.09-1.33008.18-2,.28v1.85h9.23975Q895.21665,549.61515,892.17685,548.66516Zm0,0h-1.29v-.13c-.66016.09-1.33008.18-2,.28v1.85h9.23975Q895.21665,549.61515,892.17685,548.66516Zm9.21,0h-10.5v-47.5a7.51079,7.51079,0,0,0-5.43017-7.2,7.05091,7.05091,0,0,0-2.06983-.3h-89a7.50906,7.50906,0,0,0-7.5,7.5v49a7.50264,7.50264,0,0,0,7.5,7.5h107a4.50458,4.50458,0,0,0,4.46973-4.01,3.90311,3.90311,0,0,0,.03027-.49A4.50681,4.50681,0,0,0,901.38681,548.66516Zm2.5,4.5a2.501,2.501,0,0,1-2.5,2.5h-107a5.50324,5.50324,0,0,1-5.5-5.5v-49a5.50328,5.50328,0,0,1,5.5-5.5h89a5.32005,5.32005,0,0,1,1.27.15,5.511,5.511,0,0,1,4.23,5.35v49.5h12.5a2.5044,2.5044,0,0,1,2.48,2.18C903.87656,552.95514,903.88681,553.05517,903.88681,553.16516Zm-11.71-4.5h-1.29v-.13c-.66016.09-1.33008.18-2,.28v1.85h9.23975Q895.21665,549.61515,892.17685,548.66516Z"
				transform="translate(-206.08631 -223.44402)"
				fill="#3f3d56"
			/>
			<circle cx="632.79741" cy="302.22257" r="6" fill={theme.primaryColor} />
			<path
				d="M898.59849,420.94279c-5.70477,5.09634-13.35364,10.56649-20.33919,6.83271-4.58683-2.45166-6.62894-7.82807-5.55768-12.91748,1.92644-9.15224,9.83844-14.09737,17.49912-18.09485,9.95887-5.19672,20.9487-9.72862,32.10037-8.37761s22.04719,10.56576,21.52059,21.78662c-.42347,9.02359-7.792,17.44162-5.45946,26.1688,2.34752,8.78321,13.00847,12.18439,22.089,12.6312s19.07664-.32741,26.28,5.2194c9.18922,7.076,9.04206,21.87216,2.50039,31.4491s-17.58849,14.9659-28.43879,19.06252c-14.376,5.42776-30.17738,9.34947-45.01669,5.359s-27.83036-18.22717-25.65383-33.43873c.91936-6.42534,4.29979-12.19318,7.562-17.80464s6.54354-11.46254,7.22857-17.91707c.57085-5.37878-1.13769-11.31634-5.10477-14.78115a4.437,4.437,0,0,1-1.25635-5.067Z"
				transform="translate(-206.08631 -223.44402)"
				fill="#2f2e41"
			/>
			<path
				d="M207.277,676.556H980.571a1.19068,1.19068,0,1,0,0-2.38137H207.277a1.19069,1.19069,0,1,0,0,2.38137Z"
				transform="translate(-206.08631 -223.44402)"
				fill="#3f3d56"
			/>
			<path
				d="M246.51632,577.20241v2a12.51087,12.51087,0,0,0,12.5,12.5h90a12.50461,12.50461,0,0,0,12.5-12.5v-2a12.41031,12.41031,0,0,0-2.90039-8,12.85957,12.85957,0,0,0-2.10987-2s-49.48-1.12-49.48-2.5H259.01632A12.51734,12.51734,0,0,0,246.51632,577.20241Z"
				transform="translate(-206.08631 -223.44402)"
				fill="#3f3d56"
			/>
			<rect
				x="297.02111"
				y="591.20582"
				width="13"
				height="84"
				transform="translate(400.9559 1042.96761) rotate(-180)"
				fill="#3f3d56"
			/>
			<path
				d="M260.02111,674.74913c0,1.40462,19.69947.5433,44,.5433s44,.86132,44-.5433-19.69947-12.54331-44-12.54331S260.02111,673.3445,260.02111,674.74913Z"
				transform="translate(-206.08631 -223.44402)"
				fill="#3f3d56"
			/>
			<polygon
				points="163.53 441.018 175.79 441.018 181.622 393.73 163.528 393.731 163.53 441.018"
				fill="#a0616a"
			/>
			<path
				d="M366.98961,660.9589h38.53072a0,0,0,0,1,0,0v14.88687a0,0,0,0,1,0,0H381.87649a14.88688,14.88688,0,0,1-14.88688-14.88688v0A0,0,0,0,1,366.98961,660.9589Z"
				transform="translate(566.45414 1113.34302) rotate(179.99738)"
				fill="#2f2e41"
			/>
			<polygon
				points="182.668 434.176 194.365 430.505 185.773 383.64 168.509 389.058 182.668 434.176"
				fill="#a0616a"
			/>
			<path
				d="M386.5436,648.95453h38.53072a0,0,0,0,1,0,0V663.8414a0,0,0,0,1,0,0H401.43046a14.88687,14.88687,0,0,1-14.88687-14.88687v0a0,0,0,0,1,0,0Z"
				transform="translate(783.45104 937.73147) rotate(162.57738)"
				fill="#2f2e41"
			/>
			<path
				d="M380.55229,635.8551,358.125,576.984c-5.03149,1.18164-47.22656,10.51856-61.35888-4.52832-4.84327-5.15723-5.78785-12.6289-2.80762-22.208l8.24756-10.36425.25634.01367c3.11841.16406,76.43531,4.27344,81.46119,26.38867,4.23755,18.64453,11.97656,53.40527,14.24731,63.61523a4.51574,4.51574,0,0,1-2.814,5.1836l-9.01929,3.38183a4.462,4.462,0,0,1-1.572.28614A4.513,4.513,0,0,1,380.55229,635.8551Z"
				transform="translate(-206.08631 -223.44402)"
				fill="#2f2e41"
			/>
			<path
				d="M367.55229,642.8551,345.125,583.984c-5.03174,1.18164-47.22656,10.51758-61.35888-4.52832-4.84327-5.15723-5.78785-12.6289-2.80762-22.208l8.24756-10.36425.25634.01367c3.11841.16406,76.43531,4.27344,81.46119,26.38867,4.23755,18.64453,11.97656,53.40527,14.24731,63.61523a4.51574,4.51574,0,0,1-2.814,5.1836l-9.01929,3.38183a4.462,4.462,0,0,1-1.572.28614A4.513,4.513,0,0,1,367.55229,642.8551Z"
				transform="translate(-206.08631 -223.44402)"
				fill="#2f2e41"
			/>
			<path
				d="M347.37876,528.63727a19.1964,19.1964,0,0,1-16.4845-9.37158l-7.12255-12.06592,16.92932-9.25293,7.18835,9.856,48.77356-5.13867a10.55928,10.55928,0,0,1,.79919-1.23389h0a11.01566,11.01566,0,0,1,7.8733-4.314,10.89328,10.89328,0,0,1,8.49267,3.0122A11.02275,11.02275,0,0,1,415.688,513.692a11.00369,11.00369,0,0,1-16.1134,3.15088,10.40153,10.40153,0,0,1-.82287-.7002l-46.7146,11.91065A18.83681,18.83681,0,0,1,347.37876,528.63727Z"
				transform="translate(-206.08631 -223.44402)"
				fill="#a0616a"
			/>
			<path
				d="M280.6414,560.35607a4.5069,4.5069,0,0,1-1.8186-4.34863c2.78247-18.34277-.41895-44.61914-3.59473-63.43262A24.79543,24.79543,0,0,1,302.7874,463.8131a79.86812,79.86812,0,0,1,9.91284,1.9541h0a24.59627,24.59627,0,0,1,18.582,24.46778c-.47485,18.1543,3.21142,40.80566,4.81836,49.70117a4.5186,4.5186,0,0,1-.83374,3.51074,4.39222,4.39222,0,0,1-3.09546,1.75c-17.80811,1.81739-37.01221,10.502-46.87451,15.5166a4.50062,4.50062,0,0,1-2.04956.501A4.42884,4.42884,0,0,1,280.6414,560.35607Z"
				transform="translate(-206.08631 -223.44402)"
				fill="#ccc"
			/>
			<path
				d="M328.7122,513.04455a4.4709,4.4709,0,0,1-2.47363-2.39746l-9.9314-22.94239a11.49973,11.49973,0,1,1,21.10718-9.13574l9.93164,22.94239a4.5057,4.5057,0,0,1-2.342,5.917l-12.8479,5.56152a4.46851,4.46851,0,0,1-3.44385.05469Z"
				transform="translate(-206.08631 -223.44402)"
				fill="#ccc"
			/>
			<circle cx="94.87339" cy="208.00327" r="24.56103" fill="#a0616a" />
			<path
				d="M286.11695,453.268a3.97442,3.97442,0,0,1-5.69016.32692,44.42586,44.42586,0,0,1-12.67228-16.54878c-3.19306-7.611-3.661-16.899-.027-24.03305,3.072-6.03078,8.58662-9.86968,14.09195-13.00724a22.99346,22.99346,0,0,1,6.84781-2.89428,8.9936,8.9936,0,0,1,7.154,1.40677,7.54425,7.54425,0,0,1,2.878,7.22724,22.35408,22.35408,0,0,1,9.80208-7.35654,11.36522,11.36522,0,0,1,7.55375-.50594c3.53554,1.18009,6.15253,4.75026,7.60882,8.58606a19.14072,19.14072,0,0,1,1.25546,9.26372,11.12859,11.12859,0,0,1-4.2691,7.67143c-2.88387,1.98988-6.59927,1.78778-9.95313.90708s-6.60888-2.37392-10.02126-2.79024-7.19422.4863-9.22732,3.52991c-1.43176,2.14338-1.7693,4.96687-2.06144,7.65827l-1.51947,13.99863a18.54776,18.54776,0,0,1-1.27762,5.83956A3.25061,3.25061,0,0,1,286.11695,453.268Z"
				transform="translate(-206.08631 -223.44402)"
				fill="#2f2e41"
			/>
			<path
				d="M374.95438,604.53826H335.08186a4.24661,4.24661,0,0,1-3.35526-1.62624,4.31805,4.31805,0,0,1-.80709-3.70456,102.32847,102.32847,0,0,0-.0003-47.52515,4.31916,4.31916,0,0,1,.80739-3.70456,4.24661,4.24661,0,0,1,3.35526-1.62624h39.87252a4.30257,4.30257,0,0,1,4.2371,3.6057,167.50643,167.50643,0,0,1,0,50.97535A4.30257,4.30257,0,0,1,374.95438,604.53826Z"
				transform="translate(-206.08631 -223.44402)"
				fill="#ccc"
			/>
			<path
				d="M344.35194,568.02812v-1.17188a3.00328,3.00328,0,0,1,3-3h20.92358a3.00328,3.00328,0,0,1,3,3v1.17188a3.00328,3.00328,0,0,1-3,3H347.35194A3.00328,3.00328,0,0,1,344.35194,568.02812Z"
				transform="translate(-206.08631 -223.44402)"
				fill="#fff"
			/>
			<path
				d="M344.35194,580.37284v-1.17236a3.00328,3.00328,0,0,1,3-3h20.92358a3.00328,3.00328,0,0,1,3,3v1.17236a3.00328,3.00328,0,0,1-3,3H347.35194A3.00328,3.00328,0,0,1,344.35194,580.37284Z"
				transform="translate(-206.08631 -223.44402)"
				fill="#fff"
			/>
			<path
				d="M357.21432,532.28513a4.39088,4.39088,0,0,1-.82066-3.766,104.00137,104.00137,0,0,0-.0003-48.30591,4.392,4.392,0,0,1,.821-3.766,4.31762,4.31762,0,0,1,3.41038-1.65235H401.152a4.37255,4.37255,0,0,1,4.30671,3.66494,170.23877,170.23877,0,0,1,0,51.81279,4.37255,4.37255,0,0,1-4.30671,3.66494H360.6247A4.31762,4.31762,0,0,1,357.21432,532.28513Z"
				transform="translate(-206.08631 -223.44402)"
				fill={theme.primaryColor}
			/>
			<path
				d="M393.33135,496.61659H371.982a3.00328,3.00328,0,0,1-3-3v-1.27393a3.00328,3.00328,0,0,1,3-3h21.34936a3.00328,3.00328,0,0,1,3,3v1.27393A3.00328,3.00328,0,0,1,393.33135,496.61659Z"
				transform="translate(-206.08631 -223.44402)"
				fill="#fff"
			/>
			<path
				d="M393.33135,509.164H371.982a3.00328,3.00328,0,0,1-3-3V504.89a3.00328,3.00328,0,0,1,3-3h21.34936a3.00328,3.00328,0,0,1,3,3V506.164A3.00328,3.00328,0,0,1,393.33135,509.164Z"
				transform="translate(-206.08631 -223.44402)"
				fill="#fff"
			/>
			<path
				d="M338.61941,554.28326a11.01879,11.01879,0,0,1-6.53393-6.15771,10.25939,10.25939,0,0,1-.35913-1.019l-52.322-14.89307a10.88006,10.88006,0,0,1-7.86109-10.34277l-.12768-20.20166,19.28589.51611,1.25683,12.13428L336.699,534.40631a10.56725,10.56725,0,0,1,1.31153-.66455h.00024a11.01,11.01,0,1,1,.60864,20.5415Z"
				transform="translate(-206.08631 -223.44402)"
				fill="#a0616a"
			/>
			<path
				d="M271.71557,510.5377a4.47088,4.47088,0,0,1-1.11986-3.25769l1.534-24.95261a11.49972,11.49972,0,1,1,22.956,1.41237L293.552,508.69248a4.5057,4.5057,0,0,1-4.76751,4.21507l-13.97358-.85916a4.46853,4.46853,0,0,1-3.09531-1.51069Z"
				transform="translate(-206.08631 -223.44402)"
				fill="#ccc"
			/>
			<path
				d="M496.60838,376.50788h-82a9.01031,9.01031,0,0,1-9-9v-89a9.0103,9.0103,0,0,1,9-9h82a9.01031,9.01031,0,0,1,9,9v89A9.01032,9.01032,0,0,1,496.60838,376.50788Zm-82-105a7.00785,7.00785,0,0,0-7,7v89a7.00786,7.00786,0,0,0,7,7h82a7.00787,7.00787,0,0,0,7-7v-89a7.00786,7.00786,0,0,0-7-7Z"
				transform="translate(-206.08631 -223.44402)"
				fill="#3f3d56"
			/>
			<path
				d="M646.60838,376.50788h-82a9.01031,9.01031,0,0,1-9-9v-89a9.0103,9.0103,0,0,1,9-9h82a9.01031,9.01031,0,0,1,9,9v89A9.01032,9.01032,0,0,1,646.60838,376.50788Zm-82-105a7.00785,7.00785,0,0,0-7,7v89a7.00786,7.00786,0,0,0,7,7h82a7.00787,7.00787,0,0,0,7-7v-89a7.00786,7.00786,0,0,0-7-7Z"
				transform="translate(-206.08631 -223.44402)"
				fill="#3f3d56"
			/>
			<path
				d="M431.1234,306.50788h-8a4.505,4.505,0,0,1-4.5-4.5v-8a4.505,4.505,0,0,1,4.5-4.5h8a4.505,4.505,0,0,1,4.5,4.5v8A4.505,4.505,0,0,1,431.1234,306.50788Z"
				transform="translate(-206.08631 -223.44402)"
				fill={theme.primaryColor}
			/>
			<path
				d="M431.1234,331.50788h-8a4.505,4.505,0,0,1-4.5-4.5v-8a4.505,4.505,0,0,1,4.5-4.5h8a4.505,4.505,0,0,1,4.5,4.5v8A4.505,4.505,0,0,1,431.1234,331.50788Z"
				transform="translate(-206.08631 -223.44402)"
				fill="#ccc"
			/>
			<path
				d="M489.59335,301.64484H452.244a3.00328,3.00328,0,0,1-3-3v-1.27393a3.00328,3.00328,0,0,1,3-3h37.34936a3.00328,3.00328,0,0,1,3,3v1.27393A3.00328,3.00328,0,0,1,489.59335,301.64484Z"
				transform="translate(-206.08631 -223.44402)"
				fill={theme.primaryColor}
			/>
			<path
				d="M581.8131,304.9394h-8a4.505,4.505,0,0,1-4.5-4.5v-8a4.505,4.505,0,0,1,4.5-4.5h8a4.505,4.505,0,0,1,4.5,4.5v8A4.505,4.505,0,0,1,581.8131,304.9394Z"
				transform="translate(-206.08631 -223.44402)"
				fill="#ccc"
			/>
			<path
				d="M640.28306,300.07636H602.93369a3.00328,3.00328,0,0,1-3-3v-1.27393a3.00329,3.00329,0,0,1,3-3h37.34937a3.00329,3.00329,0,0,1,3,3v1.27393A3.00328,3.00328,0,0,1,640.28306,300.07636Z"
				transform="translate(-206.08631 -223.44402)"
				fill="#ccc"
			/>
			<path
				d="M640.28306,324.07636H570.93369a3.00328,3.00328,0,0,1-3-3v-1.27393a3.00329,3.00329,0,0,1,3-3h69.34937a3.00329,3.00329,0,0,1,3,3v1.27393A3.00328,3.00328,0,0,1,640.28306,324.07636Z"
				transform="translate(-206.08631 -223.44402)"
				fill={theme.primaryColor}
			/>
			<path
				d="M640.28306,341.07636H570.93369a3.00328,3.00328,0,0,1-3-3v-1.27393a3.00329,3.00329,0,0,1,3-3h69.34937a3.00329,3.00329,0,0,1,3,3v1.27393A3.00328,3.00328,0,0,1,640.28306,341.07636Z"
				transform="translate(-206.08631 -223.44402)"
				fill="#ccc"
			/>
			<path
				d="M640.28306,358.07636H570.93369a3.00328,3.00328,0,0,1-3-3v-1.27393a3.00329,3.00329,0,0,1,3-3h69.34937a3.00329,3.00329,0,0,1,3,3v1.27393A3.00328,3.00328,0,0,1,640.28306,358.07636Z"
				transform="translate(-206.08631 -223.44402)"
				fill="#ccc"
			/>
			<path
				d="M796.60838,376.50788h-82a9.01031,9.01031,0,0,1-9-9v-89a9.0103,9.0103,0,0,1,9-9h82a9.01031,9.01031,0,0,1,9,9v89A9.01032,9.01032,0,0,1,796.60838,376.50788Zm-82-105a7.00785,7.00785,0,0,0-7,7v89a7.00786,7.00786,0,0,0,7,7h82a7.00787,7.00787,0,0,0,7-7v-89a7.00786,7.00786,0,0,0-7-7Z"
				transform="translate(-206.08631 -223.44402)"
				fill="#3f3d56"
			/>
			<path
				d="M790.28306,300.07636H752.93369a3.00328,3.00328,0,0,1-3-3v-1.27393a3.00329,3.00329,0,0,1,3-3h37.34937a3.00329,3.00329,0,0,1,3,3v1.27393A3.00328,3.00328,0,0,1,790.28306,300.07636Z"
				transform="translate(-206.08631 -223.44402)"
				fill="#ccc"
			/>
			<path
				d="M790.28306,324.07636H720.93369a3.00328,3.00328,0,0,1-3-3v-1.27393a3.00329,3.00329,0,0,1,3-3h69.34937a3.00329,3.00329,0,0,1,3,3v1.27393A3.00328,3.00328,0,0,1,790.28306,324.07636Z"
				transform="translate(-206.08631 -223.44402)"
				fill="#ccc"
			/>
			<path
				d="M790.28306,341.07636H720.93369a3.00328,3.00328,0,0,1-3-3v-1.27393a3.00329,3.00329,0,0,1,3-3h69.34937a3.00329,3.00329,0,0,1,3,3v1.27393A3.00328,3.00328,0,0,1,790.28306,341.07636Z"
				transform="translate(-206.08631 -223.44402)"
				fill="#ccc"
			/>
			<path
				d="M790.28306,358.07636H720.93369a3.00328,3.00328,0,0,1-3-3v-1.27393a3.00329,3.00329,0,0,1,3-3h69.34937a3.00329,3.00329,0,0,1,3,3v1.27393A3.00328,3.00328,0,0,1,790.28306,358.07636Z"
				transform="translate(-206.08631 -223.44402)"
				fill="#ccc"
			/>
			<path
				d="M489.59335,326.64484H452.244a3.00328,3.00328,0,0,1-3-3v-1.27393a3.00328,3.00328,0,0,1,3-3h37.34936a3.00328,3.00328,0,0,1,3,3v1.27393A3.00328,3.00328,0,0,1,489.59335,326.64484Z"
				transform="translate(-206.08631 -223.44402)"
				fill="#ccc"
			/>
			<path
				d="M431.1234,356.50788h-8a4.505,4.505,0,0,1-4.5-4.5v-8a4.505,4.505,0,0,1,4.5-4.5h8a4.505,4.505,0,0,1,4.5,4.5v8A4.505,4.505,0,0,1,431.1234,356.50788Z"
				transform="translate(-206.08631 -223.44402)"
				fill="#ccc"
			/>
			<path
				d="M489.59335,351.64484H452.244a3.00328,3.00328,0,0,1-3-3v-1.27393a3.00328,3.00328,0,0,1,3-3h37.34936a3.00328,3.00328,0,0,1,3,3v1.27393A3.00328,3.00328,0,0,1,489.59335,351.64484Z"
				transform="translate(-206.08631 -223.44402)"
				fill="#ccc"
			/>
			<path
				d="M736.605,295.50788c0-.02307.00342-.04535.00342-.06848a8.99989,8.99989,0,0,0-9-9v9.06848Z"
				transform="translate(-206.08631 -223.44402)"
				fill={theme.primaryColor}
			/>
			<path
				d="M726.60838,296.50788V287.4394a9,9,0,1,0,8.99658,9.06848Z"
				transform="translate(-206.08631 -223.44402)"
				fill="#ccc"
			/>
		</svg>
	)
}

export default Thumbnail
