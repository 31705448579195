import styled from "styled-components"

import CommunityProfileAvatar from "@forento/shared/components/CommunityProfileAvatar"
import { type PublicCommunityProfile } from "@forento/shared/models/community"
import { toCountFormat } from "@forento/shared/utilities/number"

import Button from "~/themes/original/components/Button"
import routes from "~/utilities/routes"

const Profile: React.FC<{ profile: PublicCommunityProfile; position: number }> = ({ profile, position }) => (
	<Container onClick={routes.community.profile(profile.id)}>
		<div>{position}</div>
		<CommunityProfileAvatar profile={profile} />
		<div>
			{profile.firstName} {profile.lastName}
		</div>
		<div>
			{toCountFormat(profile.gamification.points)} points
			{profile.gamification.currentLevel !== null && <> &ndash; {profile.gamification.currentLevel.label}</>}
		</div>
	</Container>
)

const Container = styled(Button)`
	padding: 16px 24px;
	background-color: white;
	border-radius: 8px;
	display: grid;
	grid-template-columns: 32px min-content 1fr max-content;
	column-gap: 16px;
	align-items: center;
	justify-content: space-between;
`

export default Profile
