import { Fragment } from "react"
import styled from "styled-components"

import { usePrivacyPolicy } from "~/api/privacyPolicy"
import Layout from "~/themes/original/components/Layout"

const PrivacyPolicyPage: React.FC = () => {
	const content = usePrivacyPolicy()

	return (
		<Layout>
			<Title>Privacy Policy</Title>
			{content.introItems.map((item, index) => (
				<Text key={index}>{item}</Text>
			))}
			{content.sections.map((section, index) => (
				<Fragment key={index}>
					<Subtitle>{section.title}</Subtitle>
					{section.items.map((item, index) => (
						<Text key={index}>{item}</Text>
					))}
				</Fragment>
			))}
		</Layout>
	)
}

const Title = styled.h1`
	font-weight: 500;
	font-size: 36px;
	color: #151d48;
	margin-bottom: 24px;

	@media (max-width: 999px) {
		margin-left: 16px;
		margin-right: 16px;
	}
`

const Text = styled.p`
	font-size: 14px;
	margin-bottom: 16px;
`

const Subtitle = styled.h2`
	font-weight: 600;
	font-size: 20px;
	color: #151d48;
	margin-bottom: 8px;
`

export default PrivacyPolicyPage
