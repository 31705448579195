import { type ReactNode, useContext, useMemo, createContext, type FC } from "react"

import { type PlatformUser } from "@forento/shared/models/user"

import trpc, { swr } from "~/utilities/trpc"

type Value = { user: PlatformUser | null; reload(): Promise<void>; signOut(): Promise<void> }

const UserContext = createContext<Value>({
	user: initialUser,
	reload: async () => {},
	signOut: async () => {},
})

export const useUser = () => useContext(UserContext)

export const UserContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
	const { data: user, mutate } = swr.user.getUser.useSWR()

	const value: Value = useMemo(
		() => ({
			user: user !== undefined ? user : initialUser,
			async reload() {
				await mutate()
			},
			async signOut() {
				await trpc.user.signOut.mutate()
				await mutate()
			},
		}),
		[mutate, user],
	)

	return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}
