export const supportedFileExtensions = {
	image: ["PNG", "JPG", "JPEG", "GIF", "SVG"],
	spreadsheet: ["CSV", "XLS", "XLSX"],
}

export function getFileNameFromPath(filePath: string): string | null {
	return filePath.split("/").pop()?.toLowerCase() ?? null
}

export function getExtensionByFileName(fileName: string): string | null {
	return fileName.split(".").pop()?.toLowerCase() ?? null
}

export async function getDataUrlByFile(file: File) {
	const reader = new FileReader()
	reader.readAsDataURL(file)
	await new Promise<unknown>(resolve => {
		reader.onload = resolve
	})
	return reader.result as string
}
