import { useLayoutEffect } from "react"

import editorJs from "@forento/shared/utilities/editorJs"

export default function useEditorJsDisplay(editorElement: HTMLElement | null, data: string | null) {
	useLayoutEffect(() => {
		const editor = editorElement
			? editorJs({
					holder: editorElement,
					data: data !== null ? JSON.parse(data) : undefined,
					readOnly: true,
				})
			: null

		return () => {
			editor?.destroy?.()
		}
	}, [editorElement, data])
}
