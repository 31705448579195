export function toPriceString({ amount, currency }: { amount: number; currency: string }) {
	return `${amount.toLocaleString("en-US", {
		style: "currency",
		currency,
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	})}`
}

export function toPriceStringUSD(amount: number) {
	return toPriceString({ amount, currency: "USD" })
}

export function toPercentString(value: number) {
	return `${value.toLocaleString("en-US", { style: "percent" })}`
}
