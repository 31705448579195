import { useTheme } from "styled-components"

const CompletedImage: React.FC = () => {
	const theme = useTheme()

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 769.15018 500"
			style={{ width: 256, maxWidth: "100%", marginBottom: 32 }}
		>
			<g fill={theme.primaryColor}>
				<path
					d="M824.50511,697.74951H809.74559a6.50736,6.50736,0,0,1-6.5-6.5V568.23535a6.50737,6.50737,0,0,1,6.5-6.5h14.75952a6.50737,6.50737,0,0,1,6.5,6.5V691.24951A6.50736,6.50736,0,0,1,824.50511,697.74951Z"
					transform="translate(-215.42491 -200)"
				/>
				<path
					d="M784.313,697.74951H769.55321a6.50736,6.50736,0,0,1-6.5-6.5V568.23535a6.50737,6.50737,0,0,1,6.5-6.5H784.313a6.50737,6.50737,0,0,1,6.5,6.5V691.24951A6.50736,6.50736,0,0,1,784.313,697.74951Z"
					transform="translate(-215.42491 -200)"
				/>
				<path
					d="M782.079,493.48968a14.09088,14.09088,0,0,1-1.28017.78586L679.18236,549.46112a6.49966,6.49966,0,0,1-8.8141-2.60986l-7.0439-12.97044.43938-.23845-.43938.23845a6.50688,6.50688,0,0,1,2.6098-8.81376l101.61646-55.18558a13.88068,13.88068,0,0,1,14.52837,23.6082Z"
					transform="translate(-215.42491 -200)"
				/>
				<circle cx="582.66336" cy="182.35661" r="51" />
				<path
					id="efe8c2a6-7c83-4734-b3a8-78abd7d8e319-118"
					data-name="Path 395"
					d="M394.16419,381.11421a13.12251,13.12251,0,0,1-7.89453-2.62369l-.14122-.10592-29.73486-22.74621a13.214,13.214,0,0,1,16.07928-20.97359l19.25985,14.76935,45.512-59.37552a13.20838,13.20838,0,0,1,18.51877-2.44608l.00383.00287-.28245.3922.29011-.3922a13.22432,13.22432,0,0,1,2.44321,18.52249l-53.53212,69.80743a13.21635,13.21635,0,0,1-10.51031,5.15319Z"
					transform="translate(-215.42491 -200)"
				/>
				<path
					d="M843.68689,602.40051a6.51661,6.51661,0,0,1-6.07211,3.61966l-14.74939-.542a6.50739,6.50739,0,0,1-6.25653-6.73443l4.24773-115.55658a13.87971,13.87971,0,1,1,27.74069,1.0198l-4.24817,115.55635A6.47656,6.47656,0,0,1,843.68689,602.40051Z"
					transform="translate(-215.42491 -200)"
				/>
			</g>
			<g fill="#2f2e41">
				<path
					d="M790.5554,668.5h-29a4.50508,4.50508,0,0,1-4.5-4.5V563a4.50508,4.50508,0,0,1,4.5-4.5h29a4.50507,4.50507,0,0,1,4.5,4.5V664A4.50507,4.50507,0,0,1,790.5554,668.5Z"
					transform="translate(-215.42491 -200)"
				/>
				<path
					d="M831.5554,668.5h-29a4.50508,4.50508,0,0,1-4.5-4.5V563a4.50508,4.50508,0,0,1,4.5-4.5h29a4.50507,4.50507,0,0,1,4.5,4.5V664A4.50507,4.50507,0,0,1,831.5554,668.5Z"
					transform="translate(-215.42491 -200)"
				/>
				<path
					d="M804.05074,403.76555c-3.30591-.0918-7.42029-.20654-10.59-2.522a8.13276,8.13276,0,0,1-3.20008-6.07276,5.47084,5.47084,0,0,1,1.86036-4.49315c1.65551-1.39894,4.073-1.72706,6.67822-.96144l-2.69922-19.72558,1.98144-.27149,3.17322,23.18994-1.65466-.75927c-1.91834-.87989-4.55164-1.32764-6.188.05517a3.51514,3.51514,0,0,0-1.15271,2.89551,6.14687,6.14687,0,0,0,2.38123,4.52783c2.46667,1.80176,5.74621,2.03418,9.46582,2.13818Z"
					transform="translate(-215.42491 -200)"
				/>
				<rect x="560.10435" y="171.69182" width="10.77161" height="2" />
				<rect x="594.10435" y="171.69182" width="10.77161" height="2" />
				<path
					d="M801.65638,331.20576a42.16092,42.16092,0,0,1,16.48679-30.88561,41.06467,41.06467,0,0,1,35.01478-6.678,40.44122,40.44122,0,0,1,26.88253,22.93858,42.10753,42.10753,0,0,1,3.61013,17.69246,47.83352,47.83352,0,0,1-4.83068,19.06711c-3.00717,6.55216-6.8,12.69926-10.094,19.10425a152.46164,152.46164,0,0,0-8.37722,19.557A154.17872,154.17872,0,0,0,852.027,434.3754c-1.22412,22.77069.95445,56.01429,26.66,64.753,6.5368,2.22223,13.58848,2.636,20.442,2.61783a83.95189,83.95189,0,0,0,21.42037-2.509c12.13546-3.24045,23.65553-10.84961,28.8264-22.67174a31.5533,31.5533,0,0,0,2.373-8.56911c.10762-.80991-.76661-1.49235-1.5-1.5a1.53686,1.53686,0,0,0-1.5,1.5c-1.67411,12.59789-12.17164,22.25885-23.56929,26.60212a67.69689,67.69689,0,0,1-20.7358,4.01122c-7.04627.356-14.36228.30089-21.26181-1.32054a30.79222,30.79222,0,0,1-16.01387-9.1029,41.40144,41.40144,0,0,1-8.3594-15.12233c-4.05745-12.38511-4.48264-26.00217-3.76968-38.91066a151.26183,151.26183,0,0,1,8.49774-42.19341,147.93429,147.93429,0,0,1,8.80388-19.9579c3.34244-6.28626,7.05366-12.39483,9.86986-18.94723a49.44351,49.44351,0,0,0,4.44456-19.15341,45.24365,45.24365,0,0,0-3.5757-17.79309,43.37043,43.37043,0,0,0-25.81022-24.36088,44.64078,44.64078,0,0,0-36.0158,2.95013,45.38509,45.38509,0,0,0-21.25516,27.7329,43.4242,43.4242,0,0,0-1.34171,8.77531c-.09888,1.92955,2.90139,1.92422,3,0Z"
					transform="translate(-215.42491 -200)"
				/>
				<path
					d="M811.62965,332.259a23.48756,23.48756,0,0,1,7.85693-14.33284,23.16915,23.16915,0,0,1,16.195-5.37551c1.92969.09651,1.92456-2.90374,0-3a26.1035,26.1035,0,0,0-26.94474,21.91083c-.28826,1.88869,2.602,2.70292,2.89284.79752Z"
					transform="translate(-215.42491 -200)"
				/>
				<path
					d="M812.706,336.2085a5.47462,5.47462,0,0,1-1.72022-.27784l-14.24389-4.70312a5.49977,5.49977,0,1,1,3.449-10.44483l14.24365,4.70313A5.5,5.5,0,0,1,812.706,336.2085Z"
					transform="translate(-215.42491 -200)"
				/>
				<path
					d="M814.96507,453h-2.45654a4.06858,4.06858,0,0,0,.04687-.5c0-4.14215-6.26806-7.5-14-7.5s-14,3.35785-14,7.5a4.0686,4.0686,0,0,0,.04688.5h-2.45606c-19.104,0-34.59082,17.73932-34.59082,39.62189V575a4.00008,4.00008,0,0,0,4,4h94a4.00008,4.00008,0,0,0,4-4V492.62189C849.5554,470.73932,834.06859,453,814.96507,453Z"
					transform="translate(-215.42491 -200)"
				/>
				<path
					d="M703.99339,543.3584a4.51852,4.51852,0,0,1-1.17187-.15528,4.4707,4.4707,0,0,1-2.73242-2.09667l-16-27.7129a4.49963,4.49963,0,0,1,1.647-6.147l69.28222-40a20.49991,20.49991,0,0,1,20.5,35.50684l-69.28222,40A4.473,4.473,0,0,1,703.99339,543.3584Z"
					transform="translate(-215.42491 -200)"
				/>
				<path
					d="M848.5554,565.5h-32a4.50508,4.50508,0,0,1-4.5-4.5V481a20.5,20.5,0,0,1,41,0v80A4.50507,4.50507,0,0,1,848.5554,565.5Z"
					transform="translate(-215.42491 -200)"
				/>
			</g>
			<g fill="#3f3d56">
				<path
					d="M561.0554,466H216.42491a1,1,0,0,1-1-1V201a1,1,0,0,1,1-1H388.7401a1,1,0,0,1,0,2H217.42491V464H560.0554V333a1,1,0,0,1,2,0V465A1,1,0,0,1,561.0554,466Z"
					transform="translate(-215.42491 -200)"
				/>
				<path d="M983.57509,700h-381a1,1,0,0,1,0-2h381a1,1,0,0,1,0,2Z" transform="translate(-215.42491 -200)" />
			</g>
		</svg>
	)
}

export default CompletedImage
