import { type FC, useState } from "react"
import { Navigate, useParams } from "react-router"
import styled from "styled-components"

import { toDateTimeString } from "@forento/shared/utilities/date"
import { parseNumber } from "@forento/shared/utilities/number"

import { PurchaseSuccessModalContextProvider } from "~/contexts/PurchaseSuccessModalContext"
import Button from "~/themes/school/components/Button"
import Hero from "~/themes/school/components/Hero"
import { ArrowIcon } from "~/themes/school/components/Icon"
import Layout from "~/themes/school/components/Layout"
import Modal, { ModalButtons, ModalTitle } from "~/themes/school/components/Modal"
import { PageBreadcrumb } from "~/themes/school/components/PageHeader"
import PartialLoadingPage from "~/themes/school/components/PartialLoadingPage"
import PurchaseItem from "~/themes/school/components/PurchaseItem"
import { useTranslation } from "~/translations"
import routes from "~/utilities/routes"
import { swr } from "~/utilities/trpc"

import Thumbnail from "./Thumbnail"

const SessionPage: FC = () => {
	const sessionId = parseNumber(useParams().sessionId!)
	const t = useTranslation()

	const [isModalOpen, setModalOpen] = useState(false)

	const { data: session, error } = swr.privateSession.getPersonalized.useSWR(sessionId ?? -1)

	if (session === null) return <Navigate to={routes.session.index()} />

	const redemotionInstructions =
		session?.price === null ? session.redemptionInstructions : (session?.orders[0]?.redemptionInstructions ?? null)
	const redemptionLink =
		session?.price === null ? session.redemptionLink : (session?.orders[0]?.redemptionLink ?? null)

	return (
		<Layout>
			<Modal isOpen={isModalOpen}>
				<ModalTitle>{t("session.redeem")}</ModalTitle>
				<p>{redemotionInstructions}</p>
				<ModalButtons>
					{redemptionLink !== null ? (
						<>
							<Button variant="secondary" onClick={() => setModalOpen(false)}>
								{t("cancel")}
							</Button>
							<Button variant="primary" onClick={redemptionLink} newTab>
								{t("session.redeem")}
							</Button>
						</>
					) : (
						<Button variant="primary" onClick={() => setModalOpen(false)}>
							{t("close")}
						</Button>
					)}
				</ModalButtons>
			</Modal>
			{error ? (
				<p>{t("failedToLoad")}</p>
			) : session === undefined ? (
				<PartialLoadingPage />
			) : (
				<PurchaseSuccessModalContextProvider
					content={{
						type: "session",
						isUnlocked: session.price === null || session.orders.length > 0,
						payPalOrderId: session.orders[0]?.type === "paypal" ? session.orders[0].id : null,
					}}
				>
					<PageBreadcrumb
						path={[{ title: t("sessions"), link: routes.session.index() }]}
						title={session.title}
					/>
					<Hero
						title={session.title}
						htmlDescription={session.longDescription?.html}
						thumbnail={<Thumbnail />}
						callToAction={
							session.price !== null ? (
								<PurchaseItem
									item={{ type: "session", id: session.id, price: session.price }}
									onPurchase={() => {}}
								/>
							) : session.redemptionInstructions !== null ? (
								<Button variant="primary" onClick={() => setModalOpen(true)}>
									<CallToActionIcon as={ArrowIcon} />
									{t("session.redeem")}
								</Button>
							) : session.redemptionLink !== null ? (
								<Button variant="primary" onClick={session.redemptionLink} newTab>
									<CallToActionIcon as={ArrowIcon} />
									{t("session.redeem")}
								</Button>
							) : null
						}
					/>
					{session.orders.length > 0 && (
						<Orders>
							<OrdersTitle>{t("session.yourOrders")}</OrdersTitle>
							<OrdersList>
								{session.orders.map(order => (
									<Order key={order.createDate.toISOString()}>
										<OrderDate>{toDateTimeString(order.createDate)}</OrderDate>
										{order.redemptionInstructions !== null ? (
											<Button variant="primary" onClick={() => setModalOpen(true)}>
												{t("session.redeem")}
											</Button>
										) : order.redemptionLink !== null ? (
											<Button variant="primary" onClick={order.redemptionLink} newTab>
												{t("session.redeem")}
											</Button>
										) : null}
									</Order>
								))}
							</OrdersList>
						</Orders>
					)}
				</PurchaseSuccessModalContextProvider>
			)}
		</Layout>
	)
}

const CallToActionIcon = styled.div`
	width: 16px;
	height: 16px;
`

const Orders = styled.div`
	background-color: white;
	box-shadow: 0px 4px 30px 0px rgba(223, 232, 255, 0.25);
	padding: 48px 86px;
	margin-top: 32px;
`

const OrdersTitle = styled.h2`
	font-size: 20px;
	font-weight: bold;
	margin-bottom: 48px;
`

const OrdersList = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const Order = styled.div`
	display: flex;
	background-color: white;
	color: black;
	border: 1px solid black;
	padding: 8px 32px;
	border-radius: 10px;
	padding: 8px 32px;
	justify-content: space-between;
	align-items: center;
	gap: 16px;
`

const OrderDate = styled.div`
	font-size: 14px;
	line-height: 1;
`

export default SessionPage
