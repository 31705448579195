import styled from "styled-components"

import { hasAccessToPostCommunityPost } from "@forento/shared/utilities/community"

import { usePlatform } from "~/contexts/PlatformContext"
import { useUser } from "~/contexts/UserContext"
import withCommunityAuthCheck from "~/hocs/withCommunityAuthCheck"
import Layout from "~/themes/original/components/Layout"
import PartialLoadingPage from "~/themes/original/components/PartialLoadingPage"
import Post from "~/themes/original/components/Post"
import { swr } from "~/utilities/trpc"

import CreatePost from "./CreatePost"

const CommunityPage: React.FC = () => {
	const user = useUser()
	const platform = usePlatform().platform!

	const { data: posts, error, mutate } = swr.community.listPosts.useSWR()

	return (
		<Layout>
			{hasAccessToPostCommunityPost(user.user!, platform.community!) && <CreatePost onPostCreated={mutate} />}
			{error ? (
				<p>Failed to load posts.</p>
			) : posts === undefined ? (
				<PartialLoadingPage />
			) : (
				<Posts>
					{posts
						.toSorted((a, b) => b.createDate.getTime() - a.createDate.getTime())
						.map(x => (
							<Post key={x.id} post={x} onChange={mutate} />
						))}
				</Posts>
			)}
		</Layout>
	)
}

const Posts = styled.div`
	display: flex;
	flex-direction: column;
	gap: 32px;
`

export default withCommunityAuthCheck(CommunityPage)
