import { type FC, type ReactNode } from "react"
import styled from "styled-components"

import BaseModal from "@forento/shared/components/Modal"

import { mediaQueries } from "~/utilities/dimensions"

interface Props {
	isOpen: boolean
	width?: string | number
	onSubmit?(): void
	children: ReactNode
}

const Modal: FC<Props> = ({ isOpen, width, onSubmit, children }) => (
	<StyledBaseModal isOpen={isOpen} width={width ?? 500} onSubmit={onSubmit}>
		<Content>{children}</Content>
	</StyledBaseModal>
)

const StyledBaseModal = styled(BaseModal)`
	border-radius: 12px;
`

const Content = styled.div`
	padding: 24px;
	text-align: "center";
	display: flex;
	flex-direction: column;
	gap: 16px;
`

export const ModalTitle = styled.h1``

export const ModalText = styled.p``

export const ModalButtons = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin-top: ${24 - 16}px;

	${mediaQueries.tablet} {
		flex-direction: row;
		justify-content: flex-end;
	}
`

export default Modal
