import { type FC } from "react"

import Card, { Cards } from "~/themes/original/components/Card"
import { CertificateIcon } from "~/themes/original/components/Icon"
import Layout from "~/themes/original/components/Layout"
import PartialLoadingPage from "~/themes/original/components/PartialLoadingPage"
import routes from "~/utilities/routes"
import { swr } from "~/utilities/trpc"

const CertificatesPage: FC = () => {
	const { data: certificates, error } = swr.course.certificate.list.useSWR()

	return (
		<Layout>
			{error ? (
				<p>Failed to load certificates.</p>
			) : !certificates ? (
				<PartialLoadingPage />
			) : (
				<Cards>
					{certificates.map(certificate => (
						<Card
							key={certificate.id}
							title={certificate.course.title}
							icon={CertificateIcon}
							onClick={routes.certificate.detail(certificate.id)}
						/>
					))}
				</Cards>
			)}
		</Layout>
	)
}

export default CertificatesPage
