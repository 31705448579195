import { useState } from "react"
import styled from "styled-components"

import { useAlert } from "~/contexts/AlertContext"
import { useUser } from "~/contexts/UserContext"
import { SubmitButton } from "~/themes/school/components/Button"
import InputField from "~/themes/school/components/InputField"
import Layout from "~/themes/school/components/Layout"
import { PageTitle } from "~/themes/school/components/PageHeader"
import trpc from "~/utilities/trpc"

import Group from "./Group"
import ProfileAvatar from "./ProfileAvatar"

const SettingsPage: React.FC = () => {
	const user = useUser()
	const alert = useAlert()

	const [firstName, setFirstName] = useState(user.user!.firstName)
	const [lastName, setLastName] = useState(user.user!.lastName)
	const [currentPassword, setCurrentPassword] = useState("")
	const [newPassword, setNewPassword] = useState("")
	const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("")
	const [deleteAccountPassword, setDeleteAccountPassword] = useState("")

	const [isSubmittingPersonalInfo, setSubmittingPersonalInfo] = useState(false)
	const [isSubmittingChangePassword, setSubmittingChangePassword] = useState(false)
	const [isSubmittingDeleteAccount, setSubmittingDeletingAccount] = useState(false)

	const handleSavePersonalInfo = async () => {
		const fields = {
			firstName: firstName.trim(),
			lastName: lastName.trim(),
		}
		if (Object.values(fields).some(field => field.length === 0)) {
			await alert.show("Error", "Please fill in all fields.")
			return
		}
		setSubmittingPersonalInfo(true)

		try {
			await trpc.user.update.mutate(fields)
			await user.reload()
		} finally {
			setSubmittingPersonalInfo(false)
		}
	}

	const handleChangePassword = async () => {
		if (currentPassword.length === 0 || newPassword.length === 0) {
			await alert.show("Error", "Please fill in all fields.")
			return
		}

		if (newPassword !== newPasswordConfirmation) {
			await alert.show("Error", "Passwords don't match.")
			return
		}

		setSubmittingChangePassword(true)
		try {
			const { status } = await trpc.user.setPassword.mutate({ current: currentPassword, new: newPassword })
			if (status !== "success") {
				await alert.show("Error", "Incorrect current password.")
				return
			}
			await user.reload()
			setCurrentPassword("")
			setNewPassword("")
			setNewPasswordConfirmation("")
			await alert.show("Success", "Password changed successfully!")
		} catch (error) {
			console.error(error)
			await alert.show("Error", "Failed to change password. Please try again later.")
		} finally {
			setSubmittingChangePassword(false)
		}
	}

	const handleSubmit = async () => {
		if (deleteAccountPassword.length === 0) return

		const dialog = await alert.confirm(
			"Delete account",
			"Are you sure you want to delete your account? This action is irreversible.",
		)
		if (!dialog.result) return

		setSubmittingDeletingAccount(true)
		try {
			const response = await trpc.user.delete.mutate({ password: deleteAccountPassword })
			if (response.status !== "success") {
				switch (response.error) {
					case "invalid-password":
						await alert.show("Error", "Invalid password.")
						break
					case "is-creator":
						await alert.show(
							"Error",
							"You can't delete your account because you're a creator. Please contact support.",
						)
						break
				}
				return
			}

			await user.reload()
			dialog.close()
		} catch (error) {
			console.error(error)
		} finally {
			setSubmittingDeletingAccount(false)
		}
	}

	return (
		<Layout>
			<PageTitle>Settings</PageTitle>

			<Groups>
				<Group
					horizontal
					title="Personal information"
					onSubmit={handleSavePersonalInfo}
					gridArea="personalInfo"
				>
					<InputField
						label="First name"
						value={firstName}
						onChange={setFirstName}
						autoComplete="given-name"
					/>
					<InputField label="Last name" value={lastName} onChange={setLastName} autoComplete="family-name" />
					<SubmitButton variant="primary" isLoading={isSubmittingPersonalInfo}>
						Save changes
					</SubmitButton>
				</Group>

				<Group horizontal title="Change your password" onSubmit={handleChangePassword} gridArea="password">
					<InputField
						inputType="password"
						label="Current password"
						value={currentPassword}
						onChange={setCurrentPassword}
						autoComplete="current-password"
					/>
					<InputField
						inputType="password"
						label="New password"
						value={newPassword}
						onChange={setNewPassword}
						autoComplete="new-password"
					/>
					<InputField
						inputType="password"
						label="Confirm new password"
						value={newPasswordConfirmation}
						onChange={setNewPasswordConfirmation}
						autoComplete="new-password"
					/>
					<SubmitButton variant="primary" isLoading={isSubmittingChangePassword}>
						Change password
					</SubmitButton>
				</Group>

				{user.user!.communityProfileId !== null && (
					<Group title="Community avatar" gridArea="avatar">
						<ProfileAvatar />
					</Group>
				)}

				{!user.user!.isCreator && (
					<Group horizontal title="Delete account" onSubmit={handleSubmit} gridArea="deleteAccount">
						<InputField
							inputType="password"
							label="Current password"
							value={deleteAccountPassword}
							onChange={setDeleteAccountPassword}
							autoComplete="current-password"
						/>
						<SubmitButton
							variant="danger"
							isDisabled={deleteAccountPassword.length === 0}
							isLoading={isSubmittingDeleteAccount}
						>
							Delete account
						</SubmitButton>
					</Group>
				)}
			</Groups>
		</Layout>
	)
}

const Groups = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;

	@media (min-width: 1000px) {
		display: grid;
		grid-template-areas: "personalInfo personalInfo" "password password" "avatar deleteAccount";
		flex-direction: column;
		gap: 42px;
		padding: 0;
	}
`

export default SettingsPage
