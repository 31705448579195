import * as Sentry from "@sentry/react"
import "array.prototype.tosorted/auto"
import React from "react"
import { createRoot } from "react-dom/client"

import "@forento/shared/assets/lexical.css"

import App from "~/App"
import "~/translations"

document.addEventListener("DOMContentLoaded", () => {
	if (!CONFIG.isDevelopment) {
		Sentry.init({
			dsn: "https://9d8352e4606c4d6f96cb219abec7af08@o794413.ingest.sentry.io/5894396",
			replaysOnErrorSampleRate: 0.25,
			tracesSampleRate: 0.05,
			integrations: [
				Sentry.replayIntegration({
					beforeErrorSampling(event) {
						const error = event.exception?.values?.[0].value
						if (error?.includes("Failed to fetch")) return false
						return true
					},
				}),
				Sentry.browserTracingIntegration(),
			],
		})
	}

	createRoot(document.getElementById("root")!).render(
		<React.StrictMode>
			<App />
		</React.StrictMode>,
	)
})
