import { type CommunityPost, type CommunityPrivilege } from "../models/community"
import { type PlatformCommunity } from "../models/platform"
import { type PlatformUser } from "../models/user"

export function getCommunityPrivilegeDisplayName(privilege: CommunityPrivilege): string {
	switch (privilege) {
		case "view":
			return "Only read"
		case "react":
			return "React to posts"
		case "comment":
			return "React to posts and comment"
		case "post":
			return "React to posts, comment, and post"
	}
}

export function hasAccessToViewCommunity(user: PlatformUser, community: PlatformCommunity): boolean {
	if (user.isCreator) return true

	const userMembershipPrivilege = user.membership?.communityPrivilege ?? null

	return community.defaultPrivilege !== null || userMembershipPrivilege !== null
}

export function hasAccessToReactToCommunityPost(user: PlatformUser, community: PlatformCommunity): boolean {
	return hasAnyCommunityPrivilege(["react", "comment", "post"], user, community)
}

export function hasAccessToPostCommunityComment(user: PlatformUser, community: PlatformCommunity): boolean {
	return hasAnyCommunityPrivilege(["comment", "post"], user, community)
}

export function hasAccessToPostCommunityPost(user: PlatformUser, community: PlatformCommunity): boolean {
	return hasAnyCommunityPrivilege(["post"], user, community)
}

function hasAnyCommunityPrivilege(
	communityPrivileges: CommunityPrivilege[],
	user: PlatformUser,
	community: PlatformCommunity,
): boolean {
	if (user.isCreator) return true

	const userMembershipPrivilege = user.membership?.communityPrivilege ?? null

	return (
		(community.defaultPrivilege !== null && communityPrivileges.includes(community.defaultPrivilege)) ||
		(userMembershipPrivilege !== null && communityPrivileges.includes(userMembershipPrivilege))
	)
}

export function isAuthorizedToEditPost(user: PlatformUser, post: CommunityPost) {
	return user.communityProfileId !== null && (user.isCreator || user.communityProfileId === post.profile.id)
}
