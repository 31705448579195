import styled from "styled-components"

import Layout from "~/themes/original/components/Layout"
import PartialLoadingPage from "~/themes/original/components/PartialLoadingPage"
import { swr } from "~/utilities/trpc"

import Event from "./Event"

const EventsPage: React.FC = () => {
	const { data: upcomingEvents, error } = swr.event.listPublic.useSWR()

	return (
		<Layout>
			{error ? (
				<p>Failed to load upcoming events.</p>
			) : upcomingEvents === undefined ? (
				<PartialLoadingPage />
			) : (
				<Events>
					{upcomingEvents.map(event => (
						<Event key={event.id} event={event} />
					))}
				</Events>
			)}
		</Layout>
	)
}

const Events = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

export default EventsPage
