import { type FC, type ReactNode } from "react"
import styled, { css } from "styled-components"

import Form from "@forento/shared/components/Form"

import { usePlatform } from "~/contexts/PlatformContext"
import { InputFieldElement } from "~/themes/school/components/InputField"
import { backgroundColor, fonts } from "~/themes/school/styles"

type Props = { title: string; onSubmit?(): void; children: ReactNode }

const Layout: FC<Props> = ({ title, onSubmit, children }) => {
	const platform = usePlatform().platform!

	const content = (
		<>
			{platform.logo !== null ? <Logo alt="Logo" src={platform.logo.filePath} /> : <Brand>{platform.name}</Brand>}
			<Title>{title}</Title>
			{children}
		</>
	)

	return (
		<Container $backgroundImage={platform.thumbnailFilePath}>
			{onSubmit ? (
				<Content as={Form} onSubmit={onSubmit}>
					{content}
				</Content>
			) : (
				<Content>{content}</Content>
			)}
		</Container>
	)
}

const Container = styled.div<{ $backgroundImage: string | null }>`
	@media (min-width: 500px) {
		height: 100vh;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;

		${({ $backgroundImage }) =>
			$backgroundImage !== null
				? css`
						&:before {
							content: "";
							position: absolute;
							top: -20px;
							bottom: -20px;
							left: -20px;
							right: -20px;
							background-image: url(${$backgroundImage});
							background-size: cover;
							background-position: center;
							z-index: -1;
							filter: blur(10px);
						}
					`
				: css`
						background-color: ${backgroundColor};
					`}
	}
`

const Content = styled.div`
	background-color: white;
	border-radius: 10px;
	box-shadow: 0 4px 30px rgba(223, 232, 255, 0.25);
	display: flex;
	flex-direction: column;
	padding: 16px;
	gap: 24px;

	@media (min-width: 500px) {
		flex: 0 1 400px;
		padding: 40px;
	}
`

export const Logo = styled.img`
	width: 100%;
	height: 100px;
	align-self: center;
	object-fit: contain;
`

export const Brand = styled.div`
	font-family: ${fonts.quicksand};
	font-size: 18px;
	font-weight: bold;
	text-align: center;
`

export const Title = styled.h1`
	font-family: ${fonts.quicksand};
	font-size: 1.5em;
	text-align: center;
`

export const Text = styled.div`
	text-align: center;
	line-height: 1.4;
`

export const InputFields = styled.div`
	display: flex;
	flex-direction: column;

	> *:not(:first-child) {
		margin-top: ${24 - 16}px;
	}

	> ${InputFieldElement}:not(:first-child) {
		margin-top: 24px;
	}
`

export const Buttons = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

export const Divider = styled.hr`
	height: 1px;
	background-color: ${backgroundColor};
	border: none;
`

export const InputFieldRow = styled.div`
	display: flex;
	gap: 24px;

	@media (max-width: 999px) {
		flex-direction: column;
		gap: 32px;
	}
`

export default Layout
