import styled from "styled-components"

const ChaptersList: React.FC<{ chapters: { id: number; title: string }[] }> = ({ chapters }) => (
	<Container>
		{chapters.map(chapter => (
			<Chapter key={chapter.id}>{chapter.title}</Chapter>
		))}
	</Container>
)

const Container = styled.div`
	border: 1px solid #e8e8ed;
	border-radius: 8px;
	overflow: hidden;
`

const Chapter = styled.div`
	background-color: white;
	padding: 16px 24px;
	font-weight: 500;
	font-size: 18px;
	color: #302d3a;

	&:not(:last-child) {
		border-bottom: 1px solid #e8e8ed;
	}
`

export default ChaptersList
