import styled from "styled-components"

import { usePlatform } from "~/contexts/PlatformContext"
import Button from "~/themes/original/components/Button"

interface MobileHeaderProps {
	leftAction?: {
		icon: React.ComponentType
		onClick: string | (() => void)
	}
	className?: string
}

const MobileHeader: React.FC<MobileHeaderProps> = ({ leftAction, className }) => {
	const platform = usePlatform().platform!

	return (
		<Container className={className}>
			<SideContent>
				{leftAction && (
					<StyledButton onClick={leftAction.onClick}>
						<StyledIcon as={leftAction.icon} />
					</StyledButton>
				)}
			</SideContent>
			{platform.logo !== null ? <Logo alt="Logo" src={platform.logo.filePath} /> : <Brand>{platform.name}</Brand>}
			<SideContent />
		</Container>
	)
}

const Container = styled.header`
	width: 100%;
	padding: 16px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media (min-width: 1000px) {
		display: none;
	}
`

const SideContent = styled.div`
	width: 24px;
	height: 24px;
`

const StyledButton = styled(Button)`
	width: 100%;
	height: 100%;
`

const StyledIcon = styled.div`
	width: 100%;
	height: 100%;
`

const Brand = styled.p`
	font-size: 26px;
	font-weight: 600;
`

const Logo = styled.img`
	height: 36px;
`

export default MobileHeader
