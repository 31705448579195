import { useCallback, useMemo, useState } from "react"

import { getExtensionByFileName } from "@forento/shared/utilities/file"
import { parseNumber } from "@forento/shared/utilities/number"
import { toSlug } from "@forento/shared/utilities/string"

import { useAlert } from "~/contexts/AlertContext"
import { useTranslation } from "~/translations"
import { swr } from "~/utilities/trpc"

export function useDownloadable(id: number) {
	const alert = useAlert()
	const t = useTranslation()

	const { data: downloadable, error, mutate } = swr.downloadable.getPersonalized.useSWR(id)

	const [downloadProgress, setDownloadProgress] = useState<number>()

	const handleDownload = useCallback(async () => {
		if (downloadable?.status !== "unlocked") return

		if (downloadable.type === "url") {
			window.open(downloadable.url, "_blank")
			return
		}

		setDownloadProgress(0)

		try {
			const response = await fetch(downloadable.filePath, {
				headers: { "Content-Type": "application/octet-stream" },
			})
			if (response.body === null) return
			const reader = response.body.getReader()

			const contentLength = parseNumber(response.headers.get("Content-Length") ?? "")
			if (contentLength === null) return
			let receivedLength = 0
			const chunks = []
			// eslint-disable-next-line no-constant-condition
			while (true) {
				const { done, value } = await reader.read()
				if (done) break
				chunks.push(value)
				receivedLength += value.length
				setDownloadProgress(receivedLength / contentLength)
			}

			const allChunks = new Uint8Array(receivedLength)
			let position = 0
			for (const chunk of chunks) {
				allChunks.set(chunk, position)
				position += chunk.length
			}

			const extension = getExtensionByFileName(downloadable.filePath)
			const fileUrl = URL.createObjectURL(new Blob([allChunks]))
			const element = document.createElement("a")
			element.href = fileUrl
			element.download = toSlug(downloadable.title) + (extension !== null ? `.${extension}` : "")
			document.body.appendChild(element)
			element.click()
			URL.revokeObjectURL(fileUrl)
			element.remove()
		} catch (error) {
			console.error(error)
			await alert.show(t("downloadable.downloadFailed"), t("downloadable.downloadFailedMessage"))
		} finally {
			setDownloadProgress(undefined)
		}
	}, [alert, downloadable, t])

	const value = useMemo(() => {
		if (error) return { status: "error" as const }
		if (downloadable === undefined) return { status: "loading" as const }
		if (downloadable === null) return { status: "not-found" as const }

		return {
			downloadProgress,
			download: handleDownload,
			reload: mutate,
			...downloadable,
		}
	}, [error, downloadable, downloadProgress, handleDownload, mutate])

	return value
}
