import styled from "styled-components"

import LoadingIndicator from "./LoadingIndicator"

const FullLoadingPage: React.FC = () => (
	<Container>
		<LoadingIndicator />
	</Container>
)

const Container = styled.div`
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
`

export default FullLoadingPage
