import styled from "styled-components"

import LoadingIndicator from "./LoadingIndicator"

const PartialLoadingPage: React.FC = () => (
	<Container>
		<LoadingIndicator />
	</Container>
)

const Container = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 50px;
`

export default PartialLoadingPage
