import { type FC } from "react"
import type React from "react"
import { useLayoutEffect, useState } from "react"
import styled, { css, useTheme } from "styled-components"

import { type CoursePageAnswer } from "@forento/shared/models/course"

import Button from "~/themes/school/components/Button"

type Props = {
	answers: CoursePageAnswer[]
	setSelection(selectedAnswerIds: number[]): void
}

const MultiAnswerType: FC<Props> = ({ answers, setSelection }) => {
	const [selectedAnswerIds, setSelectedAnswerIds] = useState<number[]>([])

	useLayoutEffect(() => {
		setSelectedAnswerIds([])
	}, [answers])

	return (
		<Answers>
			{answers.map(answer => {
				const isChecked = selectedAnswerIds.includes(answer.id)

				return (
					<Answer
						key={answer.id}
						isChecked={isChecked}
						onClick={() => {
							const newValue = selectedAnswerIds.includes(answer.id)
								? selectedAnswerIds.filter(x => x !== answer.id)
								: [...selectedAnswerIds, answer.id]

							setSelectedAnswerIds(newValue)
							setSelection(newValue)
						}}
					>
						<Checkmark>
							<CheckmarkIcon isChecked={isChecked} />
						</Checkmark>
						<Text>{answer.text}</Text>
					</Answer>
				)
			})}
		</Answers>
	)
}

const Answers = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const Checkmark = styled.div`
	position: absolute;
	top: 50%;
	left: 16px;
	transform: translateY(-50%);
	flex: 0 0 auto;
	width: 24px;
	height: 24px;
	border: 1px solid black;
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
`

const Answer = styled(Button)<{ isChecked: boolean }>`
	display: block;
	border-radius: 10px;
	border: 1px solid black;
	font-size: 14px;
	padding: 8px ${16 + 24 + 16}px;
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: 16px;
	position: relative;

	${props =>
		props.isChecked &&
		css`
			color: ${props.theme.primaryColor};
			border-color: ${props.theme.primaryColor};

			${Checkmark} {
				border-color: ${props => props.theme.primaryColor};
			}
		`}
`

const CheckmarkIcon: React.FC<{ isChecked: boolean }> = ({ isChecked }) => {
	const theme = useTheme()

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			width={16}
			height={16}
			fill={theme.primaryColor}
			style={{ transition: "0.1s", transform: `scale(${isChecked ? "1" : "0"})`, transformOrigin: "center" }}
		>
			<path d="M0 0h24v24H0V0z" fill="none" />
			<path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
		</svg>
	)
}

const Text = styled.div`
	flex: 1;
	text-align: center;
`

export default MultiAnswerType
