import { type FC, useLayoutEffect, useState } from "react"
import styled, { css } from "styled-components"

import { type CoursePageAnswer } from "@forento/shared/models/course"

type Props = {
	answers: CoursePageAnswer[]
	setSelection(selection: number): void
}

const SingleAnswerType: FC<Props> = ({ answers, setSelection }) => {
	const [selectedAnswerId, setSelectedAnswerId] = useState<number>()

	useLayoutEffect(() => {
		setSelectedAnswerId(undefined)
	}, [answers])

	return (
		<Answers>
			{answers.map(answer => {
				const isChecked = selectedAnswerId === answer.id

				return (
					<Answer
						key={answer.id}
						$isChecked={isChecked}
						onClick={() => {
							setSelectedAnswerId(answer.id)
							setSelection(answer.id)
						}}
					>
						{answer.text}
					</Answer>
				)
			})}
		</Answers>
	)
}

const Answers = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const Answer = styled.label<{ $isChecked: boolean }>`
	display: block;
	border-radius: 10px;
	border: 1px solid black;
	font-size: 14px;
	padding: 8px 16px;
	cursor: pointer;
	text-align: center;

	${props =>
		props.$isChecked &&
		css`
			color: ${props.theme.primaryColor};
			border-color: ${props.theme.primaryColor};
		`}
`

export default SingleAnswerType
