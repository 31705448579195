import { type FC, useId } from "react"
import styled from "styled-components"

import { fonts } from "~/themes/school/styles"
import { useTranslation } from "~/translations"

interface Props {
	question: {
		text: string
		isMultipleChoice: boolean
		answers: {
			id: number
			text: string
		}[]
	}
	index: number
	selectedIds: number[]
	setSelectedIds(value: number[]): void
}

const Question: FC<Props> = ({ question, index, selectedIds, setSelectedIds }) => {
	const t = useTranslation()
	const controlIdPrefix = useId()

	const handleAnswerClick = (id: number) => {
		if (question.isMultipleChoice) {
			if (selectedIds.includes(id)) {
				setSelectedIds(selectedIds.filter(x => x !== id))
			} else {
				setSelectedIds([...selectedIds, id])
			}
		} else {
			setSelectedIds([id])
		}
	}

	return (
		<div>
			<Text>
				{question.isMultipleChoice
					? t("exam.questionMultipleChoice", { number: index + 1, question: question.text })
					: t("exam.question", { number: index + 1, question: question.text })}
			</Text>
			<Answers>
				{question.answers.map(({ id, text }) => {
					const controlId = `${controlIdPrefix}-${id}`
					const isChecked = selectedIds.includes(id)

					return (
						<div key={id}>
							<AnswerInput
								type={question.isMultipleChoice ? "checkbox" : "radio"}
								id={controlId}
								onChange={() => handleAnswerClick(id)}
								checked={isChecked}
							/>
							<AnswerLabel htmlFor={controlId} $isChecked={isChecked}>
								{text}
							</AnswerLabel>
						</div>
					)
				})}
			</Answers>
		</div>
	)
}

const Text = styled.div`
	font-family: ${fonts.quicksand};
	font-size: 16px;
	font-weight: bold;
	margin-bottom: 16px;
`

const Answers = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin: 0 0 32px 0;
`

const AnswerInput = styled.input`
	display: none;
`

const AnswerLabel = styled.label<{ $isChecked: boolean }>`
	width: 100%;
	max-width: 400px;
	display: block;
	padding: 8px 32px;
	font-size: 14px;
	text-align: center;
	border-radius: 10px;
	color: ${props => (props.$isChecked ? props.theme.primaryColor : "black")};
	border: 1px solid ${props => (props.$isChecked ? props.theme.primaryColor : "black")};
	cursor: pointer;
`

export default Question
