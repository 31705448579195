import { useLayoutEffect, useState } from "react"
import styled, { css } from "styled-components"
import Values from "values.js"

import CommunityProfileAvatar from "@forento/shared/components/CommunityProfileAvatar"
import Form from "@forento/shared/components/Form"
import { type CommunityMessage, type PublicCommunityProfile } from "@forento/shared/models/community"
import { toDateTimeString } from "@forento/shared/utilities/date"

import { useUser } from "~/contexts/UserContext"
import Button, { SubmitButton } from "~/themes/original/components/Button"
import { BackIcon, SendIcon } from "~/themes/original/components/Icon"
import InputField from "~/themes/original/components/InputField"
import { toRelativeDateString } from "~/themes/original/utilities/date"
import { isColorDark } from "~/utilities/color"
import routes from "~/utilities/routes"
import { lightTextColor } from "~/utilities/styles"
import trpc from "~/utilities/trpc"

interface Props {
	conversation: {
		otherProfile: PublicCommunityProfile
		messages: CommunityMessage[]
	}
	reload: () => Promise<void>
}

const DetailedConversation: React.FC<Props> = ({ conversation, reload }) => {
	const user = useUser()

	const [input, setInput] = useState("")
	const [isSubmitting, setSubmitting] = useState(false)
	const [messagesElement, setMessagesElement] = useState<HTMLDivElement | null>(null)

	const handleSubmit = async () => {
		const trimmedInput = input.trim()
		if (trimmedInput.length === 0) return

		setSubmitting(true)

		try {
			await trpc.community.sendMessage.mutate({
				otherProfileId: conversation.otherProfile.id,
				text: trimmedInput,
			})
			setInput("")
			await reload()
		} finally {
			setSubmitting(false)
		}
	}

	useLayoutEffect(() => {
		if (messagesElement !== null) {
			messagesElement.scrollTop = messagesElement.scrollHeight
		}
	}, [messagesElement, conversation.messages])

	return (
		<Container>
			<BackButton onClick={routes.community.conversations()}>
				<BackButtonIcon />
				Back to conversations
			</BackButton>
			<Header>
				<CommunityProfileAvatar profile={conversation.otherProfile} size={52} />
				<Name>
					{conversation.otherProfile.firstName} {conversation.otherProfile.lastName}
				</Name>
			</Header>
			<Messages ref={setMessagesElement}>
				{conversation.messages.map(message => {
					const isAuthor = message.senderProfileId === user.user!.communityProfileId

					return (
						<MessageContainer key={message.id} $isAuthor={isAuthor}>
							<Message $isAuthor={isAuthor}>{message.text}</Message>
							<MessageDate title={toDateTimeString(message.sendDate)}>
								{toRelativeDateString(message.sendDate)}
							</MessageDate>
						</MessageContainer>
					)
				})}
			</Messages>
			<Footer onSubmit={handleSubmit}>
				<InputField placeholder="Type your message..." value={input} onChange={setInput} />
				<SendButton isLoading={isSubmitting}>
					<SendButtonIcon />
				</SendButton>
			</Footer>
		</Container>
	)
}

const Container = styled.div`
	height: 100%;
	flex: 2 0 0;
	background-color: white;
	border-radius: 8px;
	padding: 42px 0;
	display: flex;
	flex-direction: column;
	gap: 22px;
`

const BackButton = styled(Button)`
	display: flex;
	align-items: center;
	gap: 16px;
	margin: 0 42px 8px;

	@media (min-width: 1000px) {
		display: none;
	}
`

const BackButtonIcon = styled(BackIcon)`
	width: 24px;
	height: 24px;
`

const Header = styled.div`
	display: flex;
	align-items: center;
	gap: 22px;
	padding-bottom: 22px;
	margin: 0 42px;
	border-bottom: 1px solid ${props => new Values(props.theme.primaryColor).tint(90).hexString()};
`

const Name = styled.p`
	font-weight: 600;
	font-size: 22px;
	color: #444a6d;
`

const Messages = styled.div`
	flex: 1 0 0;
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 0 42px;
	overflow-y: auto;
`

const MessageContainer = styled.div<{ $isAuthor: boolean }>`
	width: 100%;
	max-width: 450px;
	font-size: 18px;
	display: flex;
	flex-direction: column;
	gap: 4px;

	${props =>
		props.$isAuthor
			? css`
					align-self: flex-end;
					align-items: flex-end;
					color: ${props => (isColorDark(props.theme.primaryColor) ? "white" : lightTextColor)};
				`
			: css`
					align-self: flex-start;
					align-items: flex-start;
					color: ${isColorDark(new Values(props.theme.primaryColor).tint(90).hexString())
						? "white"
						: lightTextColor};
				`}
`

const Message = styled.p<{ $isAuthor: boolean }>`
	background-color: ${props =>
		props.$isAuthor ? props.theme.primaryColor : new Values(props.theme.primaryColor).tint(90).hexString()};
	border-radius: ${props => (props.$isAuthor ? "4px 4px 0 4px" : "4px 4px 4px 0")};
	padding: 16px 32px;
	position: relative;

	&:after {
		content: "";
		position: absolute;
		top: calc(100% - 20px);
		border-style: solid;

		${props =>
			props.$isAuthor
				? css`
						right: -20px;
						border-width: 0 20px 20px 0;
						border-color: ${props.theme.primaryColor} transparent;
					`
				: css`
						left: -20px;
						border-width: 20px 20px 0 0;
						border-color: transparent ${new Values(props.theme.primaryColor).tint(90).hexString()};
					`}
	}
`

const MessageDate = styled.p`
	font-size: 16px;
	color: #d0d2da;
`

const Footer = styled(Form)`
	border-top: 1px solid ${props => new Values(props.theme.primaryColor).tint(90).hexString()};
	padding-top: 22px;
	margin: 0 42px;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	gap: 22px;

	@media (min-width: 500px) {
		flex-direction: row;
		align-items: stretch;
	}
`

const SendButton = styled(SubmitButton).attrs({ variant: "primary", replaceOnLoading: true })`
	flex: 0 0 auto;
	width: 58px;
	height: 58px;
	padding: 0;

	&:hover,
	&:active {
		padding: 0;
	}
`

const SendButtonIcon = styled(SendIcon)`
	width: 24px;
	height: 24px;
`

export default DetailedConversation
