import { type FC, useState } from "react"
import styled from "styled-components"

import Form from "@forento/shared/components/Form"

import { useUser } from "~/contexts/UserContext"
import { SubmitButton } from "~/themes/original/components/Button"
import InputField from "~/themes/original/components/InputField"
import PartialLoadingPage from "~/themes/original/components/PartialLoadingPage"
import { useTranslation } from "~/translations"
import trpc, { swr } from "~/utilities/trpc"

import Comment from "./Comment"

type Props = { courseId: number; commentsUnderReview: number; pageId: number | null; reloadCourse(): Promise<unknown> }

const CourseComments: FC<Props> = ({ courseId, commentsUnderReview, pageId, reloadCourse }) => {
	const user = useUser()
	const t = useTranslation()

	const [newComment, setNewComment] = useState("")
	const [newReplies, setNewReplies] = useState<Record<number, string>>("")

	const [isSubmittingNewComment, setSubmittingNewComment] = useState(false)
	const [submittingReplyIds, setSubmittingReplyIds] = useState<number[]>([])

	const comments = swr.course.listComments.useSWR({ courseId })

	async function handleCreateComment() {
		if (newComment.trim() === "") return
		setSubmittingNewComment(true)
		try {
			await trpc.course.createComment.mutate({ courseId, pageId, text: newComment })
			setNewComment("")
			await Promise.all([comments.mutate(), reloadCourse()])
		} finally {
			setSubmittingNewComment(false)
		}
	}

	async function handleCreateReply(commentId: number) {
		const text = newReplies[commentId]?.trim() ?? ""
		if (text === "") return
		setSubmittingReplyIds(current => [...current, commentId])
		try {
			await trpc.course.createCommentReply.mutate({ commentId, pageId, text })
			setNewReplies(current => ({ ...current, [commentId]: "" }))
			await Promise.all([comments.mutate(), reloadCourse()])
		} finally {
			setSubmittingReplyIds(current => current.filter(id => id !== commentId))
		}
	}

	async function handleDeleteComment(commentId: number) {
		await trpc.course.deleteComment.mutate(commentId)
		await comments.mutate()
	}

	async function handleDeleteReply(replyId: number) {
		await trpc.course.deleteCommentReply.mutate(replyId)
		await comments.mutate()
	}

	return (
		<Container>
			{comments.error ? (
				<p>Failed to load comments.</p>
			) : comments.data === undefined ? (
				<PartialLoadingPage />
			) : (
				<>
					{user.user && (
						<NewCommentContainer onSubmit={handleCreateComment}>
							<InputField value={newComment} onChange={setNewComment} placeholder="New comment..." />
							<SubmitButton variant="primary" isLoading={isSubmittingNewComment}>
								Post
							</SubmitButton>
						</NewCommentContainer>
					)}
					{commentsUnderReview > 0 && (
						<p>{t("course.commentsUnderReview", { count: commentsUnderReview })}</p>
					)}
					{comments.data.map(comment => (
						<div key={comment.id}>
							<Comment comment={comment} onDelete={() => handleDeleteComment(comment.id)} />
							<Replies>
								{comment.replies.map(reply => (
									<Comment
										key={reply.id}
										comment={reply}
										onDelete={() => handleDeleteReply(reply.id)}
									/>
								))}
								{user.user && (
									<NewCommentContainer onSubmit={() => handleCreateReply(comment.id)}>
										<InputField
											value={newReplies[comment.id] ?? ""}
											onChange={value => setNewReplies(c => ({ ...c, [comment.id]: value }))}
											placeholder="Reply..."
										/>
										<SubmitButton
											variant="primary"
											isLoading={submittingReplyIds.includes(comment.id)}
										>
											Reply
										</SubmitButton>
									</NewCommentContainer>
								)}
							</Replies>
						</div>
					))}
				</>
			)}
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 32px;
`

const NewCommentContainer = styled(Form)`
	display: flex;
	gap: 8px;
`

const Replies = styled.div`
	margin-left: 32px;
	display: flex;
	flex-direction: column;
	gap: 8px;

	&:not(:empty) {
		margin-top: 8px;
	}
`

export default CourseComments
