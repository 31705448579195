import html2canvas from "html2canvas"

export async function downloadElement(element: HTMLElement) {
	const canvas = await html2canvas(element, { useCORS: true })

	const data = canvas.toDataURL("image/png")
	const link = document.createElement("a")

	if (typeof link.download === "string") {
		link.href = data
		link.download = "Certificate.png"
		document.body.appendChild(link)
		link.click()
		document.body.removeChild(link)
	} else {
		window.open(data)
	}
}
