import { type FC, type ReactNode } from "react"
import styled, { css } from "styled-components"

import Form from "@forento/shared/components/Form"

type Props = { title: string; onSubmit?(): void; gridArea: string; horizontal?: boolean; children: ReactNode }

const Group: FC<Props> = ({ title, onSubmit, gridArea, horizontal = false, children }) => {
	const content = (
		<>
			<Title>{title}</Title>
			<Content $horizontal={horizontal}>{children}</Content>
		</>
	)

	return onSubmit !== undefined ? (
		<Container as={Form} onSubmit={onSubmit} $gridArea={gridArea}>
			{content}
		</Container>
	) : (
		<Container $gridArea={gridArea}>{content}</Container>
	)
}

const Container = styled.div<{ $gridArea: string }>`
	grid-area: ${props => props.$gridArea};
	background-color: white;
	box-shadow: 0px 4px 30px 0px rgba(223, 232, 255, 0.25);
	padding: 18px 26px 42px;
`

const Title = styled.h2`
	font-family: Quicksand;
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 32px;
`

const Content = styled.div<{ $horizontal: boolean }>`
	background: white;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	gap: 24px;

	@media (min-width: 1000px) {
		flex-direction: ${props => (props.$horizontal ? "row" : "column")};

		${props =>
			props.$horizontal &&
			css`
				align-items: center;
				flex-wrap: wrap;
			`}
	}
`

export default Group
