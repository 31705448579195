import { type ReactNode } from "react"
import styled from "styled-components"

import Form from "@forento/shared/components/Form"

const Group: React.FC<{ title: string; onSubmit?: () => void; children: ReactNode }> = ({
	title,
	onSubmit,
	children,
}) => {
	const content = (
		<>
			<Title>{title}</Title>
			<Content>{children}</Content>
		</>
	)

	return onSubmit !== undefined ? (
		<Container as={Form} onSubmit={onSubmit}>
			{content}
		</Container>
	) : (
		<Container>{content}</Container>
	)
}

const Container = styled.div`
	max-width: 600px;
`

const Title = styled.h2`
	font-weight: 600;
	font-size: 20px;
	color: #151d48;
	margin-bottom: 24px;
`

const Content = styled.div`
	background: white;
	border-radius: 8px;
	padding: 24px;
	display: flex;
	flex-direction: column;
	gap: 24px;
`

export default Group
