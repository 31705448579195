import { type FC, useState } from "react"
import { useLocation } from "react-router"
import styled, { css } from "styled-components"
import Values from "values.js"

import { useUser } from "~/contexts/UserContext"
import Button from "~/themes/original/components/Button"
import { BillingIcon, LogoutIcon, SettingsIcon } from "~/themes/original/components/Icon"
import routes from "~/utilities/routes"
import { lightTextColor } from "~/utilities/styles"

import Logo from "../Logo"
import useNavigationItems from "./useNavigationItems"

const Header: FC = () => {
	const { user, signOut } = useUser()
	const location = useLocation()

	const navigationItems = useNavigationItems()

	const [isDropdownOpen, setDropdownOpen] = useState(false)

	return (
		<Container>
			<Logo />
			<NavItems>
				{navigationItems.map(({ label, onClick }, index) => (
					<NavItem key={index} onClick={onClick} isActive={onClick === location.pathname}>
						{label}
					</NavItem>
				))}
				{user ? (
					<NavItemContainer>
						<NavItem onClick={() => setDropdownOpen(current => !current)} isActive={isDropdownOpen}>
							{user.firstName} {user.lastName}
						</NavItem>
						<UserDropdown $isOpen={isDropdownOpen}>
							<UserDropdownItem onClick={routes.billing.index()}>
								<UserDropdownItemIcon as={BillingIcon} />
								Billing
							</UserDropdownItem>
							<UserDropdownItem onClick={routes.user.settings()}>
								<UserDropdownItemIcon as={SettingsIcon} />
								Settings
							</UserDropdownItem>
							<UserDropdownItem onClick={signOut}>
								<UserDropdownItemIcon as={LogoutIcon} />
								Sign out
							</UserDropdownItem>
						</UserDropdown>
					</NavItemContainer>
				) : (
					<NavItem onClick={routes.user.signin()}>Sign in</NavItem>
				)}
			</NavItems>
		</Container>
	)
}

const Container = styled.header`
	padding: 42px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 42px;

	@media (max-width: 999px) {
		display: none;
	}
`

const NavItems = styled.nav`
	display: flex;
	align-items: center;
	gap: 42px;
`

const NavItemContainer = styled.div`
	position: relative;
`

const NavItem = styled(Button)<{ isActive?: boolean }>`
	font-size: 16px;
	color: ${lightTextColor};
	letter-spacing: 0.6px;

	${props =>
		props.isActive &&
		css`
			font-weight: 700;
			letter-spacing: 0;
			color: ${props => props.theme.primaryColor};
		`}
`

const UserDropdown = styled.div<{ $isOpen: boolean }>`
	position: absolute;
	z-index: 1;
	right: 0;
	top: ${23 + 16}px;
	width: 200px;
	background-color: white;
	box-shadow: 0 20px 40px -2px rgba(0, 0, 0, 0.16);
	border-radius: 8px;
	padding: 16px;
	transform: scale(0);
	transform-origin: top right;
	transition: 0.15s;
	white-space: nowrap;

	${props =>
		props.$isOpen &&
		css`
			transform: scale(1);
		`}
`

const UserDropdownItem = styled(Button)`
	width: 100%;
	font-size: 16px;
	color: ${lightTextColor};
	border-radius: 4px;
	padding: 8px;
	transition: 0.15s;
	display: flex;
	align-items: center;
	gap: 16px;
	letter-spacing: 0.6px;

	&:hover {
		color: ${props => props.theme.primaryColor};
		background-color: ${props => new Values(props.theme.primaryColor).tint(95).hexString()};
	}

	&:active {
		color: ${props => props.theme.primaryColor};
		background-color: ${props => new Values(props.theme.primaryColor).tint(90).hexString()};
	}
`

const UserDropdownItemIcon = styled.div`
	width: 24px;
	height: 24px;
`

export default Header
