import { t } from "i18next"
import { type FC } from "react"
import { useLocation, useNavigate } from "react-router"
import styled from "styled-components"

import Tooltip from "@forento/shared/components/Tooltip"
import { type ThinMembership } from "@forento/shared/models/membership"
import { toPriceString } from "@forento/shared/utilities/currency"

import { usePlatform } from "~/contexts/PlatformContext"
import { useUser } from "~/contexts/UserContext"
import Button from "~/themes/school/components/Button"
import routes from "~/utilities/routes"
import trpc from "~/utilities/trpc"

type Props = {
	membershipId: number
	isActiveMembership: boolean
	price: ThinMembership["prices"][number]
	setSubmittingPriceId(submittingPriceId: number | undefined): void
	submittingPriceId?: number
}
const PriceButton: FC<Props> = ({
	membershipId,
	isActiveMembership,
	price,
	setSubmittingPriceId,
	submittingPriceId,
}) => {
	const user = useUser()
	const platform = usePlatform().platform!
	const location = useLocation()
	const navigate = useNavigate()

	async function handleSelect() {
		if (user.user === null) {
			return navigate(`${routes.user.signup()}?next=${encodeURIComponent(location.pathname)}`)
		}

		setSubmittingPriceId(price.id)
		try {
			const result = await trpc.payment.joinMembershipPrice.mutate(price.id)
			switch (result.status) {
				case "success":
					await user.reload()
					setSubmittingPriceId(undefined)
					break
				case "redirect":
					window.location.href = result.url
					break
			}
		} catch (error) {
			console.error(error)
			setSubmittingPriceId(undefined)
		}
	}

	async function handleCancel() {
		const membership = user.user!.membership
		if (membership === null || membership.type === "external") return

		setSubmittingPriceId(membership.priceId)
		try {
			await trpc.payment.leaveMembership.mutate()
			await user.reload()
		} finally {
			setSubmittingPriceId(undefined)
		}
	}

	if (user.user?.membership?.type === "external") {
		return (
			<Button key={price.id} variant="secondary" isDisabled>
				{user.user.membership.id === membershipId ? t("membership.active") : t("membership.managedExternally")}
			</Button>
		)
	}

	const isActivePrice = user.user?.membership?.priceId === price.id
	const isMembershipRenewing = user.user?.membership?.endDate === null

	if (!platform.paymentGateway?.isEnabled) {
		return (
			<StyledTooltip tooltip="Payments are not enabled">
				<StyledButton variant="primary" isDisabled>
					{t("membership.plan", {
						price: toPriceString(price),
						interval: t(`paymentInterval.${price.paymentInterval}`),
					})}
				</StyledButton>
			</StyledTooltip>
		)
	}

	return (
		<StyledButton
			variant={isActiveMembership ? (isActivePrice && isMembershipRenewing ? "danger" : "secondary") : "primary"}
			onClick={isActivePrice && isMembershipRenewing ? handleCancel : handleSelect}
			isLoading={submittingPriceId === price.id}
			isDisabled={submittingPriceId !== undefined}
		>
			{isActiveMembership
				? isActivePrice
					? isMembershipRenewing
						? t("membership.cancel")
						: t("membership.resume")
					: t("membership.switch", {
							price: toPriceString(price),
							interval: t(`paymentInterval.${price.paymentInterval}`),
						})
				: t("membership.plan", {
						price: toPriceString(price),
						interval: t(`paymentInterval.${price.paymentInterval}`),
					})}
		</StyledButton>
	)
}

const StyledTooltip = styled(Tooltip)`
	width: 100%;
`

const StyledButton = styled(Button)`
	width: 100%;
`

export default PriceButton
