import { useState } from "react"
import { Trans } from "react-i18next"
import { PhoneInput } from "react-international-phone"
import { useNavigate } from "react-router"
import { useSearchParams } from "react-router-dom"

import { useAlert } from "~/contexts/AlertContext"
import { usePlatform } from "~/contexts/PlatformContext"
import { useUser } from "~/contexts/UserContext"
import Button, { SubmitButton } from "~/themes/school/components/Button"
import InputField, { InputLabel } from "~/themes/school/components/InputField"
import { useTranslation } from "~/translations"
import routes from "~/utilities/routes"
import trpc from "~/utilities/trpc"

import Layout, { Buttons, Divider, InputFields } from "../Layout"
import PhoneNumberInputStyles from "./PhoneNumberInputStyles"
import { TermsOfService, TermsOfServiceButton } from "./styles"

const RegularSignup: React.FC = () => {
	const platform = usePlatform()
	const user = useUser()
	const [searchParams] = useSearchParams()
	const navigate = useNavigate()
	const alert = useAlert()
	const t = useTranslation()

	const [firstName, setFirstName] = useState("")
	const [lastName, setLastName] = useState("")
	const [email, setEmail] = useState("")
	const [phoneNumber, setPhoneNumber] = useState("")
	const [password, setPassword] = useState("")
	const [passwordConfirmation, setPasswordConfirmation] = useState("")
	const [isSubmitting, setSubmitting] = useState(false)

	const nextUrl = useSearchParams()[0].get("next") ?? undefined

	const isValid =
		firstName.trim().length > 0 &&
		lastName.trim().length > 0 &&
		email.trim().length > 0 &&
		password.length > 0 &&
		password === passwordConfirmation &&
		(!platform.platform!.isPhoneNumberEnabled || phoneNumber.length > 0)

	const handleSignup = async () => {
		if (!isValid) return

		setSubmitting(true)

		try {
			const response = await trpc.user.registerStudent.mutate({
				firstName: firstName.trim(),
				lastName: lastName.trim(),
				email: email.trim(),
				phoneNumber: platform.platform!.isPhoneNumberEnabled ? phoneNumber : null,
				password,
				platformId: platform.platform!.id,
			})

			if (response.status === "email-taken") {
				await alert.show(t("user.signUp.emailTaken"), t("user.signUp.emailTakenMessage"))
				return
			}

			await user.reload()

			navigate(nextUrl ?? "/")
		} catch {
			await alert.show(t("general.error"), t("general.unknownError"))
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<Layout title={t("user.signUp.title")} onSubmit={handleSignup}>
			<InputFields>
				<InputField
					inputType="text"
					label={t("user.signUp.firstName")}
					value={firstName}
					onChange={setFirstName}
					autoComplete="given-name"
				/>

				<InputField
					inputType="text"
					label={t("user.signUp.lastName")}
					value={lastName}
					onChange={setLastName}
					autoComplete="family-name"
				/>

				<InputField inputType="email" label={t("user.general.email")} value={email} onChange={setEmail} />

				{platform.platform!.isPhoneNumberEnabled && (
					<div>
						<PhoneNumberInputStyles />
						<InputLabel>{t("user.signUp.phoneNumber")}</InputLabel>
						<PhoneInput value={phoneNumber} onChange={setPhoneNumber} />
					</div>
				)}

				<InputField
					inputType="password"
					label={t("user.general.password")}
					value={password}
					onChange={setPassword}
				/>

				<InputField
					inputType="password"
					label={t("user.signUp.confirmPassword")}
					value={passwordConfirmation}
					onChange={setPasswordConfirmation}
				/>
			</InputFields>

			<TermsOfService>
				<Trans
					i18nKey="user.signUp.agreeToTerms"
					components={{
						terms: <TermsOfServiceButton onClick={routes.policy.termsOfService()} newTab />,
						privacy: <TermsOfServiceButton onClick={routes.policy.privacy()} newTab />,
					}}
				/>
			</TermsOfService>

			<Buttons>
				{platform.platform!.isSignupsOpen ? (
					<SubmitButton variant="primary" isLoading={isSubmitting} isDisabled={!isValid}>
						{t("user.signUp.title")}
					</SubmitButton>
				) : (
					<Button variant="primary" isDisabled>
						{t("user.signUp.closed")}
					</Button>
				)}

				<Divider />

				<Button variant="secondary" onClick={`${routes.user.signin()}?${searchParams}`}>
					{t("user.signIn.title")}
				</Button>
			</Buttons>
		</Layout>
	)
}

export default RegularSignup
