import { type FC, useEffect, useLayoutEffect, useState } from "react"
import styled, { css, keyframes } from "styled-components"

import CommunityProfileAvatar from "@forento/shared/components/CommunityProfileAvatar"
import Tooltip from "@forento/shared/components/Tooltip"
import { type PublicCommunityProfile } from "@forento/shared/models/community"

import { useUser } from "~/contexts/UserContext"
import { MessageIcon, ProfileIcon } from "~/themes/original/components/Icon"
import routes from "~/utilities/routes"
import { lightTextColor } from "~/utilities/styles"

import Button from "./Button"

type Props = { authorElement: HTMLElement | null; profile: PublicCommunityProfile | null }

const ProfilePopup: FC<Props> = ({ authorElement, profile }) => {
	const [popup, setPopup] = useState<{ profile: PublicCommunityProfile; x: number; y: number; xOffset: number }>()

	useEffect(() => {
		if (authorElement === null || profile === null) return

		let isCancelled = false

		const handleMouseEnter = () => {
			isCancelled = false

			setTimeout(() => {
				if (isCancelled) return
				setPopup({
					profile,
					x: authorElement.offsetLeft,
					y: authorElement.offsetTop,
					xOffset: authorElement.getBoundingClientRect().width + 16,
				})
			}, 500)
		}

		const handleMouseExit = () => {
			isCancelled = true
		}

		authorElement.addEventListener("mouseenter", handleMouseEnter)
		authorElement.addEventListener("mouseleave", handleMouseExit)

		return () => {
			authorElement.removeEventListener("mouseenter", handleMouseEnter)
			authorElement.removeEventListener("mouseleave", handleMouseExit)
		}
	}, [authorElement, profile])

	return <Element data={popup ?? null} onClose={() => setPopup(undefined)} />
}

type ElementProps = {
	data: {
		profile: PublicCommunityProfile
		x: number
		y: number
		xOffset: number
	} | null
	onClose(): void
}

const Element: React.FC<ElementProps> = ({ data, onClose }) => {
	const user = useUser().user!

	const [state, setState] = useState<"idle" | "open" | "closed">(data === null ? "idle" : "open")
	const [cachedData, setCachedData] = useState(data)

	useLayoutEffect(() => {
		if (data === null) {
			setState("closed")
			return
		}
		setCachedData(data)
		setState("open")
	}, [data])

	useEffect(() => {
		if (data !== null) return

		const timeout = setTimeout(() => {
			setCachedData(null)
		}, 1000)

		return () => {
			clearTimeout(timeout)
		}
	}, [data])

	if (cachedData === null) return null

	const { profile, x, y, xOffset } = cachedData

	return (
		<Container $x={x} $y={y} onMouseLeave={onClose}>
			<Popup $xOffset={xOffset} $state={state}>
				<Header>
					<CommunityProfileAvatar profile={profile} size={82} />
					<Details>
						<Name>
							{profile.firstName} {profile.lastName}
						</Name>
						{profile.gamification.currentLevel !== null && (
							<Level>
								Level {profile.gamification.currentLevel.tier} -{" "}
								{profile.gamification.currentLevel.label}
							</Level>
						)}
					</Details>
				</Header>
				<Stats>
					<Stat.Container>
						<Stat.Value>{profile.posts}</Stat.Value>
						<Stat.Label>Posts</Stat.Label>
					</Stat.Container>
					<Stat.Container>
						<Stat.Value>{profile.reactions}</Stat.Value>
						<Stat.Label>Reactions</Stat.Label>
					</Stat.Container>
					<Stat.Container>
						<Stat.Value>{profile.comments}</Stat.Value>
						<Stat.Label>Comments</Stat.Label>
					</Stat.Container>
				</Stats>
				<Divider />
				{profile.id !== user.communityProfileId && (
					<Actions>
						<Tooltip tooltip="View full profile">
							<Action onClick={routes.community.profile(profile.id)}>
								<ProfileIcon />
							</Action>
						</Tooltip>
						<Tooltip tooltip="Send message">
							<Action onClick={routes.community.conversation(profile.id)}>
								<MessageIcon />
							</Action>
						</Tooltip>
					</Actions>
				)}
			</Popup>
		</Container>
	)
}

const Container = styled.div<{ $x: number; $y: number }>`
	position: absolute;
	top: ${props => props.$y}px;
	left: ${props => props.$x}px;
`

const openAnimation = keyframes`
	from { transform: scale(0); }
	to { transform: scale(1); }
`

const closeAnimation = keyframes`
	from { transform: scale(1); }
	to { transform: scale(0);}
`

const Popup = styled.div<{ $xOffset: number; $state: "idle" | "open" | "closed" }>`
	transition: 0.1s transform;
	transform-origin: top left;
	margin-left: ${props => props.$xOffset}px;
	background-color: white;
	box-shadow: 0 20px 40px -2px rgba(0, 0, 0, 0.16);
	border-radius: 8px;
	padding: 24px;
	display: flex;
	flex-direction: column;
	gap: 16px;

	${props =>
		props.$state === "open"
			? css`
					animation: ${openAnimation} 0.15s forwards;
				`
			: props.$state === "closed"
				? css`
						animation: ${closeAnimation} 0.15s forwards;
					`
				: ""}
`

const Header = styled.div`
	display: flex;
	gap: 16px;
`

const Details = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 4px;
`

const Name = styled.h1`
	font-size: 22px;
	font-weight: 600;
	color: #151d48;
`

const Level = styled.p`
	font-size: 14px;
	color: ${lightTextColor};
`

const Divider = styled.hr`
	width: 100%;
	border: none;
	border-bottom: 1px solid #dedede;
	margin: 8px 0;
`

const Actions = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
`

const Action = styled(Button)`
	width: 24px;
	height: 24px;
	color: ${lightTextColor};
`

const Stats = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	gap: 8px;
`

const Stat = {
	Container: styled.div`
		flex: 1 0 0;
		display: flex;
		flex-direction: column;
		align-items: center;
	`,
	Value: styled.p`
		font-size: 22px;
		font-weight: bold;
		color: #151d48;
		margin-bottom: 6px;
	`,
	Label: styled.p`
		font-size: 14px;
		color: #151d48;
	`,
}

export default ProfilePopup
