import styled from "styled-components"

import Button from "~/themes/original/components/Button"

export const TermsOfService = styled.p`
	font-weight: 500;
	font-size: 16px;
	color: #151d48;
`

export const TermsOfServiceButton = styled(Button)`
	display: inline;
	color: ${props => props.theme.primaryColor};
`
