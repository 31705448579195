import { useState } from "react"
import { useParams } from "react-router"
import styled from "styled-components"
import Values from "values.js"

import CommunityProfileAvatar from "@forento/shared/components/CommunityProfileAvatar"
import Form from "@forento/shared/components/Form"
import { hasAccessToPostCommunityComment, hasAccessToReactToCommunityPost } from "@forento/shared/utilities/community"
import { toDateTimeString } from "@forento/shared/utilities/date"
import { parseNumber, toCountFormat } from "@forento/shared/utilities/number"

import { useAlert } from "~/contexts/AlertContext"
import { usePlatform } from "~/contexts/PlatformContext"
import { useUser } from "~/contexts/UserContext"
import Button, { SubmitButton } from "~/themes/original/components/Button"
import { CommentIcon, LikeIcon } from "~/themes/original/components/Icon"
import InputField from "~/themes/original/components/InputField"
import Layout from "~/themes/original/components/Layout"
import PartialLoadingPage from "~/themes/original/components/PartialLoadingPage"
import PostReactions from "~/themes/original/components/PostReactions"
import ProfilePopup from "~/themes/original/components/ProfilePopup"
import { toRelativeDateString } from "~/themes/original/utilities/date"
import routes from "~/utilities/routes"
import { lightTextColor } from "~/utilities/styles"
import trpc, { swr } from "~/utilities/trpc"

import Attachment from "./Attachment"
import Comment from "./Comment"
import PollOption from "./PollOption"

const CommunityPostPage: React.FC = () => {
	const postId = parseNumber(useParams().postId ?? "") ?? -1
	const user = useUser()
	const platform = usePlatform().platform!
	const alert = useAlert()

	const { data: post, error, mutate } = swr.community.getPost.useSWR(postId)

	const [text, setText] = useState("")
	const [isSubmittingComment, setSubmittingComment] = useState(false)
	const [isReactionPopupOpen, setReactionPopupOpen] = useState(false)

	const [authorElement, setAuthorElement] = useState<HTMLDivElement | null>(null)

	const handleVote = async (pollOptionId: number | null) => {
		if (post == null) return
		await trpc.community.setPostVote.mutate({
			postId: post.id,
			pollOptionId:
				pollOptionId !== null && !post.pollOptions.find(x => x.id === pollOptionId)?.isSelected
					? pollOptionId
					: null,
		})
		await mutate()
	}

	const handleLike = async (reaction: boolean) => {
		if (reaction) {
			await trpc.community.createPostReaction.mutate(postId)
		} else {
			await trpc.community.deletePostReaction.mutate(postId)
		}
		await mutate()
	}

	const handleSubmitComment = async () => {
		setSubmittingComment(true)
		try {
			await trpc.community.createPostComment.mutate({ postId, text })
			setText("")
			await mutate()
		} finally {
			setSubmittingComment(false)
		}
	}

	const handleDeleteComment = async (commentId: number) => {
		const dialog = await alert.confirm("Delete comment", "Are you sure you want to delete this comment?")
		if (!dialog.result) return

		await trpc.community.deletePostComment.mutate(commentId)

		await mutate()
		dialog.close()
	}

	const hasAccessToReact =
		platform.community !== null && hasAccessToReactToCommunityPost(user.user!, platform.community)

	return (
		<Layout onBackButtonClick={routes.community.index()}>
			<ProfilePopup authorElement={authorElement} profile={post?.profile ?? null} />
			<PostReactions
				postId={isReactionPopupOpen ? (post?.id ?? null) : null}
				onClose={() => setReactionPopupOpen(false)}
			/>
			{error ? (
				<p>Failed to load post.</p>
			) : post === undefined ? (
				<PartialLoadingPage />
			) : post === null ? (
				<p>Post was not found</p>
			) : (
				<>
					<PostContent>
						<Header>
							<HeaderGroup>
								<CommunityProfileAvatar profile={post.profile} ref={setAuthorElement} />
								<HeaderColumn>
									<Author onClick={routes.community.profile(post.profile.id)}>
										{post.profile.firstName} {post.profile.lastName}
									</Author>
									<Date title={toDateTimeString(post.createDate)}>
										{toRelativeDateString(post.createDate)}
									</Date>
								</HeaderColumn>
							</HeaderGroup>
						</Header>
						<Title>{post.title}</Title>
						<Content dangerouslySetInnerHTML={{ __html: post.htmlContent }} />
						{post.pollOptions.length > 0 && (
							<PollOptions>
								{post.pollOptions.map((option, index) => (
									<PollOption
										key={index}
										option={option}
										onChange={isSelected => (isSelected ? handleVote(option.id) : handleVote(null))}
									/>
								))}
							</PollOptions>
						)}
						{post.attachments.length > 0 && (
							<Attachments>
								{post.attachments.map(attachment => (
									<Attachment key={attachment.id} attachment={attachment} />
								))}
							</Attachments>
						)}
						<Footer>
							{hasAccessToReact ? (
								<FooterGroup>
									<LikeButton onClick={() => handleLike(!post.hasUserReacted)}>
										<FooterGroupIcon as={LikeIcon} isFilled={post.hasUserReacted} />
									</LikeButton>
									<LikeCountButton onClick={() => setReactionPopupOpen(true)}>
										{toCountFormat(post.reactions)}
									</LikeCountButton>
								</FooterGroup>
							) : (
								<FooterGroup>
									<FooterGroupIcon as={LikeIcon} />
									{toCountFormat(post.reactions)}
								</FooterGroup>
							)}

							<FooterGroup>
								<FooterGroupIcon as={CommentIcon} />
								{toCountFormat(post.comments.length)}
							</FooterGroup>
						</Footer>
					</PostContent>
					<Comments>
						{post.comments.map(comment => (
							<Comment
								key={comment.id}
								comment={comment}
								onDelete={
									comment.profile.id === user.user!.communityProfileId
										? () => handleDeleteComment(comment.id)
										: undefined
								}
							/>
						))}
						{platform.community !== null &&
							hasAccessToPostCommunityComment(user.user!, platform.community) && (
								<SubmitComment onSubmit={handleSubmitComment}>
									<InputField placeholder="Enter comment..." value={text} onChange={setText} />
									<SubmitButton variant="primary" isLoading={isSubmittingComment}>
										Post
									</SubmitButton>
								</SubmitComment>
							)}
					</Comments>
				</>
			)}
		</Layout>
	)
}

const PostContent = styled.div`
	background-color: white;
	border-radius: 8px;
	padding: 16px 24px;
`

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 24px;
	margin-bottom: 16px;
`

const HeaderGroup = styled.div`
	display: flex;
	align-items: center;
	gap: 24px;
`

const HeaderColumn = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 2px;
`

const Author = styled(Button)`
	font-weight: 600;
	font-size: 20px;
	color: #151d48;
`

const Date = styled.p`
	font-size: 14px;
	color: ${lightTextColor};
`

const Title = styled.h1`
	font-weight: 500;
	font-size: 16px;
	color: #151d48;
	margin: 0 ${32 - 24}px 8px;
`

const Content = styled.div`
	font-size: 16px;
	color: #444a6d;
	margin: 0 ${32 - 24}px 16px;
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const PollOptions = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-bottom: 16px;
`

const Attachments = styled.div`
	font-size: 16px;
	color: ${lightTextColor};
	margin-bottom: 32px;
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
`

const Footer = styled.div`
	display: flex;
	align-items: center;
	gap: 42px;
	margin: 0 ${32 - 24}px;
`

const FooterGroup = styled.div`
	display: flex;
	align-items: center;
	font-size: 16px;
	color: ${lightTextColor};
	gap: 12px;
`

const LikeButton = styled(Button)`
	display: flex;
	color: ${lightTextColor};
`

const LikeCountButton = styled(Button)`
	font-size: 16px;
	color: ${lightTextColor};
`

const FooterGroupIcon = styled.div`
	width: 24px;
	height: 24px;
`

const Comments = styled.div`
	width: 100%;
	align-self: flex-start;
	background-color: ${props => new Values(props.theme.primaryColor).tint(90).hexString()};
	border-radius: 8px;
	padding: 22px;
	margin-top: ${20 + 16}px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 12px;
	position: relative;

	&:after {
		content: "";
		position: absolute;
		top: -20px;
		left: 20px;
		border-left: 20px solid transparent;
		border-right: 20px solid transparent;
		border-bottom: 20px solid ${props => new Values(props.theme.primaryColor).tint(90).hexString()};
	}

	@media (min-width: 600px) {
		width: unset;
	}
`

const SubmitComment = styled(Form)`
	width: 100%;
	display: flex;
	align-items: center;
	gap: 22px;

	flex-direction: column;
	align-items: flex-end;

	@media (min-width: 600px) {
		min-width: 500px;
		flex-direction: row;
	}
`

export default CommunityPostPage
