import { type FC, Fragment } from "react"
import styled from "styled-components"

import { useTermsOfService } from "~/api/termsOfService"
import Button from "~/themes/school/components/Button"
import Layout from "~/themes/school/components/Layout"
import { PageTitle } from "~/themes/school/components/PageHeader"
import PartialLoadingPage from "~/themes/school/components/PartialLoadingPage"
import { fonts } from "~/themes/school/styles"
import { useTranslation } from "~/translations"

const TermsOfServicePage: FC = () => {
	const content = useTermsOfService()
	const t = useTranslation()

	return (
		<Layout>
			<PageTitle>{t("termsOfService")}</PageTitle>
			<Content>
				{content.status === "success" ? (
					<>
						{content.terms.static.introItems.map((item, index) => (
							<Text key={index}>{item}</Text>
						))}
						{content.terms.static.sections.map((section, index) => (
							<Fragment key={index}>
								<Section>
									<NumberColumn>
										<SectionTitle>{index + 1}.</SectionTitle>
									</NumberColumn>
									<ContentColumn>
										<SectionTitle>{section.title}</SectionTitle>
									</ContentColumn>
								</Section>
								{section.items.map((item, itemIndex) => (
									<Section key={itemIndex}>
										<NumberColumn>
											{!section.skipItemNumbers && (
												<Text>
													{index + 1}.{itemIndex + 1}.
												</Text>
											)}
										</NumberColumn>
										<ContentColumn>
											<Text>{item}</Text>
										</ContentColumn>
									</Section>
								))}
							</Fragment>
						))}
						{content.terms.custom && (
							<>
								<Section>
									<NumberColumn>
										<SectionTitle>{content.terms.static.sections.length + 1}.</SectionTitle>
									</NumberColumn>
									<ContentColumn>
										<SectionTitle>EXTRA TERMS</SectionTitle>
									</ContentColumn>
								</Section>
								<Section>
									<NumberColumn></NumberColumn>
									<ContentColumn>
										<Text>
											<Button
												variant="primary"
												onClick={() => {
													window
														.open("")!
														.document.write(
															"<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
																encodeURI(content.terms.custom!) +
																"'></iframe>",
														)
												}}
											>
												Link to document
											</Button>
										</Text>
									</ContentColumn>
								</Section>
							</>
						)}
					</>
				) : content.status === "loading" ? (
					<PartialLoadingPage />
				) : (
					<p>{t("failedToLoad")}</p>
				)}
			</Content>
		</Layout>
	)
}

const Content = styled.div`
	background-color: white;
	padding: 42px;
`

const Section = styled.div`
	display: flex;

	@media (max-width: 999px) {
		margin-left: 16px;
		margin-right: 16px;
	}
`

const NumberColumn = styled.div`
	flex: 0 0 42px;
	font-size: 14px;
`

const ContentColumn = styled.div`
	flex: 1 0 0;
`

const SectionTitle = styled.h2`
	font-family: ${fonts.quicksand};
	font-size: 16px;
	font-weight: bold;
	margin-bottom: 8px;
`

const Text = styled.p`
	font-size: 14px;
	margin-bottom: 16px;
`

export default TermsOfServicePage
