import type React from "react"
import { useLayoutEffect, useState } from "react"
import styled, { css, useTheme } from "styled-components"

import { type CoursePageAnswer } from "@forento/shared/models/course"

interface MultiAnswerTypeProps {
	answers: CoursePageAnswer[]
	setSelection(selectedAnswerIds: number[]): void
}

const MultiAnswerType: React.FC<MultiAnswerTypeProps> = ({ answers, setSelection }) => {
	const [selectedAnswerIds, setSelectedAnswerIds] = useState<number[]>([])

	useLayoutEffect(() => {
		setSelectedAnswerIds([])
	}, [answers])

	return (
		<Answers>
			{answers.map(answer => {
				const isChecked = selectedAnswerIds.includes(answer.id)

				return (
					<AnswerContainer key={answer.id}>
						<AnswerInput type="checkbox" value={answer.id} />
						<Answer
							$isChecked={isChecked}
							onClick={() => {
								const newValue = selectedAnswerIds.includes(answer.id)
									? selectedAnswerIds.filter(x => x !== answer.id)
									: [...selectedAnswerIds, answer.id]

								setSelectedAnswerIds(newValue)
								setSelection(newValue)
							}}
						>
							<Checkmark>
								<CheckmarkIcon isChecked={isChecked} />
							</Checkmark>
							{answer.text}
						</Answer>
					</AnswerContainer>
				)
			})}
		</Answers>
	)
}

const Answers = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const AnswerContainer = styled.label`
	display: block;
	background-color: white;
	border: 0.5px solid #e8e8ed;
	border-radius: 8px;
	padding: 16px;
	cursor: pointer;
`

const AnswerInput = styled.input`
	display: none;
`

const Answer = styled.div<{ $isChecked: boolean }>`
	display: flex;
	align-items: center;
	gap: 16px;
	color: #151d48;
	font-size: 16px;
	letter-spacing: 0.3px;

	${props =>
		props.$isChecked &&
		css`
			font-weight: 600;
			letter-spacing: 0;
		`}
`

const Checkmark = styled.div`
	flex: 0 0 auto;
	width: 24px;
	height: 24px;
	border: 1px solid ${props => props.theme.primaryColor};
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
`

const CheckmarkIcon: React.FC<{ isChecked: boolean }> = ({ isChecked }) => {
	const theme = useTheme()

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			width={16}
			height={16}
			fill={theme.primaryColor}
			style={{ transition: "0.1s", transform: `scale(${isChecked ? "1" : "0"})`, transformOrigin: "center" }}
		>
			<path d="M0 0h24v24H0V0z" fill="none" />
			<path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
		</svg>
	)
}

export default MultiAnswerType
