import { Navigate } from "react-router"
import { useSearchParams } from "react-router-dom"

import { useUser } from "~/contexts/UserContext"
import Button from "~/themes/school/components/Button"
import Hero from "~/themes/school/components/Hero"
import Layout from "~/themes/school/components/Layout"
import { PageTitle } from "~/themes/school/components/PageHeader"
import { useTranslation } from "~/translations"
import routes from "~/utilities/routes"
import trpc from "~/utilities/trpc"

const CreateProfilePage: React.FC = () => {
	const [searchParams] = useSearchParams()
	const user = useUser()
	const t = useTranslation()

	if (user.user!.communityProfileId !== null) {
		return <Navigate to={searchParams.get("next") ?? routes.community.index()} />
	}

	const handleCreateProfile = async () => {
		await trpc.community.createProfile.mutate()
		await user.reload()
	}

	return (
		<Layout>
			<PageTitle>{t("community.title")}</PageTitle>
			<Hero
				title={t("community.join")}
				description={t("community.joinMessage")}
				callToAction={
					<Button variant="primary" onClick={handleCreateProfile}>
						{t("community.makeProfileVisible")}
					</Button>
				}
			/>
		</Layout>
	)
}

export default CreateProfilePage
