import { type FC } from "react"

import Card, { Cards } from "~/themes/original/components/Card"
import Layout from "~/themes/original/components/Layout"
import PartialLoadingPage from "~/themes/original/components/PartialLoadingPage"
import routes from "~/utilities/routes"
import { swr } from "~/utilities/trpc"

const ExamsPage: FC = () => {
	const { data: exams, error } = swr.exam.listPersonalized.useSWR()

	return (
		<Layout>
			{error ? (
				<p>Failed to load exams.</p>
			) : exams === undefined ? (
				<PartialLoadingPage />
			) : (
				<Cards>
					{exams.map(exam => (
						<Card
							key={exam.id}
							title={exam.name}
							description={exam.shortDescription ?? undefined}
							footer={{
								text:
									exam.userScore !== null
										? `${exam.userScore}/${exam.questions.length}`
										: `${exam.questions.length} questions`,
								isBold: true,
							}}
							onClick={routes.exam.detail(exam.id)}
						/>
					))}
				</Cards>
			)}
		</Layout>
	)
}

export default ExamsPage
