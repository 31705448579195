import styled from "styled-components"

import Layout from "~/themes/original/components/Layout"
import PartialLoadingPage from "~/themes/original/components/PartialLoadingPage"
import { swr } from "~/utilities/trpc"

import Profile from "./Profile"

const LeaderboardPage: React.FC = () => {
	const { data: profiles, error } = swr.community.listLeaderboard.useSWR()

	return (
		<Layout>
			{error ? (
				<p>Failed to load leaderboard.</p>
			) : profiles === undefined ? (
				<PartialLoadingPage />
			) : (
				<Container>
					{profiles.map((profile, index) => (
						<Profile key={profile.id} profile={profile} position={index + 1} />
					))}
				</Container>
			)}
		</Layout>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

export default LeaderboardPage
