import i18n from "i18next"
import { initReactI18next, useTranslation as useT } from "react-i18next"

import { type PlatformLanguageId } from "@forento/shared/models/platform"

import german from "./de.json"
import english from "./en.json"
import spanish from "./es.json"
import french from "./fr.json"
import italian from "./it.json"
import swedish from "./sv.json"

export const resources = {
	en: { translation: english },
	sv: { translation: swedish },
	es: { translation: spanish },
	fr: { translation: french },
	de: { translation: german },
	it: { translation: italian },
}

export const useTranslation = () => useT("translation").t

export const useLocale = () => useT("translation").i18n.language

i18n.use(initReactI18next).init({
	resources,
	lng: "en",
	interpolation: { escapeValue: false },
})

export function changeLanguage(language: PlatformLanguageId) {
	i18n.changeLanguage(language)
}
