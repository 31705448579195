import styled from "styled-components"

import Button from "~/themes/school/components/Button"

export const TermsOfService = styled.p`
	text-align: center;
	line-height: 1.4;
	font-style: italic;
`

export const TermsOfServiceButton = styled(Button)`
	display: inline;
	color: ${props => props.theme.primaryColor};
`
