import styled from "styled-components"

import CommunityProfileAvatar from "@forento/shared/components/CommunityProfileAvatar"
import { type PublicCommunityProfile } from "@forento/shared/models/community"

import { useUser } from "~/contexts/UserContext"
import Button from "~/themes/school/components/Button"
import { AuthorityIcon, MessageIcon } from "~/themes/school/components/Icon"
import { fonts, lightTextColor } from "~/themes/school/styles"
import { useTranslation } from "~/translations"
import routes from "~/utilities/routes"

const Profile: React.FC<{ profile: PublicCommunityProfile }> = ({ profile }) => {
	const user = useUser()
	const t = useTranslation()

	return (
		<Container>
			<Header>
				<CommunityProfileAvatar profile={profile} />
				<Name>
					{profile.firstName} {profile.lastName}
					{profile.isCreator && <StyledAuthorityIcon />}
				</Name>
			</Header>
			{profile.gamification.currentLevel !== null && (
				<Level>
					{t("community.gamificationLevel", {
						level: profile.gamification.currentLevel.tier,
						label: profile.gamification.currentLevel.label,
					})}
				</Level>
			)}
			{profile.id !== user.user!.communityProfileId && (
				<Actions>
					<Action onClick={routes.community.conversation(profile.id)}>
						<MessageIcon />
					</Action>
				</Actions>
			)}
			<Stats>
				<Stat.Container>
					<Stat.Value>{profile.posts}</Stat.Value>
					<Stat.Label>{t("community.posts")}</Stat.Label>
				</Stat.Container>
				<Stat.Container>
					<Stat.Value>{profile.reactions}</Stat.Value>
					<Stat.Label>{t("community.reactions")}</Stat.Label>
				</Stat.Container>
				<Stat.Container>
					<Stat.Value>{profile.comments}</Stat.Value>
					<Stat.Label>{t("community.comments")}</Stat.Label>
				</Stat.Container>
			</Stats>
		</Container>
	)
}

const Container = styled.div`
	flex: 1 0 0;
	background-color: #ffffff;
	box-shadow: 0px 4px 30px 0px rgba(223, 232, 255, 0.25);
	padding: 24px;
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const Header = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
`

const Name = styled.h1`
	font-family: ${fonts.quicksand};
	font-size: 20px;
	font-weight: bold;
	display: flex;
	align-items: center;
	gap: 8px;
`

const StyledAuthorityIcon = styled(AuthorityIcon)`
	width: 24px;
	height: 24px;
`

const Level = styled.p`
	color: ${lightTextColor};
`

const Stats = styled.div`
	width: 100%;
	display: flex;
	gap: 8px;
`

const Stat = {
	Container: styled.div`
		flex: 1 0 0;
		display: flex;
		flex-direction: column;
		align-items: center;
	`,
	Value: styled.p`
		font-size: 22px;
		font-weight: bold;
		margin-bottom: 6px;
	`,
	Label: styled.p`
		font-size: 14px;
	`,
}

const Actions = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 16px;
`

const Action = styled(Button)`
	width: 24px;
	height: 24px;
`

export default Profile
