import { type FC } from "react"
import { Navigate, useParams } from "react-router"
import styled from "styled-components"
import Values from "values.js"

import AspectRatio from "@forento/shared/components/AspectRatio"
import { parseNumber } from "@forento/shared/utilities/number"

import { useDownloadable } from "~/api/downloadable"
import { PurchaseSuccessModalContextProvider } from "~/contexts/PurchaseSuccessModalContext"
import Button from "~/themes/original/components/Button"
import Layout from "~/themes/original/components/Layout"
import PartialLoadingPage from "~/themes/original/components/PartialLoadingPage"
import PurchaseItem from "~/themes/original/components/PurchaseItem"
import routes from "~/utilities/routes"
import { lightTextColor } from "~/utilities/styles"

const DownloadablePage: FC = () => {
	const downloadableId = parseNumber(useParams().downloadableId) ?? -1

	const downloadable = useDownloadable(downloadableId)

	if (downloadable.status === "not-found") return <Navigate to={routes.downloadable.index()} />

	return (
		<PurchaseSuccessModalContextProvider
			content={{
				type: "downloadable",
				isUnlocked: Boolean(downloadable.status === "unlocked"),
				payPalOrderId:
					downloadable.status === "unlocked" &&
					downloadable.unlockedCause === "purchased" &&
					downloadable.order.type === "paypal"
						? downloadable.order.id
						: null,
			}}
		>
			<Layout>
				{downloadable.status === "error" ? (
					<p>Failed to load downloadable.</p>
				) : downloadable.status === "loading" ? (
					<PartialLoadingPage />
				) : (
					<Container>
						<Content>
							<Details>
								<Title>{downloadable.title}</Title>
								{downloadable.longDescription && (
									<Description
										dangerouslySetInnerHTML={{ __html: downloadable.longDescription.html }}
									/>
								)}
								{downloadable.status === "paywall" ? (
									<PurchaseItem
										item={{ type: "downloadable", id: downloadable.id, price: downloadable.price }}
										onPurchase={downloadable.reload}
									/>
								) : downloadable.status === "sign-in-required" ? (
									<>
										<CallToActionText>
											This downloadable is only available to signed in users.
										</CallToActionText>
										<ButtonsRow>
											<Button variant="primary" onClick={routes.user.signin()}>
												Sign in
											</Button>
											<Button variant="primary" onClick={routes.user.signup()}>
												Sign up
											</Button>
										</ButtonsRow>
									</>
								) : (
									<Button
										variant="primary"
										onClick={downloadable.download}
										isLoading={downloadable.downloadProgress !== undefined}
									>
										{downloadable.downloadProgress === undefined
											? "Download"
											: `Downloading... ${Math.floor(downloadable.downloadProgress * 100)}%`}
									</Button>
								)}
							</Details>
							<ThumbnailContainer>
								<AspectRatio width={{ value: 100, unit: "percent" }} aspectRatio={16 / 12}>
									{downloadable.thumbnailFilePath !== null ? (
										<Thumbnail src={downloadable.thumbnailFilePath} />
									) : (
										<div />
									)}
								</AspectRatio>
							</ThumbnailContainer>
						</Content>
					</Container>
				)}
			</Layout>
		</PurchaseSuccessModalContextProvider>
	)
}

const Container = styled.div`
	@media (max-width: 999px) {
		margin: 0 16px;
	}
`

const Content = styled.div`
	background-color: ${props => new Values(props.theme.primaryColor).tint(90).hexString()};
	border-radius: 16px;
	display: flex;
	padding: 42px;
	gap: ${42 * 2}px;
	margin-bottom: 42px;
`

const Details = styled.div`
	flex: 1 0 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 22px;
`

const Title = styled.h1`
	font-weight: 500;
	font-size: 24px;
	color: #151d48;
`

const Description = styled.div`
	font-size: 16px;
	color: ${lightTextColor};
`

const ThumbnailContainer = styled.div`
	flex: 1 0 0;
	border-radius: 8px;
	overflow: hidden;

	@media (max-width: 999px) {
		display: none;
	}
`

const Thumbnail = styled.img.attrs({ alt: "Course thumbnail" })`
	object-fit: cover;
`

const CallToActionText = styled.p`
	font-size: 16px;
	color: ${lightTextColor};
	font-style: italic;
`

const ButtonsRow = styled.div`
	display: flex;
	gap: 16px;
`

export default DownloadablePage
