import styled from "styled-components"

import { useUser } from "~/contexts/UserContext"
import Button from "~/themes/school/components/Button"
import { BillingIcon } from "~/themes/school/components/Icon"
import { SettingsIcon, SignInIcon, SignOutIcon } from "~/themes/school/components/Icon"
import { useTranslation } from "~/translations"
import routes from "~/utilities/routes"

import useNavigationItems from "./useNavigationItems"

const NavigationMenu: React.FC<{ isOpen: boolean }> = ({ isOpen }) => {
	const user = useUser()
	const navItems = useNavigationItems()
	const t = useTranslation()

	return (
		<Container $isOpen={isOpen}>
			{navItems.map(item => (
				<StyledButton key={item.label} onClick={item.onClick}>
					<StyledIcon as={item.icon} />
					{item.label}
				</StyledButton>
			))}
			{user.user ? (
				<>
					<StyledButton onClick={routes.user.settings()}>
						<StyledIcon as={SettingsIcon} />
						{t("settings")}
					</StyledButton>

					<StyledButton onClick={routes.billing.index()}>
						<StyledIcon as={BillingIcon} />
						{t("billing.title")}
					</StyledButton>

					<StyledButton onClick={user.signOut}>
						<StyledIcon as={SignOutIcon} />
						{t("signOut")}
					</StyledButton>
				</>
			) : (
				<StyledButton onClick={routes.user.signin()}>
					<StyledIcon as={SignInIcon} />
					{t("user.signIn.title")}
				</StyledButton>
			)}
		</Container>
	)
}

const Container = styled.nav<{ $isOpen: boolean }>`
	position: absolute;
	background-color: white;
	top: ${24 + 18 * 2}px;
	left: 0;
	right: 0;
	min-height: calc(100vh - ${24 + 18 * 2}px);
	padding: 24px;
	transform-origin: top;
	transform: ${props => (props.$isOpen ? "translateX(0)" : "translateX(-100%)")};
	transition: transform 0.15s;

	@media (min-width: 1000px) {
		display: none;
	}
`

const StyledButton = styled(Button)`
	width: 100%;
	font-size: 16px;
	display: flex;
	align-items: center;
	gap: 16px;
	padding: 12px 0;
`

const StyledIcon = styled.div`
	width: 24px;
	height: 24px;
`

export default NavigationMenu
