import { type FC, useId } from "react"
import styled from "styled-components"

import { getAlphabetString } from "@forento/shared/utilities/string"

interface Props {
	text: string
	isMultipleChoice: boolean
	index: number
	isChecked: boolean
	onClick(): void
}

const Answer: FC<Props> = ({ text, isMultipleChoice, index, isChecked, onClick }) => {
	const id = useId()

	return (
		<Container>
			<input type={isMultipleChoice ? "checkbox" : "radio"} id={id} onChange={onClick} checked={isChecked} />
			<label htmlFor={id}>
				{getAlphabetString(index)}. {text}
			</label>
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`

export default Answer
