import styled from "styled-components"

import { toPriceString } from "@forento/shared/utilities/currency"
import { toDateString } from "@forento/shared/utilities/date"
import { toPaymentStatusString } from "@forento/shared/utilities/payment"

import Button from "~/themes/school/components/Button"
import Layout from "~/themes/school/components/Layout"
import { PageTitle } from "~/themes/school/components/PageHeader"
import PartialLoadingPage from "~/themes/school/components/PartialLoadingPage"
import Table from "~/themes/school/components/Table"
import { useTranslation } from "~/translations"
import { swr } from "~/utilities/trpc"

const BillingPage: React.FC = () => {
	const t = useTranslation()

	const { data: transactions, error } = swr.payment.listTransactions.useSWR()

	return (
		<Layout>
			<PageTitle>{t("billing.title")}</PageTitle>
			<Content>
				{error ? (
					<p>{t("failedToLoad")}</p>
				) : transactions === undefined ? (
					<PartialLoadingPage />
				) : (
					<Table
						headers={[
							t("billing.date"),
							t("billing.product"),
							t("billing.amount"),
							t("billing.status"),
							t("billing.receipt"),
						]}
						data={transactions.map(transaction => ({
							id: transaction.id,
							columns: [
								toDateString(transaction.date),
								transaction.item?.title ?? "Other",
								toPriceString(transaction),
								toPaymentStatusString(transaction.status),
								transaction.receipt.status === "available" ? (
									<ReceiptButton onClick={transaction.receipt.url} newTab>
										{t("general.download")}
									</ReceiptButton>
								) : transaction.receipt.status === "paypal" ? (
									<ReceiptButton onClick="https://www.paypal.com" newTab>
										{t("billing.visitPayPal")}
									</ReceiptButton>
								) : null,
							],
						}))}
					/>
				)}
			</Content>
		</Layout>
	)
}

const Content = styled.div`
	display: flex;
	flex-direction: column;
	background-color: white;
	padding: 42px;
	gap: 42px;

	@media (max-width: 999px) {
		padding: 0 16px;
	}
`

const ReceiptButton = styled(Button)`
	color: ${props => props.theme.primaryColor};
`

export default BillingPage
