import { type FC } from "react"
import styled from "styled-components"

import Button from "~/themes/original/components/Button"
import { AttachmentIcon, LinkIcon } from "~/themes/original/components/Icon"

type Props = { resources: { type: "link" | "attachment"; label: string; href: string }[] }

const Resources: FC<Props> = ({ resources }) => (
	<Container>
		{resources.map((resource, index) => (
			<Item key={index} onClick={resource.href} newTab>
				{resource.type === "link" ? (
					<Icon as={LinkIcon} />
				) : resource.type === "attachment" ? (
					<Icon as={AttachmentIcon} />
				) : null}
				{resource.label}
			</Item>
		))}
	</Container>
)

const Container = styled.div`
	border: 1px solid #e8e8ed;
	border-radius: 8px;
	overflow: hidden;
`

const Item = styled(Button)`
	background-color: white;
	padding: 16px 24px;
	font-weight: 500;
	font-size: 18px;
	color: #302d3a;
	display: flex;
	align-items: center;
	gap: 16px;

	&:not(:last-child) {
		border-bottom: 1px solid #e8e8ed;
	}
`

const Icon = styled.div`
	width: 24px;
	height: 24px;
`

export default Resources
