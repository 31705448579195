import { type ComponentType, type FC, type ReactNode } from "react"
import styled, { css } from "styled-components"

import AspectRatio from "@forento/shared/components/AspectRatio"

import { backgroundColor, lightTextColor } from "~/themes/school/styles"

const Card = styled.div`
	flex: 0 1 350px;
	background-color: #ffffff;
	box-shadow: 0px 4px 30px 0px rgba(223, 232, 255, 0.25);
	padding: 32px 42px;
	display: flex;
	flex-direction: column;
	gap: 22px;
	text-decoration: none;
`

export const Cards = styled.div`
	display: flex;
	gap: 24px;
	flex-wrap: wrap;

	@media (min-width: 1000px) {
		gap: 42px;
	}
`

export const CardHeader = styled.div`
	display: flex;
	flex-direction: column;

	@media (min-width: 1000px) {
		flex-direction: row;
		justify-content: space-between;
		gap: 8px;
	}
`

export const CardTitle = styled.h1`
	font-size: 16px;
	font-weight: bold;
	color: black;
`

export const CardSticker = styled.p<{ $isBold?: boolean }>`
	font-size: 16px;
	color: ${lightTextColor};

	${props =>
		props.$isBold &&
		css`
			font-size: 16px;
			font-weight: bold;
			color: #ffa412;
		`}
`

export const CardThumbnail: FC<{ filePath: string | null; sticker?: ReactNode }> = ({ filePath, sticker }) => (
	<ThumbnailAspectRatio aspectRatio={16 / 9} width={{ value: 100, unit: "percent" }}>
		<ThumbnailContainer>
			{filePath && <Thumbnail src={filePath} />}
			{sticker}
		</ThumbnailContainer>
	</ThumbnailAspectRatio>
)

export const CardIcon: FC<{ icon: ComponentType }> = ({ icon: Icon }) => (
	<IconAspectRatio aspectRatio={16 / 9} width={{ value: 75, unit: "percent" }}>
		<Icon />
	</IconAspectRatio>
)

export const CardText = styled.p`
	flex: 1;
	color: ${lightTextColor};
	font-size: 16px;
	text-align: center;
`

export const CardFooter = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 12px;
`

const ThumbnailAspectRatio = styled(AspectRatio)`
	background-color: ${backgroundColor};
	overflow: hidden;
`

const IconAspectRatio = styled(AspectRatio)`
	align-self: center;
	color: ${props => props.theme.primaryColor};
`

const ThumbnailContainer = styled.div`
	position: relative;
`

const Thumbnail = styled.img.attrs({ alt: "Thumbnail" })`
	width: 100%;
	height: 100%;
	object-fit: cover;
`

export default Card
