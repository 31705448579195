import { type FC, type ReactNode } from "react"
import styled from "styled-components"

type Props = {
	headers: (string | { label: string; width: number })[]
	data: { id: string | number; columns: ReactNode[] }[]
}

const Table: FC<Props> = ({ headers, data }) => (
	<div>
		<Header>
			{headers.map((header, index) =>
				typeof header === "string" ? (
					<HeaderCell key={`header-${index}`}>{header}</HeaderCell>
				) : (
					<HeaderCell key={`header-${index}`} $width={header.width}>
						{header.label}
					</HeaderCell>
				),
			)}
		</Header>
		{data.map(row => (
			<Row key={row.id}>
				{row.columns.map((cell, cellIndex) => {
					const headerCell = headers[cellIndex]
					if (typeof headerCell === "string") return <Cell key={cellIndex}>{cell}</Cell>
					return (
						<Cell key={cellIndex} $width={headerCell.width}>
							{cell}
						</Cell>
					)
				})}
			</Row>
		))}
	</div>
)

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 8px;
	border-bottom: 1px solid #e5e5e5;
`

const HeaderCell = styled.p<{ $width?: number }>`
	flex: ${({ $width }) => ($width !== undefined ? `0 0 ${$width}px` : "1 0 0")};
	font-size: 14px;
	color: #454d66;
	padding: 16px;
	display: flex;
	justify-content: center;

	&:not(:first-child) {
		border-left: 1px solid #e5e5e5;
	}
`

const Row = styled.li`
	display: flex;
	justify-content: space-between;
	gap: 8px;

	&:nth-of-type(2n) {
		background-color: #f5f8f9;
	}
`

const Cell = styled.p<{ $width?: number }>`
	flex: ${({ $width }) => ($width !== undefined ? `0 0 ${$width}px` : "1 0 0")};
	font-size: 16px;
	padding: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 16px;

	&:not(:first-child) {
		border-left: 1px solid #e5e5e5;
	}
`

export default Table
