import { type Theme } from "@forento/shared/models/platform"

import * as originalPages from "~/themes/original/pages"
import * as schoolPages from "~/themes/school/pages"
import r from "~/utilities/routes"

type AppRoute = { path: string; auth?: boolean; community?: boolean; page: Page }

type Page = { [theme in Theme]: React.ComponentType<object> }

type Pages = { [section: string]: { [page: string]: Page } }

const pages = {
	billing: {
		index: { 1: originalPages.BillingPage, 2: schoolPages.BillingPage },
	},
	event: {
		index: { 1: originalPages.EventsPage, 2: schoolPages.EventsPage },
		detail: { 1: originalPages.EventPage, 2: schoolPages.EventPage },
	},
	certificate: {
		index: { 1: originalPages.CertificatesPage, 2: schoolPages.CertificatesPage },
		detail: { 1: originalPages.CertificatePage, 2: schoolPages.CertificatePage },
	},
	community: {
		index: { 1: originalPages.CommunityPage, 2: schoolPages.CommunityPage },
		create: { 1: originalPages.CreateProfilePage, 2: schoolPages.CreateProfilePage },
		post: { 1: originalPages.CommunityPostPage, 2: schoolPages.CommunityPostPage },
		conversations: { 1: originalPages.ConversationsPage, 2: schoolPages.ConversationsPage },
		conversation: { 1: originalPages.ConversationsPage, 2: schoolPages.ConversationsPage },
		profile: { 1: originalPages.ProfilePage, 2: schoolPages.ProfilePage },
		leaderboard: { 1: originalPages.LeaderboardPage, 2: schoolPages.LeaderboardPage },
	},
	course: {
		index: { 1: originalPages.CoursesPage, 2: schoolPages.CoursesPage },
		detail: { 1: originalPages.CourseDetailPage, 2: schoolPages.CourseDetailPage },
		player: { 1: originalPages.CoursePlayerPage, 2: schoolPages.CoursePlayerPage },
	},
	exam: {
		index: { 1: originalPages.ExamsPage, 2: schoolPages.ExamsPage },
		detail: { 1: originalPages.ExamPage, 2: schoolPages.ExamPage },
		start: { 1: originalPages.StartExamPage, 2: schoolPages.StartExamPage },
		result: { 1: originalPages.ExamResultPage, 2: schoolPages.ExamResultPage },
	},
	membership: {
		index: { 1: originalPages.MembershipsPage, 2: schoolPages.MembershipsPage },
		detail: { 1: originalPages.MembershipPage, 2: schoolPages.MembershipPage },
		completingPayPal: { 1: originalPages.CompletingPayPalPage, 2: schoolPages.CompletingPayPalPage },
	},
	policy: {
		termsOfService: { 1: originalPages.TermsOfServicePage, 2: schoolPages.TermsOfServicePage },
		privacy: { 1: originalPages.PrivacyPolicyPage, 2: schoolPages.PrivacyPolicyPage },
	},
	downloadable: {
		index: { 1: originalPages.DownloadablesPage, 2: schoolPages.DownloadablesPage },
		detail: { 1: originalPages.DownloadablePage, 2: schoolPages.DownloadablePage },
	},
	session: {
		index: { 1: originalPages.SessionsPage, 2: schoolPages.SessionsPage },
		detail: { 1: originalPages.SessionPage, 2: schoolPages.SessionPage },
	},
	user: {
		signin: { 1: originalPages.SigninPage, 2: schoolPages.SigninPage },
		signup: { 1: originalPages.SignupPage, 2: schoolPages.SignupPage },
		invitedSignupMembership: { 1: originalPages.InvitedSignupMembership, 2: schoolPages.InvitedSignupMembership },
		resetPassword: { 1: originalPages.ResetPasswordPage, 2: schoolPages.ResetPasswordPage },
		newPassword: { 1: originalPages.NewPasswordPage, 2: schoolPages.NewPasswordPage },
		completeProfile: { 1: originalPages.CompleteProfilePage, 2: schoolPages.CompleteProfilePage },
		settings: { 1: originalPages.SettingsPage, 2: schoolPages.SettingsPage },
	},
	video: {
		index: { 1: originalPages.VideosPage, 2: schoolPages.VideosPage },
		detail: { 1: originalPages.VideoPage, 2: schoolPages.VideoPage },
	},
} satisfies Pages

const notFoundPage: Page = { 1: originalPages.NotFoundPage, 2: schoolPages.NotFoundPage }

const appRoutes: AppRoute[] = [
	{ path: r.billing.index(), page: pages.billing.index, auth: true },
	{ path: r.certificate.index(), page: pages.certificate.index, auth: true },
	{ path: r.certificate.detail(null), page: pages.certificate.detail, auth: true },
	{ path: r.event.index(), page: pages.event.index },
	{ path: r.event.detail(null), page: pages.event.detail },
	{ path: r.community.index(), page: pages.community.index, auth: true, community: true },
	{ path: r.community.create(), page: pages.community.create, auth: true },
	{ path: r.community.post(null), page: pages.community.post, auth: true, community: true },
	{ path: r.community.conversations(), page: pages.community.conversations, auth: true, community: true },
	{ path: r.community.conversation(null), page: pages.community.conversation, auth: true, community: true },
	{ path: r.community.profile(null), page: pages.community.profile, auth: true, community: true },
	{ path: r.community.leaderboard(), page: pages.community.leaderboard, auth: true, community: true },
	{ path: r.course.index(), page: pages.course.index },
	{ path: r.course.detail(null), page: pages.course.detail },
	{ path: r.course.player(null), page: pages.course.player, auth: true },
	{ path: r.exam.index(), page: pages.exam.index },
	{ path: r.exam.detail(null), page: pages.exam.detail },
	{ path: r.exam.start(null), page: pages.exam.start, auth: true },
	{ path: r.exam.result(null), page: pages.exam.result, auth: true },
	{ path: r.membership.index(), page: pages.membership.index },
	{ path: r.membership.detail(null), page: pages.membership.detail },
	{ path: r.membership.completingPayPal(), page: pages.membership.completingPayPal },
	{ path: r.policy.termsOfService(), page: pages.policy.termsOfService },
	{ path: r.policy.privacy(), page: pages.policy.privacy },
	{ path: r.downloadable.index(), page: pages.downloadable.index },
	{ path: r.downloadable.detail(null), page: pages.downloadable.detail },
	{ path: r.session.index(), page: pages.session.index },
	{ path: r.session.detail(null), page: pages.session.detail },
	{ path: r.user.signin(), page: pages.user.signin },
	{ path: r.user.signup(), page: pages.user.signup },
	{ path: r.user.invitedSignupMembership(), page: pages.user.invitedSignupMembership },
	{ path: r.user.resetPassword(), page: pages.user.resetPassword },
	{ path: r.user.newPassword(), page: pages.user.newPassword },
	{ path: r.user.completeProfile(), page: pages.user.completeProfile },
	{ path: r.user.settings(), page: pages.user.settings, auth: true },
	{ path: r.video.index(), page: pages.video.index },
	{ path: r.video.detail(null), page: pages.video.detail },
	{ path: "*", page: notFoundPage },
]

export default appRoutes
