import { type FC } from "react"
import styled from "styled-components"

import Answer from "./Answer"

interface Props {
	question: {
		text: string
		isMultipleChoice: boolean
		answers: {
			id: number
			text: string
		}[]
	}
	index: number
	selectedIds: number[]
	setSelectedIds(value: number[]): void
}

const Question: FC<Props> = ({ question, index, selectedIds, setSelectedIds }) => {
	const handleAnswerClick = (id: number) => {
		if (question.isMultipleChoice) {
			if (selectedIds.includes(id)) {
				setSelectedIds(selectedIds.filter(x => x !== id))
			} else {
				setSelectedIds([...selectedIds, id])
			}
		} else {
			setSelectedIds([id])
		}
	}

	return (
		<div>
			<Text>
				{index + 1}. {question.text}
			</Text>
			<Answers>
				{question.answers.map((answer, index) => (
					<Answer
						key={answer.id}
						text={answer.text}
						isMultipleChoice={question.isMultipleChoice}
						index={index}
						isChecked={selectedIds.includes(answer.id)}
						onClick={() => handleAnswerClick(answer.id)}
					/>
				))}
			</Answers>
		</div>
	)
}

const Text = styled.div`
	font-size: 20px;
	letter-spacing: 0.75px;
	margin-bottom: 4px;
`

const Answers = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
	margin-left: 1em;
`

export default Question
