import { type FC, useLayoutEffect, useState } from "react"
import { Navigate, useNavigate, useParams } from "react-router"
import styled from "styled-components"

import { parseNumber } from "@forento/shared/utilities/number"

import { useAlert } from "~/contexts/AlertContext"
import Button from "~/themes/school/components/Button"
import Layout from "~/themes/school/components/Layout"
import { PageBreadcrumb } from "~/themes/school/components/PageHeader"
import PartialLoadingPage from "~/themes/school/components/PartialLoadingPage"
import { useTranslation } from "~/translations"
import routes from "~/utilities/routes"
import trpc, { swr } from "~/utilities/trpc"

import Question from "./Question"

const StartExamPage: FC = () => {
	const examId = parseNumber(useParams().examId) ?? -1
	const alert = useAlert()
	const navigate = useNavigate()
	const t = useTranslation()

	const { data: exam, error } = swr.exam.getPersonalized.useSWR(examId)

	const [startDate] = useState(new Date())
	const [answers, setAnswers] = useState<Record<number, number[]>>({})

	useLayoutEffect(() => {
		setAnswers({})
	}, [exam?.questions])

	if (exam === null || (exam !== undefined && exam.userScore !== null)) return <Navigate to={routes.exam.index()} />

	const isValid = exam?.questions.every(question => answers[question.id]?.length > 0) ?? false

	const handleSubmit = async () => {
		if (!isValid) return

		const dialog = await alert.confirm(t("exam.complete"), t("exam.completePrompt"))
		if (!dialog.result) return

		try {
			const { resultId } = await trpc.exam.complete.mutate({
				id: examId,
				startDate,
				answers: Object.entries(answers).map(([questionId, answerIds]) => ({
					questionId: Number(questionId),
					answerIds,
				})),
			})
			dialog.close()
			navigate(routes.exam.result(resultId))
		} catch (error) {
			console.error(error)
		}
	}

	return (
		<Layout>
			<PageBreadcrumb path={[{ title: "Exams", link: routes.exam.index() }]} title={exam?.name ?? "..."} />
			<Container>
				{error ? (
					<p>{t("failedToLoad")}</p>
				) : exam === undefined ? (
					<PartialLoadingPage />
				) : (
					<>
						<Questions>
							{exam.questions.map((question, index) => (
								<Question
									key={question.id}
									question={question}
									index={index}
									selectedIds={answers[question.id] ?? []}
									setSelectedIds={value => {
										setAnswers({
											...answers,
											[question.id]: value,
										})
									}}
								/>
							))}
						</Questions>
						<ButtonRow>
							<Button variant="danger" onClick={() => setAnswers({})}>
								{t("clearFields")}
							</Button>
							<Button variant="primary" isDisabled={!isValid} onClick={handleSubmit}>
								{t("exam.complete")}
							</Button>
						</ButtonRow>
					</>
				)}
			</Container>
		</Layout>
	)
}

const Container = styled.div`
	padding: 64px;
	background-color: white;
`

const Questions = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin-bottom: 16px;
`

const ButtonRow = styled.div`
	display: flex;
	gap: 16px;
`

export default StartExamPage
