import { type ComponentType, type ReactNode } from "react"

type Provider = ComponentType<{ children: ReactNode }> | ComponentType<{ children?: ReactNode }>
export function combineContextProviders(providers: Provider[]): Provider {
	return providers.reduce(
		(AccumulatedComponents, CurrentComponent) => {
			return ({ children }: { children: ReactNode }) => (
				<AccumulatedComponents>
					<CurrentComponent>{children}</CurrentComponent>
				</AccumulatedComponents>
			)
		},
		({ children }: { children: ReactNode }) => <>{children}</>,
	)
}
