import { type ChangeEvent } from "react"
import type React from "react"
import { useId, useLayoutEffect, useState } from "react"
import styled, { css } from "styled-components"

import { type CoursePageAnswer } from "@forento/shared/models/course"

import { RadioIcon } from "~/themes/original/components/Icon"

interface SingleAnswerTypeProps {
	answers: CoursePageAnswer[]
	setSelection(selection: number): void
}

const SingleAnswerType: React.FC<SingleAnswerTypeProps> = ({ answers, setSelection }) => {
	const groupName = useId()

	const [selectedAnswerId, setSelectedAnswerId] = useState<number>()

	useLayoutEffect(() => {
		setSelectedAnswerId(undefined)
	}, [answers])

	return (
		<Answers
			onChange={(event: ChangeEvent<HTMLInputElement>) => {
				const selectedAnswerId = parseInt(event.target.value)
				setSelectedAnswerId(selectedAnswerId)
				setSelection(selectedAnswerId)
			}}
		>
			{answers.map(answer => {
				const isChecked = selectedAnswerId === answer.id

				return (
					<AnswerContainer key={answer.id}>
						<AnswerInput type="radio" name={groupName} value={answer.id} />
						<Answer $isChecked={isChecked}>
							<StyledRadioIcon isChecked={isChecked} />
							{answer.text}
						</Answer>
					</AnswerContainer>
				)
			})}
		</Answers>
	)
}

const Answers = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const AnswerContainer = styled.label`
	display: block;
	background-color: white;
	border: 0.5px solid #e8e8ed;
	border-radius: 8px;
	padding: 16px;
	cursor: pointer;
`

const AnswerInput = styled.input`
	display: none;
`

const Answer = styled.div<{ $isChecked: boolean }>`
	display: flex;
	align-items: center;
	gap: 16px;
	color: #151d48;
	font-size: 16px;
	letter-spacing: 0.3px;

	${props =>
		props.$isChecked &&
		css`
			font-weight: 600;
			letter-spacing: 0;
		`}
`

const StyledRadioIcon = styled(RadioIcon)`
	width: 24px;
	height: 24px;
	flex: 0 0 auto;
	color: ${props => props.theme.primaryColor};
`

export default SingleAnswerType
