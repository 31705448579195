import { useRef, type FC } from "react"
import { Trans } from "react-i18next"
import { Navigate, useParams } from "react-router"
import styled from "styled-components"

import { parseNumber } from "@forento/shared/utilities/number"

import { usePlatform } from "~/contexts/PlatformContext"
import { useUser } from "~/contexts/UserContext"
import Button from "~/themes/school/components/Button"
import Layout from "~/themes/school/components/Layout"
import PageHeader, { PageBreadcrumb } from "~/themes/school/components/PageHeader"
import PartialLoadingPage from "~/themes/school/components/PartialLoadingPage"
import { fonts } from "~/themes/school/styles"
import { useLocale, useTranslation } from "~/translations"
import { downloadElement } from "~/utilities/element"
import routes from "~/utilities/routes"
import { swr } from "~/utilities/trpc"

const CertificatePage: FC = () => {
	const certificateId = parseNumber(useParams().certificateId)
	const t = useTranslation()
	const locale = useLocale()
	const platform = usePlatform().platform
	const user = useUser().user!

	const downloadRef = useRef<HTMLDivElement>(null)

	const { data: certificate, error } = swr.course.certificate.get.useSWR(certificateId ?? -1)

	if (certificate === null) return <Navigate to={routes.certificate.index()} />

	return (
		<Layout>
			<PageHeader>
				<PageBreadcrumb
					title={certificate?.course.title ?? "..."}
					path={[{ title: t("certificate.title"), link: routes.certificate.index() }]}
				/>
				{certificate && (
					<Button
						variant="primary"
						onClick={async () => {
							if (!certificate || !downloadRef.current) return
							await downloadElement(downloadRef.current)
						}}
					>
						{t("general.download")}
					</Button>
				)}
			</PageHeader>
			{error ? (
				<p>{t("failedToLoad")}</p>
			) : !certificate ? (
				<PartialLoadingPage />
			) : (
				<Container ref={downloadRef}>
					<Content>
						<Title>{t("certificate.certificateOfCompletion")}</Title>
						<Details>
							<Trans
								i18nKey="certificate.content"
								values={{
									name: `${user.firstName} ${user.lastName}`,
									course: certificate.course.title,
									date: new Intl.DateTimeFormat(locale, { dateStyle: "long" }).format(
										certificate.createDate,
									),
								}}
								components={{ value: <Value />, line: <Text /> }}
							/>
						</Details>
						{platform.logo &&
							(platform.logo.isIcon ? (
								<Icon alt="Logo" src={platform.logo.filePath} />
							) : (
								<Logo alt="Logo" src={platform.logo.filePath} />
							))}
					</Content>
				</Container>
			)}
		</Layout>
	)
}

const Container = styled.div`
	max-width: 800px;
	background-color: white;
	box-shadow: 0px 4px 30px 0px rgba(223, 232, 255, 0.25);
	padding: 24px;
`

const Content = styled.div`
	border: 10px solid ${props => props.theme.primaryColor};
	padding: 64px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 48px;
`

const Title = styled.h1`
	font-size: 32px;
	text-transform: uppercase;
	text-align: center;
`

const Details = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 24px;
`

const Text = styled.div`
	font-size: 20px;
	text-align: center;
`

const Value = styled.div`
	font-family: ${fonts.quicksand};
	font-size: 28px;
	font-weight: 600;
	text-align: center;
`

const Icon = styled.img`
	width: 100px;
	height: 100px;
	object-fit: contain;
`

const Logo = styled.img`
	width: 500px;
	height: 50px;
	object-fit: contain;
`

export default CertificatePage
