import { type FC } from "react"

import useCookieOption, { cookieOptions } from "~/hooks/useCookieOption"
import useMatomo from "~/hooks/useMatomo"
import Button from "~/themes/original/components/Button"
import Modal, { ModalButtons, ModalText, ModalTitle } from "~/themes/original/components/Modal"

const CookieConsent: FC = () => {
	const [cookieOption, setCookieOption] = useCookieOption()

	useMatomo({ hasCookieConsent: cookieOption !== null && Boolean(cookieOption & cookieOptions.analytics) })

	return (
		<Modal isOpen={cookieOption === null}>
			<ModalTitle>We value your privacy</ModalTitle>
			<ModalText>
				We use cookies to authenticate you and collect privacy-friendly statistics. No cookies are loaded until
				you make a choice.
			</ModalText>
			<ModalButtons>
				<Button variant="secondary" onClick={() => setCookieOption(cookieOptions.functional)}>
					Use only functional
				</Button>
				<Button
					variant="primary"
					onClick={() => setCookieOption(cookieOptions.functional | cookieOptions.analytics)}
				>
					Accept
				</Button>
			</ModalButtons>
		</Modal>
	)
}

export default CookieConsent
