import { type FC } from "react"

import useCookieOption, { cookieOptions } from "~/hooks/useCookieOption"
import useMatomo from "~/hooks/useMatomo"
import Button from "~/themes/school/components/Button"
import Modal, { ModalButtons, ModalText, ModalTitle } from "~/themes/school/components/Modal"
import { useTranslation } from "~/translations"

const CookieConsent: FC = () => {
	const [cookieOption, setCookieOption] = useCookieOption()
	const t = useTranslation()

	useMatomo({ hasCookieConsent: cookieOption !== null && Boolean(cookieOption & cookieOptions.analytics) })

	return (
		<Modal isOpen={cookieOption === null}>
			<ModalTitle>{t("cookieConsent.title")}</ModalTitle>
			<ModalText>{t("cookieConsent.text")}</ModalText>
			<ModalButtons>
				<Button variant="secondary" onClick={() => setCookieOption(cookieOptions.functional)}>
					{t("cookieConsent.onlyFunctional")}
				</Button>
				<Button
					variant="primary"
					onClick={() => setCookieOption(cookieOptions.functional | cookieOptions.analytics)}
				>
					{t("cookieConsent.accept")}
				</Button>
			</ModalButtons>
		</Modal>
	)
}

export default CookieConsent
