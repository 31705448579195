import styled from "styled-components"
import Values from "values.js"

import { type CommunityPostAttachment } from "@forento/shared/models/community"

import Button from "~/themes/original/components/Button"
import { AttachmentIcon } from "~/themes/original/components/Icon"

const Attachment: React.FC<{ attachment: CommunityPostAttachment }> = ({ attachment }) => (
	<Container newTab onClick={attachment.filePath}>
		<Icon />
		{attachment.label}
	</Container>
)

const Container = styled(Button)`
	background-color: ${props => new Values(props.theme.primaryColor).tint(90).hexString()};
	border-radius: 8px;
	padding: 12px 24px;
	display: flex;
	align-items: center;
	word-break: break-word;
	gap: 4px;
`

const Icon = styled(AttachmentIcon)`
	width: 24px;
	height: 24px;
`

export default Attachment
