import { type FC, useState } from "react"
import { Navigate, useParams } from "react-router"
import styled from "styled-components"
import Values from "values.js"

import AspectRatio from "@forento/shared/components/AspectRatio"
import { toDateTimeString } from "@forento/shared/utilities/date"
import { parseNumber } from "@forento/shared/utilities/number"

import { PurchaseSuccessModalContextProvider } from "~/contexts/PurchaseSuccessModalContext"
import Button from "~/themes/original/components/Button"
import Layout from "~/themes/original/components/Layout"
import Modal, { ModalButtons, ModalTitle } from "~/themes/original/components/Modal"
import PartialLoadingPage from "~/themes/original/components/PartialLoadingPage"
import PurchaseItem from "~/themes/original/components/PurchaseItem"
import routes from "~/utilities/routes"
import { lightTextColor } from "~/utilities/styles"
import { swr } from "~/utilities/trpc"

import Thumbnail from "./Thumbnail"

const SessionPage: FC = () => {
	const sessionId = parseNumber(useParams().sessionId!)

	const [isModalOpen, setModalOpen] = useState(false)

	const { data: session, error } = swr.privateSession.getPersonalized.useSWR(sessionId ?? -1)

	if (session === null) return <Navigate to={routes.session.index()} />

	const redemotionInstructions =
		session?.price === null ? session.redemptionInstructions : (session?.orders[0]?.redemptionInstructions ?? null)
	const redemptionLink =
		session?.price === null ? session.redemptionLink : (session?.orders[0]?.redemptionLink ?? null)

	return (
		<Layout>
			<Modal isOpen={isModalOpen}>
				<ModalTitle>Redeem session</ModalTitle>
				<p>{redemotionInstructions}</p>
				<ModalButtons>
					{redemptionLink !== null ? (
						<>
							<Button variant="secondary" onClick={() => setModalOpen(false)}>
								Cancel
							</Button>
							<Button variant="primary" onClick={redemptionLink} newTab>
								Redeem
							</Button>
						</>
					) : (
						<Button variant="primary" onClick={() => setModalOpen(false)}>
							Close
						</Button>
					)}
				</ModalButtons>
			</Modal>
			{error ? (
				<p>Failed to load session.</p>
			) : session === undefined ? (
				<PartialLoadingPage />
			) : (
				<PurchaseSuccessModalContextProvider
					content={{
						type: "session",
						isUnlocked: session.price === null || session.orders.length > 0,
						payPalOrderId: session.orders[0]?.type === "paypal" ? session.orders[0].id : null,
					}}
				>
					<Content>
						<Details>
							<Title>{session.title}</Title>
							{session.longDescription && (
								<Description dangerouslySetInnerHTML={{ __html: session.longDescription.html }} />
							)}
							{session.price !== null ? (
								<PurchaseItem
									item={{ type: "session", id: session.id, price: session.price }}
									onPurchase={() => {}}
								/>
							) : session.redemptionInstructions !== null ? (
								<Button variant="primary" onClick={() => setModalOpen(true)}>
									Redeem
								</Button>
							) : session.redemptionLink !== null ? (
								<Button variant="primary" onClick={session.redemptionLink} newTab>
									Redeem
								</Button>
							) : null}
						</Details>
						<ThumbnailContainer>
							<AspectRatio width={{ value: 100, unit: "percent" }} aspectRatio={16 / 12}>
								<Thumbnail />
							</AspectRatio>
						</ThumbnailContainer>
					</Content>
					<Orders>
						{session.orders.map(order => (
							<Order key={order.createDate.toISOString()}>
								<OrderDate>{toDateTimeString(order.createDate)}</OrderDate>
								{order.redemptionInstructions !== null ? (
									<Button variant="primary" onClick={() => setModalOpen(true)}>
										Redeem
									</Button>
								) : order.redemptionLink !== null ? (
									<Button variant="primary" onClick={order.redemptionLink} newTab>
										Redeem
									</Button>
								) : null}
							</Order>
						))}
					</Orders>
				</PurchaseSuccessModalContextProvider>
			)}
		</Layout>
	)
}

const Content = styled.div`
	background-color: ${props => new Values(props.theme.primaryColor).tint(90).hexString()};
	border-radius: 16px;
	display: flex;
	padding: 42px;
	gap: ${42 * 2}px;
	margin-bottom: 42px;
`

const Details = styled.div`
	flex: 1 0 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 22px;
`

const Title = styled.h1`
	font-weight: 500;
	font-size: 24px;
	color: #151d48;
`

const Description = styled.div`
	font-size: 16px;
	color: ${lightTextColor};
`

const ThumbnailContainer = styled.div`
	flex: 1 0 0;
	border-radius: 8px;
	overflow: hidden;

	@media (max-width: 999px) {
		display: none;
	}
`

const Orders = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const Order = styled.div`
	display: flex;
	background-color: white;
	border-radius: 8px;
	padding: 16px 24px;
	justify-content: space-between;
	align-items: center;
	gap: 16px;
`

const OrderDate = styled.div`
	font-size: 18px;
	font-weight: 600;
`

export default SessionPage
