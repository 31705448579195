import { useState } from "react"
import styled from "styled-components"

import { hasAccessToPostCommunityPost } from "@forento/shared/utilities/community"

import { usePlatform } from "~/contexts/PlatformContext"
import { useUser } from "~/contexts/UserContext"
import withCommunityAuthCheck from "~/hocs/withCommunityAuthCheck"
import Button from "~/themes/school/components/Button"
import Layout from "~/themes/school/components/Layout"
import PageHeader, { PageTitle } from "~/themes/school/components/PageHeader"
import PartialLoadingPage from "~/themes/school/components/PartialLoadingPage"
import Post from "~/themes/school/components/Post"
import CreatePostModal from "~/themes/school/pages/community/CommunityPage/CreatePostModal"
import { useTranslation } from "~/translations"
import { swr } from "~/utilities/trpc"

const CommunityPage: React.FC = () => {
	const user = useUser()
	const platform = usePlatform().platform!
	const t = useTranslation()

	const [isCreatePostModalOpen, setCreatePostModalOpen] = useState(false)

	const { data: posts, error, mutate } = swr.community.listPosts.useSWR()

	return (
		<Layout>
			<CreatePostModal
				isOpen={isCreatePostModalOpen}
				onClose={() => setCreatePostModalOpen(false)}
				onCreate={mutate}
			/>
			<PageHeader>
				<PageTitle>{t("community.title")}</PageTitle>
				{hasAccessToPostCommunityPost(user.user!, platform.community!) && (
					<Button variant="primary" onClick={() => setCreatePostModalOpen(true)}>
						{t("community.post.create")}
					</Button>
				)}
			</PageHeader>
			{error ? (
				<p>{t("failedToLoad")}</p>
			) : posts === undefined ? (
				<PartialLoadingPage />
			) : (
				<Posts>
					{posts
						.toSorted((a, b) => b.createDate.getTime() - a.createDate.getTime())
						.map(x => (
							<Post key={x.id} post={x} onChange={mutate} />
						))}
				</Posts>
			)}
		</Layout>
	)
}

const Posts = styled.div`
	display: flex;
	flex-direction: column;
	gap: 42px;
`

export default withCommunityAuthCheck(CommunityPage)
