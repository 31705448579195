import { useTheme } from "styled-components"

const Thumbnail: React.FC = () => {
	const theme = useTheme()

	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 596.91213 505.57464">
			<path
				d="M575.42701,413.69586H21.48512c-11.84669,0-21.48512-9.63791-21.48512-21.48512v-66.67087H596.91213v66.67087c0,11.84721-9.63791,21.48512-21.48512,21.48512Z"
				fill="#e6e6e6"
			/>
			<rect x="228.88706" y="402.54358" width="141.262" height="100.90143" fill="#e6e6e6" />
			<rect x="220.3901" y="490.69956" width="158.25592" height="12.74544" fill="#e6e6e6" />
			<path
				d="M596.38107,353.68606H0V14.74418C0,6.6144,6.6144,0,14.74418,0H581.63689c8.12978,0,14.74418,6.6144,14.74418,14.74418V353.68606Z"
				fill="#3f3d56"
			/>
			<rect x="13.8077" y="15.40073" width="568.23436" height="319.69821" fill="#fff" />
			<path
				id="uuid-25324785-d0f2-4f1c-ad19-f4bb5410ff47-290"
				d="M123.85295,205.94936c4.34915-3.59367,9.72872-4.26258,12.0153-1.49438,2.28658,2.7682,.6142,7.92447-3.73698,11.51883-1.71842,1.45964-3.76141,2.48653-5.95805,2.99474l-18.6198,14.99379-6.8499-8.8877,19.08307-13.83763c.91372-2.06202,2.30808-3.87516,4.06637-5.28765Z"
				fill="#ffb6b6"
			/>
			<path
				d="M16.2326,239.77936c-.00944-.66307-.00322-6.44713,4.27885-10.10294,4.91893-4.1995,11.85979-2.49972,14.29424-1.90355,5.62362,1.37718,6.08483,3.78959,11.36601,6.2683,9.8805,4.63743-2.4994,2.26379,3.3798,.77675,2.03505-.51473,31.31216-2.54515,38.59979-7.03056,14.17354-8.72353,23.83086-14.01349,23.83086-14.01349l6.31321,12.62642s-9.1289,12.69357-21.97706,22.09716c-13.17929,9.64592-19.76894,14.46893-29.78342,16.33239-.8624,.16048-22.07229,3.78187-39.15515-8.98085-3.89654-2.91112-11.03588-8.24498-11.14714-16.06965Z"
				fill={theme.primaryColor}
			/>
			<circle cx="35.81649" cy="185.67486" r="21.99265" fill="#ffb6b6" />
			<polygon
				points="208.05472 333.43009 185.4053 310.24577 173.7135 322.44168 190.58814 344.67132 207.32613 346.58567 208.05472 333.43009"
				fill="#ffb6b6"
			/>
			<path
				d="M78.72568,289.95422c1.32477,.11007,5.53464,.95349,9.55849-1.26037,2.74614-1.51087,4.15455-3.75738,4.63022-4.4718,5.90139-8.86325,28.55224-22.68449,48.88084-23.53504,4.60357-.1926,7.63375,.34082,10.38795,1.77648,3.90537,2.03574,5.46449,4.95183,10.4203,11.78292,2.71086,3.73663,5.7104,7.25691,8.45393,10.96962,16.06194,21.7362,22.15102,23.44259,21.52868,30.41109-.6905,7.73164-9.02898,15.06697-14.5711,14.22018-2.26965-.34678-3.14862-1.92498-6.18099-5.1199-8.89113-9.36767-12.56963-7.63699-18.89978-14.81212-7.39056-8.37709-6.42307-15.32303-10.27892-15.59132-3.57571-.24879-5.09524,5.67668-12.72906,13.54826-3.49306,3.60187-5.98049,5.25419-13.55853,10.51141-29.51063,20.4729-29.98163,21.91164-35.9372,23.54443-2.60568,.71437-21.09954,5.78468-27.68759-2.28408-10.30952-12.6267,14.04589-50.68156,25.98276-49.68978Z"
				fill="#2f2e41"
			/>
			<path
				d="M201.13025,338.66919c1.66577-.74799,3.61352-1.62258,4.24018-3.63382,.42403-1.3609,.17985-3.03892-.69661-4.19845-1.01127-1.33788-2.50808-1.51421-2.44753-1.75092,.09221-.36043,3.6317-.21954,7.51717,1.15395,.73321,.25919,4.0549,1.47292,8.28021,4.7716,3.38533,2.64291,2.92976,3.17274,5.0526,4.69665,6.51637,4.67782,12.62816,.99108,16.15421,5.37796,1.36082,1.69307,1.94121,4.09684,1.36853,6.04048-1.15007,3.90324-6.74285,5.24648-9.7995,5.93997-7.3615,1.67019-12.93347,.30673-18.75163-.67366-19.78659-3.33419-30.90389-.29752-32.39188-4.8815-.28162-.86758,.1759-.7937,1.70153-7.16035,1.50577-6.2838,1.45637-8.0095,2.7388-8.5127,1.9304-.75745,3.22657,2.68803,8.08945,3.86718,4.30931,1.04492,7.86513-.55173,8.94448-1.03639Z"
				fill="#2f2e41"
			/>
			<polygon
				points="167.78776 409.27485 162.06533 377.37247 145.54248 380.8986 147.02265 408.7683 159.79754 419.75133 167.78776 409.27485"
				fill="#ffb6b6"
			/>
			<path
				d="M85.1876,300.67759c1.03437,.83499,4.04422,3.89687,8.61693,4.32456,3.1207,.29189,5.5476-.77612,6.34237-1.10016,9.86016-4.02006,36.36378-2.73717,53.66232,7.97432,3.91739,2.42571,6.12517,4.56865,7.59796,7.30318,2.08836,3.87748,1.74096,7.16592,2.00574,15.60118,.14485,4.61413,.65006,9.21134,.83536,13.82403,1.08478,27.00503,5.165,31.83623,.73697,37.25285-4.91296,6.00981-15.93171,7.39707-20.04204,3.58429-1.6833-1.56144-1.52438-3.36089-2.23945-7.70732-2.09668-12.74399-6.1123-13.37756-7.3211-22.86924-1.41128-11.08171,3.28968-16.28586,.2498-18.67306-2.81902-2.21375-7.40373,1.83601-18.14054,4.06268-4.91292,1.01889-7.89899,.98932-17.12157,1.08406-35.91491,.369-37.11255,1.29501-42.95736-.69821-2.55722-.87208-20.70715-7.06164-21.62754-17.43758-1.44026-16.23716,40.08188-34.04923,49.40214-26.52558Z"
				fill="#2f2e41"
			/>
			<path
				d="M159.11615,409.72161c1.79837,.31647,3.90115,.68652,5.54907-.62579,1.11506-.88797,1.85529-2.41356,1.78117-3.86518-.08551-1.6749-1.22503-2.66132-1.042-2.82318,.2787-.24646,3.12834,1.85768,5.57211,5.17601,.46116,.62619,2.52813,3.49574,4.17204,8.59791,1.3171,4.08786,.64262,4.27045,1.54344,6.72347,2.76521,7.52985,9.89266,7.91126,10.3469,13.52119,.17529,2.16508-.69427,4.48-2.25957,5.76669-3.14344,2.58393-8.52547,.55484-11.44413-.58775-7.02916-2.75176-10.87405-7.00882-15.13778-11.08716-14.50016-13.86979-25.4044-17.59987-24.06156-22.22846,.25415-.87602,.59124-.55798,5.42874-4.96936,4.77454-4.35399,5.70271-5.80966,7.04642-5.50591,2.02264,.45724,1.1604,4.03605,4.52206,7.74243,2.97899,3.28446,6.81783,3.96004,7.98309,4.16509Z"
				fill="#2f2e41"
			/>
			<path
				d="M38.81486,189.87923c.85618-3.06269-.2192-4.1788,.87688-5.55356,.00002-.00004,.44108-.55322,4.38439-2.04605h0c-1.16344-6.75042-.29231-7.89186-.29229-7.8919,1.00399-1.31553,3.0152-1.63355,4.38439-1.46146,2.89579,.36397,3.40837,2.98166,5.26127,2.92293,2.55961-.08114,5.62956-5.20482,4.67668-9.64566-.77729-3.62247-4.12881-6.31266-4.96897-5.84585-.83301,.46284-3.83172-1.5781-4.96897-1.16917-.77665,.27927-4.43236-.09227-5.26127,.29229-.90523,.41999-3.49763,3.03482-3.74548,2.70535-1.72237-2.28946-1.6857-3.0888-2.68496-3.58223-1.77067-.87434-3.69571,.74172-7.01502,2.33834-6.82959,3.28508-8.8294,1.32549-11.984,4.0921-1.59355,1.39756-2.44808,4.06851-4.0921,9.35336-2.29915,7.39081-3.44871,11.08621-2.33834,14.61463,1.13238,3.59835,2.79995,2.81517,5.26127,7.8919,2.73983,5.65125,1.79552,8.93605,4.38439,10.23024,2.00086,1.00026,5.21638,.36365,6.43044-1.46146,2.07861-3.12477-3.29393-7.35177-1.46146-12.27629,1.12484-3.02287,4.59478-5.31493,6.72273-4.67668,2.40606,.72167,2.58325,5.03573,4.0921,4.96897,1.29207-.05716,2.18914-3.2661,2.33834-3.7998Z"
				fill="#2f2e41"
			/>
			<path
				d="M22.15418,210.9243c4.50049-5.04615,22.83197,.17676,24.55258,8.18419,.7077,3.29357-1.9049,4.68425-1.16917,8.47649,1.0761,5.54667,7.16909,5.16857,11.6917,10.81483,6.42075,8.016-.19392,15.85188,7.01502,29.22926,1.54044,2.85856,2.83881,5.26787,5.55356,7.59961,6.22959,5.35068,12.02717,3.51565,15.19922,8.47649,2.37209,3.70978,1.39747,8.28224,1.16917,9.35336-.93616,4.39206-3.47342,5.46222-5.26127,9.06107-2.97456,5.98761,.39336,10.38356,.58459,17.53756,.23927,8.95093-4.51735,21.35848-14.03005,25.42946-10.68707,4.57357-23.57658-3.00132-29.81385-10.52253-3.72182-4.488-4.8022-8.62788-6.43044-13.73775-7.27695-22.83729-11.38807-20.77183-16.95297-38.87492-6.3617-20.69512-7.61233-44.94052,0-52.61267,1.00898-1.01692,3.78571-3.36736,5.55356-7.59961,2.37887-5.69507,.32374-8.55598,2.33834-10.81483Z"
				fill={theme.primaryColor}
			/>
			<path
				id="uuid-91308677-eda7-42a2-9f2e-a07e84038198-291"
				d="M88.65331,328.39105c3.48927,4.43334,4.03014,9.82729,1.20837,12.04743-2.82177,2.22013-7.93683,.42567-11.42675-4.00971-1.41838-1.75263-2.39643-3.81945-2.85228-6.02755l-14.54698-18.97093,7.51668-5.73344,14.91146,18.50331c2.03972,.96247,3.81921,2.39954,5.18951,4.1909Z"
				fill="#ffb6b6"
			/>
			<path
				d="M35.37742,230.15543c.64719-.14454,6.31083-1.31867,10.76347,2.12736,5.11484,3.95855,4.86707,11.10022,4.78019,13.60509-.20073,5.78631-2.46827,6.73006-3.81723,12.40591-2.52378,10.61887,2.28253,20.4873,4.93792,25.93939,.91914,1.88721,3.87205,7.66186,9.7501,13.88094,11.43206,12.09534,21.6324,18.45987,21.6324,18.45987l-10.95803,9.74043s-17.45493-5.3202-29.2823-15.97929c-12.13217-10.9338-30.78935-49.61903-34.65703-59.04258-.33308-.81151,4.38498,12.38209,13.39349-6.94555,2.0548-4.40857,5.81969-12.48606,13.45703-14.19157Z"
				fill={theme.primaryColor}
			/>
			<path
				d="M315.83751,221.08136l-13.08365-6.1978c5.36634,7.4891,9.99038,19.18539,12.46595,28.49257,4.19148-8.66968,10.94828-19.27767,17.63647-25.61422l-13.82798,3.55737c8.52074-41.7599,40.56942-71.73938,77.2696-71.73938l.51953-1.50838c-38.33429,0-72.27996,29.56391-80.97992,73.00984Z"
				fill="#3f3d56"
			/>
			<path
				d="M376.05662,284.71011h-82.66786c-3.50634,0-6.35907-2.85273-6.35907-6.35907s2.85273-6.35907,6.35907-6.35907h82.66786c3.50634,0,6.35907,2.85273,6.35907,6.35907s-2.85273,6.35907-6.35907,6.35907Z"
				fill={theme.primaryColor}
			/>
			<path
				d="M486.05662,284.71011h-82.66786c-3.50634,0-6.35907-2.85273-6.35907-6.35907s2.85273-6.35907,6.35907-6.35907h82.66786c3.50634,0,6.35907,2.85273,6.35907,6.35907s-2.85273,6.35907-6.35907,6.35907Z"
				fill="#e6e6e6"
			/>
			<path
				d="M437.11815,111.71741h-80.3571c-2.02587,0-3.67427-1.64841-3.67427-3.67428s1.64841-3.67351,3.67427-3.67351h80.3571c2.02587,0,3.67351,1.64765,3.67351,3.67351s-1.64764,3.67427-3.67351,3.67427Z"
				fill="#3f3d56"
			/>
			<path
				d="M480.23862,150.91545h-166.59803c-2.02587,0-3.67427-1.64842-3.67427-3.67428s1.64841-3.67351,3.67427-3.67351h166.59803c2.02587,0,3.67351,1.64765,3.67351,3.67352,0,2.02587-1.64764,3.67427-3.67351,3.67427Z"
				fill="#3f3d56"
			/>
			<path
				d="M36.12104,504.38463c0,.66003,.53003,1.19,1.19006,1.19H559.60108c.65997,0,1.19-.52997,1.19-1.19,0-.65997-.53003-1.19-1.19-1.19H37.3111c-.66003,0-1.19006,.53003-1.19006,1.19Z"
				fill="#3f3d56"
			/>
			<path
				d="M164.36892,222.29651c-.14159,0-.28281-.00519-.42513-.01408-8.36889-.53744-37.00488-4.58938-54.10034-34.14792-1.40884-2.43368-1.13122-5.52786,.69015-7.69839l31.66012-37.72172c1.29468-1.54264,3.2947-2.43146,5.3203-2.407,1.39994,.03336,4.71466,.38696,5.35848,2.94221,3.80323,15.09655,19.8631,32.84694,39.17726,35.19982,1.95777,.23796,3.52932,1.98446,4.2039,4.67166,.52632,2.09194,.04003,4.24838-1.33471,5.9163l-25.36242,30.79799c-1.28838,1.56414-3.17906,2.46111-5.18761,2.46111Zm-16.97648-80.47167c-1.54709,0-3.05193,.69311-4.03563,1.86511l-31.65975,37.72172c-1.41292,1.68349-1.62937,4.07937-.53967,5.96226,16.71851,28.90694,44.70512,32.8677,52.88424,33.39328,1.67015,.11194,3.27395-.59971,4.3429-1.89698l25.36279-30.79799c1.06488-1.29208,1.44146-2.96149,1.03411-4.58123-.39808-1.58564-1.37326-3.34696-2.91553-3.53525-19.95242-2.42998-36.53972-20.75265-40.46564-36.33549-.31802-1.26169-2.23242-1.75465-3.91776-1.79468-.03002-.00074-.06005-.00074-.09007-.00074Z"
				fill="#3f3d56"
			/>
			<path
				d="M129.02956,189.37601l22.82552-23.27322c1.5603-1.59089-.88415-4.0393-2.44639-2.44641l-22.82552,23.27322c-1.5603,1.59089,.88415,4.0393,2.44639,2.44641h0Z"
				fill="#e6e6e6"
			/>
			<path
				d="M142.80616,195.93278c6.29954-5.64633,11.77383-12.18399,16.25639-19.35766,1.18327-1.89361-1.80885-3.6323-2.9874-1.7462-4.33369,6.93544-9.62593,13.19938-15.71542,18.65747-1.66149,1.48921,.79191,3.92934,2.44639,2.44639h.00004Z"
				fill="#e6e6e6"
			/>
			<path
				d="M155.22261,203.78396c5.73555-6.35342,10.61777-13.4443,14.46148-21.09311,.99713-1.98424-1.9864-3.73813-2.9874-1.7462-3.7137,7.39012-8.37772,14.25303-13.92048,20.39292-1.48868,1.64906,.9506,4.10334,2.44639,2.44639h0Z"
				fill="#e6e6e6"
			/>
			<ellipse cx="145.44339" cy="139.35715" rx="23.01268" ry="22.52779" fill={theme.primaryColor} />
			<path
				d="M157.41747,150.04337c-3.53924-8.70173-7.07853-17.40341-10.61777-26.10509-.48882-1.20182-2.02009-1.2445-2.49716,0-3.49698,9.12301-5.78219,18.64254-6.8808,28.34862-.18709,1.65324,2.40401,1.63991,2.58962,0,.42992-3.79819,1.06816-7.56798,1.88753-11.29757,2.91638,.153,5.85674-.17418,8.67112-.9533,1.45013,3.56526,2.90026,7.13048,4.35034,10.69574,.61964,1.52347,3.12576,.8572,2.49711-.6884Zm-14.93138-11.61416c.87227-3.57038,1.91798-7.09834,3.13134-10.56966l3.969,9.75819c-2.32472,.61016-4.70218,.8816-7.10034,.81147Z"
				fill="#fff"
			/>
		</svg>
	)
}

export default Thumbnail
