export const toCountFormat = (value: number): string => {
	const format = (value: number) => Intl.NumberFormat(undefined, { maximumFractionDigits: 1 }).format(value)

	if (value < 1_000) return `${format(value)}`
	if (value < 1_000_000) return `${format(value / 1_000)} K`
	if (value < 1_000_000_000) return `${format(value / 1_000_000)} M`
	return `${format(value / 1_000_000_000)} B`
}

export const toSizeFormat = (value: number): string => {
	const format = (value: number) => Intl.NumberFormat(undefined, { maximumFractionDigits: 0 }).format(value)

	if (value < 1_000) return `${format(value)} B`
	if (value < 1_000_000) return `${format(value / 1_000)} KB`
	if (value < 1_000_000_000) return `${format(value / 1_000_000)} MB`
	if (value < 1_000_000_000_000) return `${format(value / 1_000_000_000)} GB`
	return `${format(value / 1_000_000_000_000)} TB`
}

export const parseNumber = (value: string | null | undefined): number | null => {
	const trimmedValue = value?.trim() ?? ""
	if (trimmedValue === "") return null
	const number = Number(trimmedValue)
	return !isNaN(number) ? number : null
}
