import { useState } from "react"
import { useLocation } from "react-router"
import styled, { css } from "styled-components"

import CommunityProfileAvatar from "@forento/shared/components/CommunityProfileAvatar"
import { type CommunityPost } from "@forento/shared/models/community"
import { hasAccessToReactToCommunityPost, isAuthorizedToEditPost } from "@forento/shared/utilities/community"
import { toRelativeDateString } from "@forento/shared/utilities/date"
import { toCountFormat } from "@forento/shared/utilities/number"

import { useAlert } from "~/contexts/AlertContext"
import { usePlatform } from "~/contexts/PlatformContext"
import { useUser } from "~/contexts/UserContext"
import Button from "~/themes/school/components/Button"
import { CommentIcon, DeleteIcon, LikeIcon } from "~/themes/school/components/Icon"
import MoreActions from "~/themes/school/components/MoreActions"
import ProfilePopup from "~/themes/school/components/ProfilePopup"
import { fonts, lightTextColor } from "~/themes/school/styles"
import { useLocale } from "~/translations"
import routes from "~/utilities/routes"
import trpc from "~/utilities/trpc"

import Attachment from "./Attachment"
import PollOption from "./PollOption"

interface Props {
	post: CommunityPost
	onChange(): void
}

const Post: React.FC<Props> = ({ post, onChange }) => {
	const user = useUser().user!
	const platform = usePlatform().platform!
	const location = useLocation()
	const alert = useAlert()
	const locale = useLocale()

	const hasAccessToReact = platform.community !== null && hasAccessToReactToCommunityPost(user, platform.community)

	const [authorElement, setAuthorElement] = useState<HTMLElement | null>(null)

	const handleVote = async (pollOptionId: number | null) => {
		await trpc.community.setPostVote.mutate({
			postId: post.id,
			pollOptionId:
				pollOptionId !== null && !post.pollOptions.find(x => x.id === pollOptionId)?.isSelected
					? pollOptionId
					: null,
		})
		onChange()
	}

	const handleLike = async (reaction: boolean) => {
		if (reaction) {
			await trpc.community.createPostReaction.mutate(post.id)
		} else {
			await trpc.community.deletePostReaction.mutate(post.id)
		}
		onChange()
	}

	const handleDelete = async () => {
		const dialog = await alert.confirm(
			"Delete post",
			"Are you sure you want to delete this post? This action cannot be undone.",
		)
		if (!dialog.result) return
		await trpc.community.deletePost.mutate(post.id)
		dialog.close()
		onChange()
	}

	const link = location.pathname !== routes.community.post(post.id) ? routes.community.post(post.id) : undefined

	return (
		<Container>
			<ProfilePopup targetElement={authorElement} profile={post.profile} />
			<Header>
				<Avatar profile={post.profile} ref={setAuthorElement} />
				<Author onClick={routes.community.profile(post.profile.id)}>
					{post.profile.firstName} {post.profile.lastName}
				</Author>
				<Date onClick={link}>{toRelativeDateString(post.createDate, locale)}</Date>
				{isAuthorizedToEditPost(user, post) && (
					<MoreActions
						actions={[{ label: "Delete", icon: DeleteIcon, isDanger: true, onClick: handleDelete }]}
					/>
				)}
			</Header>
			{post.category !== null && <Category>{post.category.label}</Category>}
			<Button onClick={link}>
				<Title>{post.title}</Title>
			</Button>
			<Content dangerouslySetInnerHTML={{ __html: post.htmlContent }} />
			{post.pollOptions.length > 0 && (
				<PollOptions>
					{post.pollOptions.map((option, index) => (
						<PollOption
							key={index}
							option={option}
							onChange={isSelected => (isSelected ? handleVote(option.id) : handleVote(null))}
						/>
					))}
				</PollOptions>
			)}
			{post.attachments.length > 0 && (
				<Attachments>
					{post.attachments.map(attachment => (
						<Attachment key={attachment.id} attachment={attachment} />
					))}
				</Attachments>
			)}
			<Footer>
				{hasAccessToReact ? (
					<FooterGroup>
						<LikeButton onClick={() => handleLike(!post.hasUserReacted)}>
							<StyledLikeIcon isFilled={post.hasUserReacted} />
						</LikeButton>
						{toCountFormat(post.reactions)}
					</FooterGroup>
				) : (
					<FooterGroup>
						<StyledLikeIcon />
						{toCountFormat(post.reactions)}
					</FooterGroup>
				)}
				<FooterGroup as={Button} onClick={link}>
					<StyledCommentIcon />
					{toCountFormat(post.comments)}
				</FooterGroup>
			</Footer>
		</Container>
	)
}

const Container = styled.article`
	max-width: 1400px;
	background-color: #ffffff;
	box-shadow: 0px 4px 30px 0px rgba(223, 232, 255, 0.25);
	padding: 24px;
`

const Header = styled.div`
	display: grid;
	grid-template-areas: "avatar name actions" "avatar date actions";
	grid-template-columns: min-content 1fr min-content;
	gap: 0 16px;
	margin-bottom: 16px;
`

const Avatar = styled(CommunityProfileAvatar)`
	grid-area: avatar;
`

const Author = styled(Button)`
	grid-area: name;
	font-family: ${fonts.quicksand};
	font-size: 20px;
	font-weight: bold;
`

const Date = styled(Button)`
	grid-area: date;
	font-size: 14px;
	color: ${lightTextColor};
`

const Category = styled.p`
	font-size: 14px;
	color: ${lightTextColor};
	margin: 0 ${32 - 24}px 4px;
`

const Title = styled.h1`
	font-family: ${fonts.quicksand};
	font-weight: bold;
	font-size: 16px;
	margin: 0 ${32 - 24}px 8px;
`

const Content = styled.div`
	font-size: 16px;
	margin: 0 ${32 - 24}px 16px;
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const PollOptions = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-bottom: 16px;
`

const Attachments = styled.div`
	font-size: 16px;
	color: ${lightTextColor};
	margin-bottom: 32px;
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
`

const Footer = styled.div`
	display: flex;
	align-items: center;
	gap: 42px;
	margin: 0 ${32 - 24}px;
`

const FooterGroup = styled.div`
	display: flex;
	align-items: center;
	font-size: 16px;
	gap: 12px;
`

const LikeButton = styled(Button)`
	display: flex;
	color: ${lightTextColor};
`

const StyledLikeIcon = styled(LikeIcon)`
	width: 24px;
	height: 24px;

	${props =>
		props.isFilled &&
		css`
			color: ${props => props.theme.primaryColor};
		`}
`

const StyledCommentIcon = styled(CommentIcon)`
	width: 24px;
	height: 24px;
`

export default Post
