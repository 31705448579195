import { useState } from "react"
import { useNavigate } from "react-router"
import { useSearchParams } from "react-router-dom"

import { useAlert } from "~/contexts/AlertContext"
import { usePlatform } from "~/contexts/PlatformContext"
import { useUser } from "~/contexts/UserContext"
import { SubmitButton } from "~/themes/original/components/Button"
import InputField from "~/themes/original/components/InputField"
import routes from "~/utilities/routes"
import trpc from "~/utilities/trpc"

import Layout, { Title, Description, NavigationLink, NavigationLinkButton, NavigationLinks, Intro } from "./Layout"

const SigninPage: React.FC = () => {
	const alert = useAlert()
	const platform = usePlatform()
	const user = useUser()
	const [searchParams] = useSearchParams()
	const navigate = useNavigate()
	const params = new URLSearchParams(window.location.search)

	const nextUrl = params.get("next") ?? undefined
	const passwordResetEmail = params.get("password-reset-email") ?? undefined
	const prefilledEmail = params.get("email") ?? undefined

	const [email, setEmail] = useState(passwordResetEmail ?? prefilledEmail ?? "")
	const [password, setPassword] = useState("")
	const [isSubmitting, setSubmitting] = useState(false)

	const handleSignin = async () => {
		if (email.trim().length === 0 || password.length === 0) return

		setSubmitting(true)

		try {
			const result = await trpc.user.loginPassword.mutate({
				email: email.trim(),
				password,
				platformId: platform.platform!.id,
			})
			if (result.status !== "success") {
				await alert.show("Login failed", "Invalid login credentials.")
				return
			}
			await user.reload()

			navigate(nextUrl ?? "/")
		} catch (error) {
			console.error(error)
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<Layout onSubmit={handleSignin}>
			<Intro>
				<Title>Sign in</Title>

				{passwordResetEmail !== undefined && (
					<Description>Your password has been successfully updated! Enter it below to sign in.</Description>
				)}
			</Intro>

			<InputField
				inputType="email"
				label="Email address"
				value={email}
				onChange={setEmail}
				disabled={prefilledEmail !== undefined || passwordResetEmail !== undefined}
			/>
			<InputField inputType="password" label="Password" value={password} onChange={setPassword} />
			<SubmitButton variant="primary" isLoading={isSubmitting}>
				Sign in
			</SubmitButton>

			<NavigationLinks>
				<NavigationLink>
					Forgot your password?
					<NavigationLinkButton onClick={routes.user.resetPassword()}>Reset password</NavigationLinkButton>
				</NavigationLink>
				<NavigationLink>
					Don't have an account yet?
					<NavigationLinkButton onClick={`${routes.user.signup()}?${searchParams}`}>
						Sign up
					</NavigationLinkButton>
				</NavigationLink>
			</NavigationLinks>
		</Layout>
	)
}

export default SigninPage
