import { createGlobalStyle } from "styled-components"
import Values from "values.js"

import { backgroundColor, lightTextColor } from "~/themes/school/styles"

const PhoneNumberInputStyles = createGlobalStyle`
	.react-international-phone-input-container {
		display: flex;
		background-color: white;
		border: 1px solid ${backgroundColor};
		border-radius: 10px;

		.react-international-phone-input {
			flex: 1;
			overflow: visible;
			height: 36px;
			padding: 0 8px;
			background-color: transparent;
			border: none;
			border-left: 1px solid ${backgroundColor};
			margin: 0;
			color: ${lightTextColor};
			font-size: 14px;

			&:focus {
				outline: none;
			}
		}
	}

	.react-international-phone-country-selector {
		position: relative;

		&-button {
			display: flex;
			height: 36px;
			align-items: center;
			justify-content: center;
			padding: 0;
			border: none;
			margin: 0;
			appearance: button;
			-webkit-appearance: button;
			background-color: transparent;
			cursor: pointer;
			text-transform: none;
			user-select: none;

			&--hide-dropdown {
				cursor: auto;

				&:hover {
					background-color: transparent;
				}
			}

			&__button-content {
				display: flex;
				align-items: center;
				justify-content: center;
			}

			&__flag-emoji {
				margin: 0 8px 0 24px;
			}

			&__dropdown-arrow {
				border-top: 4px solid ${lightTextColor};
				border-right: 4px solid transparent;
				border-left: 4px solid transparent;
				margin-right: 4px;
				transition: all 0.1s ease-out;

				&--active {
					transform: rotateX(180deg);
				}
			}
		}

		&-dropdown {
			outline: none;
			position: absolute;
			z-index: 1;
			top: 44px;
			left: 0;
			display: flex;
			width: 300px;
			max-height: 200px;
			flex-direction: column;
			padding: 4px 0;
			margin: 0;
			background-color: white;
			box-shadow: 2px 2px 16px rgba(0, 0, 0, 0.25);
			color: ${lightTextColor};
			list-style: none;
			overflow-y: scroll;

			&__list-item {
				display: flex;
				min-height: 28px;
				align-items: center;
				padding: 2px 8px;

				&-flag-emoji {
					margin-right: 8px;
				}

				&-country-name {
					overflow: hidden;
					margin-right: 8px;
					font-size: 14px;
					text-overflow: ellipsis;
					white-space: nowrap;
				}

				&-dial-code {
					color: #999;
					font-size: 14px;
				}

				&:hover {
					cursor: pointer;
				}

				&:hover, &-selected, &-focused {
					background-color: ${new Values("white").shade(5).hexString()};
				}
			}
		}
	}

	.react-international-phone-flag-emoji {
		width: 24px;
		height: 24px;
	}
`

export default PhoneNumberInputStyles
