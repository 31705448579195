const dimensions = {
	mobile: 320,
	tablet: 768,
	desktop: 1440,
}

export const devices = {
	mobile: `(min-width: ${dimensions.mobile}px)`,
	tablet: `(min-width: ${dimensions.tablet}px)`,
	desktop: `(min-width: ${dimensions.desktop}px)`,
}

export const mediaQueries = {
	mobile: `@media ${devices.mobile}`,
	tablet: `@media ${devices.tablet}`,
	desktop: `@media ${devices.desktop}`,
}

export default dimensions
