import { useState } from "react"
import { useLocation, useNavigate } from "react-router"

import { parseNumber } from "@forento/shared/utilities/number"

import { SubmitButton } from "~/themes/original/components/Button"
import InputField from "~/themes/original/components/InputField"
import LoadingIndicator from "~/themes/original/components/LoadingIndicator"
import routes from "~/utilities/routes"
import trpc, { swr } from "~/utilities/trpc"

import Layout, { Title, Description } from "./Layout"

const NewPasswordPage: React.FC = () => {
	const location = useLocation()
	const navigate = useNavigate()

	const params = new URLSearchParams(location.search)

	const [password, setPassword] = useState("")
	const [passwordConfirmation, setPasswordConfirmation] = useState("")
	const [isSubmitting, setSubmitting] = useState(false)

	const userId = parseNumber(params.get("user") ?? "") ?? -1
	const token = params.get("token") ?? ""

	const tokenCheck = swr.user.checkResetPasswordToken.useSWR({ userId, token })

	const submitNewPassword = async () => {
		if (password.length === 0 || password !== passwordConfirmation || tokenCheck.data?.status !== "success") return

		setSubmitting(true)
		try {
			await trpc.user.setPasswordFromToken.mutate({ userId, token, newPassword: password })
			navigate(`${routes.user.signin()}?password-reset-email=${encodeURIComponent(tokenCheck.data.email)}`)
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<Layout onSubmit={submitNewPassword}>
			<Title>Reset password</Title>

			{!tokenCheck.data ? (
				<LoadingIndicator />
			) : tokenCheck.data.status === "invalid" ? (
				<Description>Invalid password reset link. It may be expired or already used.</Description>
			) : (
				<>
					<Description>
						Set a new password for <strong>{tokenCheck.data.email}</strong>
					</Description>

					<InputField
						inputType="password"
						label="New password"
						value={password}
						onChange={setPassword}
						autoComplete="new-password"
					/>

					<InputField
						inputType="password"
						label="Confirm new password"
						value={passwordConfirmation}
						onChange={setPasswordConfirmation}
						autoComplete="new-password"
					/>

					<SubmitButton variant="primary" isLoading={isSubmitting}>
						Set new password
					</SubmitButton>
				</>
			)}
		</Layout>
	)
}

export default NewPasswordPage
