import { PlayerSdk } from "@api.video/player-sdk"
import { type FC, useId, useLayoutEffect } from "react"

const ApiVideo: FC<{ apiVideoId: string }> = ({ apiVideoId }) => {
	const playerId = useId()

	useLayoutEffect(() => {
		const playerSdk = new PlayerSdk(`#${CSS.escape(playerId)}`, { id: apiVideoId, hideTitle: true })

		playerSdk.hideControls()
		playerSdk.showControls([
			"play",
			"seekBackward",
			"seekForward",
			"playbackRate",
			"volume",
			"fullscreen",
			"subtitles",
			"pictureInPicture",
			"progressBar",
			"chromecast",
		])

		return () => {
			playerSdk.destroy()
		}
	}, [apiVideoId, playerId])

	return <div id={playerId} />
}

export default ApiVideo
