import { type FC } from "react"
import styled from "styled-components"

import { type PublicEvent } from "@forento/shared/models/event"
import { toDateTimeString } from "@forento/shared/utilities/date"

import Button from "~/themes/original/components/Button"
import { toRelativeDateString } from "~/themes/original/utilities/date"
import routes from "~/utilities/routes"

const Event: FC<{ event: PublicEvent }> = ({ event }) => (
	<Container key={event.id} onClick={routes.event.detail(event.id)}>
		<div>
			<time>{toDateTimeString(event.startDate)}</time>
			<span> ({toRelativeDateString(event.startDate)})</span>
		</div>
		<Title>{event.title}</Title>
		{event.shortDescription && <p>{event.shortDescription}</p>}
	</Container>
)

const Container = styled(Button)`
	background-color: #ffffff;
	border-radius: 8px;
	padding: 22px;
`

const Title = styled.h1`
	font-weight: 600;
	font-size: 24px;
	color: #151d48;
`

export default Event
