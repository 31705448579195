import { type FC, useState } from "react"
import { useDropzone } from "react-dropzone"
import styled, { css } from "styled-components"

import { getDataUrlByFile } from "@forento/shared/utilities/file"
import { parseNumber } from "@forento/shared/utilities/number"

import Button, { SubmitButton } from "~/themes/school/components/Button"
import Dropdown from "~/themes/school/components/Dropdown"
import { DeleteIcon, UploadFileIcon } from "~/themes/school/components/Icon"
import InputField, { useRichTextArea } from "~/themes/school/components/InputField"
import Modal, { ModalButtons, ModalTitle } from "~/themes/school/components/Modal"
import { useTranslation } from "~/translations"
import { dangerColor } from "~/utilities/styles"
import trpc, { swr } from "~/utilities/trpc"

type Props = { isOpen: boolean; onClose(): void; onCreate(): Promise<unknown> }

const CreatePostModal: FC<Props> = ({ isOpen, onClose, onCreate }) => {
	const t = useTranslation()

	const categories = swr.community.listPostCategories.useSWR()

	const [title, setTitle] = useState("")
	const content = useRichTextArea({ label: t("general.text") })
	const [categoryId, setCategoryId] = useState<string | null>(null)
	const [pollOptions, setPollOptions] = useState<string[]>([])
	const [attachments, setAttachments] = useState<{ label: string; file: File }[]>([])
	const [isSubmitting, setSubmitting] = useState(false)

	const dropzone = useDropzone({
		onDrop: async files => {
			for (const file of files) {
				const dialog = prompt(t("community.post.addAttachment"), file.name)
				if (dialog === null) continue
				setAttachments(current => [...current, { label: dialog, file }])
			}
		},
	})

	const handleSubmit = async () => {
		const exportedContent = content.exportEditorState()
		if (title.trim().length === 0 || exportedContent === null) return

		setSubmitting(true)

		try {
			await trpc.community.createPost.mutate({
				title: title.trim(),
				content: exportedContent,
				categoryId: parseNumber(categoryId),
				pollOptions,
				attachments: await Promise.all(
					attachments.map(async attachment => ({
						label: attachment.label,
						dataUrl: await getDataUrlByFile(attachment.file),
					})),
				),
			})
			await onCreate()

			setTitle("")
			content.set(null)
			setCategoryId(null)
			setPollOptions([])
			setAttachments([])
			onClose()
		} catch (error) {
			console.error(error)
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<Modal isOpen={isOpen} onSubmit={handleSubmit}>
			<ModalTitle>{t("community.post.create")}</ModalTitle>
			<InputField label={t("general.title")} value={title} onChange={setTitle} />
			{content.element}
			<Dropdown
				items={[
					{ id: "none", title: t("community.post.noCategory") },
					...(categories.data?.map(category => ({
						id: category.id.toString(),
						title: category.label,
					})) ?? []),
					{ id: "create", title: t("community.post.createCategory") },
				]}
				selectedItemId={categoryId ?? "none"}
				onChange={id => setCategoryId(id === "none" ? null : id)}
			/>
			<PollOptions>
				{pollOptions.map((option, index) => (
					<PollOption key={index}>
						<PollOptionInput
							value={option}
							onChange={value =>
								setPollOptions(current => [
									...current.slice(0, index),
									value,
									...current.slice(index + 1),
								])
							}
						/>
						<PollOptionDeleteButton
							onClick={() =>
								setPollOptions(current => [...current.slice(0, index), ...current.slice(index + 1)])
							}
						>
							<DeleteIcon />
						</PollOptionDeleteButton>
					</PollOption>
				))}
				<Button variant="secondary" onClick={() => setPollOptions(current => [...current, ""])}>
					{t("community.post.addPollOption")}
				</Button>
			</PollOptions>
			<Attachments>
				{attachments.map((attachment, index) => (
					<Attachment key={index}>
						<DeleteAttachment
							onClick={() =>
								setAttachments([...attachments.slice(0, index), ...attachments.slice(index + 1)])
							}
						>
							<DeleteIcon />
						</DeleteAttachment>
						{attachment.label}
					</Attachment>
				))}
				<AddAttachment {...dropzone.getRootProps()}>
					<input {...dropzone.getInputProps()} />
					<AddAttachmentIcon />
				</AddAttachment>
			</Attachments>
			<ModalButtons>
				<Button variant="secondary" onClick={onClose} isDisabled={isSubmitting}>
					{t("cancel")}
				</Button>
				<SubmitButton variant="primary" isLoading={isSubmitting}>
					{t("community.post.create")}
				</SubmitButton>
			</ModalButtons>
		</Modal>
	)
}

const PollOptions = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const PollOption = styled.div`
	display: flex;
	gap: 16px;
`

const PollOptionInput = styled(InputField)`
	flex: 1;
`

const PollOptionDeleteButton = styled(Button)`
	width: 52px;
	height: 52px;
	background-color: ${dangerColor};
	color: white;
	padding: 8px;
	border-radius: 4px;
`

const Attachments = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
`

const attachmentStyles = css`
	border: 1px dashed ${props => props.theme.primaryColor};
	border-radius: 8px;
	padding: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
`

const Attachment = styled.div`
	${attachmentStyles}
	word-break: break-all;
	position: relative;
`

const DeleteAttachment = styled(Button)`
	position: absolute;
	top: 4px;
	right: 4px;
	width: 24px;
	height: 24px;
	color: ${dangerColor};
`

const AddAttachment = styled.div`
	${attachmentStyles};
	cursor: pointer;
`

const AddAttachmentIcon = styled(UploadFileIcon)`
	width: 24px;
	height: 24px;
	color: ${props => props.theme.primaryColor};
`

export default CreatePostModal
