import { type ReactNode, useLayoutEffect, useState } from "react"
import styled, { css } from "styled-components"

interface Props {
	isCollapsed: boolean
	className?: string
	children: ReactNode
}

const Collapse: React.FC<Props> = ({ isCollapsed, className, children }) => {
	const [contentElement, setContentElement] = useState<HTMLDivElement | null>(null)
	const [expandedHeight, setExpandedHeight] = useState<number>()

	useLayoutEffect(() => {
		if (contentElement === null) return

		const resizeObserver = new ResizeObserver(() => {
			setExpandedHeight(contentElement.offsetHeight)
		})
		resizeObserver.observe(contentElement)

		return () => {
			resizeObserver.disconnect()
		}
	}, [contentElement])

	return (
		<Container $height={isCollapsed ? 0 : expandedHeight} className={className}>
			<div ref={setContentElement}>{children}</div>
		</Container>
	)
}

const Container = styled.div<{ $height?: number }>`
	transition: height 0.15s;
	overflow-y: hidden;

	${props =>
		props.$height !== undefined &&
		css`
			height: ${props.$height}px;
		`}
`

export default Collapse
