import styled, { css } from "styled-components"

import { useUser } from "~/contexts/UserContext"
import Button from "~/themes/school/components/Button"
import { BillingIcon } from "~/themes/school/components/Icon"
import { SettingsIcon, SignInIcon, SignOutIcon } from "~/themes/school/components/Icon"
import { useTranslation } from "~/translations"
import routes from "~/utilities/routes"

import Logo from "../Logo"
import useNavigationItems from "./useNavigationItems"

const Sidebar: React.FC = () => {
	const user = useUser()
	const navItems = useNavigationItems()
	const t = useTranslation()

	return (
		<Container>
			<Logo />
			<Spacer $factor={1} />
			<NavItems>
				{navItems.map(({ icon, label, onClick }, index) => (
					<NavItem key={index} onClick={onClick}>
						<Icon as={icon} />
						{label}
					</NavItem>
				))}
			</NavItems>
			<Spacer $factor={3} />
			<NavItems>
				<NavItem onClick={routes.user.settings()}>
					<Icon as={SettingsIcon} />
					{t("settings")}
				</NavItem>
				<NavItem onClick={routes.billing.index()}>
					<Icon as={BillingIcon} />
					{t("billing.title")}
				</NavItem>
				{user.user ? (
					<NavItem onClick={user.signOut}>
						<Icon as={SignOutIcon} />
						{t("signOut")}
					</NavItem>
				) : (
					<NavItem onClick={routes.user.signin()}>
						<Icon as={SignInIcon} />
						{t("user.signIn.title")}
					</NavItem>
				)}
			</NavItems>
		</Container>
	)
}

const Container = styled.header`
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	width: 250px;
	background-color: #ffffff;
	box-shadow: 0px 4px 30px 0px rgba(223, 232, 255, 0.25);
	padding: 42px 32px;
	display: flex;
	flex-direction: column;
	align-items: center;

	@media (max-width: 999px) {
		display: none;
	}
`

const Spacer = styled.div<{ $factor: number }>`
	flex: ${props => props.$factor} 0 0;
`

const NavItems = styled.nav`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: ${24 - 4 * 2}px;
`

const NavItem = styled(Button)`
	width: 100%;
	font-size: 14px;
	color: black;
	display: flex;
	align-items: center;
	gap: 16px;
	padding: 4px 16px;

	${props =>
		typeof props.onClick === "string" &&
		props.onClick === window.location.pathname &&
		css`
			font-weight: 700;
			color: ${props => props.theme.primaryColor};
		`}
`

const Icon = styled.div`
	width: 16px;
	height: 16px;
`

export default Sidebar
