import { type FC, Fragment } from "react"
import styled from "styled-components"

import { useTermsOfService } from "~/api/termsOfService"
import Button from "~/themes/original/components/Button"
import Layout from "~/themes/original/components/Layout"
import PartialLoadingPage from "~/themes/original/components/PartialLoadingPage"

const TermsOfServicePage: FC = () => {
	const terms = useTermsOfService()

	return (
		<Layout>
			<Title>Terms of Service</Title>
			{terms.status === "success" ? (
				<Content terms={terms.terms} />
			) : terms.status === "loading" ? (
				<PartialLoadingPage />
			) : (
				<p>Failed to load terms of service.</p>
			)}
		</Layout>
	)
}

type Terms = Extract<ReturnType<typeof useTermsOfService>, { status: "success" }>["terms"]
const Content: FC<{ terms: Terms }> = ({ terms }) => {
	return (
		<>
			{terms.static.introItems.map((item, index) => (
				<Text key={index}>{item}</Text>
			))}
			{terms.static.sections.map((section, index) => (
				<Fragment key={index}>
					<Section>
						<NumberColumn>
							<SectionTitle>{index + 1}.</SectionTitle>
						</NumberColumn>
						<ContentColumn>
							<SectionTitle>{section.title}</SectionTitle>
						</ContentColumn>
					</Section>
					{section.items.map((item, itemIndex) => (
						<Section key={itemIndex}>
							<NumberColumn>
								{!section.skipItemNumbers && (
									<Item>
										{index + 1}.{itemIndex + 1}.
									</Item>
								)}
							</NumberColumn>
							<ContentColumn>
								<Item>{item}</Item>
							</ContentColumn>
						</Section>
					))}
				</Fragment>
			))}
			{terms.custom && (
				<>
					<Section>
						<NumberColumn>
							<SectionTitle>{terms.static.sections.length + 1}.</SectionTitle>
						</NumberColumn>
						<ContentColumn>
							<SectionTitle>EXTRA TERMS</SectionTitle>
						</ContentColumn>
					</Section>
					<Section>
						<NumberColumn></NumberColumn>
						<ContentColumn>
							<Item>
								<Button
									variant="primary"
									onClick={() => {
										window
											.open("")!
											.document.write(
												"<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
													encodeURI(terms.custom!) +
													"'></iframe>",
											)
									}}
								>
									Link to document
								</Button>
							</Item>
						</ContentColumn>
					</Section>
				</>
			)}
		</>
	)
}

const Title = styled.h1`
	font-weight: 500;
	font-size: 36px;
	color: #151d48;
	margin-bottom: 24px;

	@media (max-width: 999px) {
		margin-left: 16px;
		margin-right: 16px;
	}
`

const Text = styled.p`
	font-size: 14px;
	margin-bottom: 16px;

	@media (max-width: 999px) {
		margin-left: 16px;
		margin-right: 16px;
	}
`

const Section = styled.div`
	display: flex;

	@media (max-width: 999px) {
		margin-left: 16px;
		margin-right: 16px;
	}
`

const NumberColumn = styled.div`
	flex: 0 0 56px;
	font-size: 14px;
`

const ContentColumn = styled.div`
	flex: 1 0 0;
`

const SectionTitle = styled.h2`
	font-weight: 600;
	font-size: 20px;
	color: #151d48;
	margin-bottom: 8px;
`

const Item = styled.p`
	font-size: 14px;
	margin-bottom: 16px;
`

export default TermsOfServicePage
