import { type FC, useState } from "react"
import styled from "styled-components"

import CommunityProfileAvatar from "@forento/shared/components/CommunityProfileAvatar"
import { type PublicCommunityProfile } from "@forento/shared/models/community"
import { toRelativeDateString } from "@forento/shared/utilities/date"

import { useUser } from "~/contexts/UserContext"
import { DeleteIcon } from "~/themes/school/components/Icon"
import MoreActions from "~/themes/school/components/MoreActions"
import ProfilePopup from "~/themes/school/components/ProfilePopup"
import { fonts, lightTextColor } from "~/themes/school/styles"
import { useLocale } from "~/translations"

interface Props {
	comment: {
		sticker?: string
		text: string
		createDate: Date
	} & (
		| { user: { id: number; firstName: string; lastName: string }; profile?: never }
		| { profile: PublicCommunityProfile; user?: never }
	)
	onDelete(): void
}

const Comment: FC<Props> = ({ comment, onDelete }) => {
	const user = useUser().user
	const locale = useLocale()

	const [authorElement, setAuthorElement] = useState<HTMLElement | null>(null)

	const universalCommentUser = comment.profile ?? comment.user

	return (
		<Container>
			{comment.profile !== undefined && (
				<>
					<ProfilePopup targetElement={authorElement} profile={comment.profile} />
					<CommunityProfileAvatar ref={setAuthorElement} profile={comment.profile} />
				</>
			)}
			<HeaderGroup>
				<Author>
					{universalCommentUser.firstName} {universalCommentUser.lastName}
				</Author>
				{comment.sticker !== undefined && (
					<>
						<Separator>•</Separator>
						<Sticker>{comment.sticker}</Sticker>
					</>
				)}
			</HeaderGroup>
			{(user?.isCreator ||
				(comment.user && comment.user.id === user?.id) ||
				(comment.profile !== undefined && comment.profile.id === user?.communityProfileId)) && (
				<MoreActions actions={[{ label: "Delete", icon: DeleteIcon, isDanger: true, onClick: onDelete }]} />
			)}
			<Text>{comment.text}</Text>
			<Date>{toRelativeDateString(comment.createDate, locale)}</Date>
		</Container>
	)
}

const Container = styled.div`
	background-color: white;
	border-radius: 10px;
	display: grid;
	grid-template-areas: "avatar name actions" "avatar text actions" "avatar date actions";
	grid-template-columns: min-content 1fr min-content;
	gap: 0 16px;

	@media (min-width: 1000px) {
		padding: 8px 32px;
		border: 1px solid black;
	}
`

const HeaderGroup = styled.div`
	grid-area: name;
	display: flex;
	flex-direction: column;
	gap: 2px;

	@media (min-width: 1000px) {
		flex-direction: row;
		align-items: center;
		gap: 8px;
	}
`

const Author = styled.span`
	font-family: ${fonts.quicksand};
	font-weight: bold;
`

const Separator = styled.div`
	@media (max-width: 999px) {
		display: none;
	}
`

const Sticker = styled.span`
	color: ${lightTextColor};
	font-size: 12px;
`

const Text = styled.div`
	grid-area: text;
	font-size: 14px;
	margin-bottom: 4px;
`

const Date = styled.p`
	grid-area: date;
	color: ${lightTextColor};
	font-size: 14px;
`

export default Comment
