import { useState } from "react"
import { PhoneInput } from "react-international-phone"
import { useNavigate } from "react-router"
import { useSearchParams } from "react-router-dom"

import { useAlert } from "~/contexts/AlertContext"
import { usePlatform } from "~/contexts/PlatformContext"
import { useUser } from "~/contexts/UserContext"
import Button, { SubmitButton } from "~/themes/original/components/Button"
import InputField, { Label } from "~/themes/original/components/InputField"
import routes from "~/utilities/routes"
import trpc from "~/utilities/trpc"

import Layout, {
	Title,
	InputFieldRow,
	InputFields,
	NavigationLinks,
	NavigationLink,
	NavigationLinkButton,
} from "../Layout"
import PhoneNumberInputStyles from "./PhoneNumberInputStyles"
import { TermsOfService, TermsOfServiceButton } from "./styles"

const RegularSignup: React.FC = () => {
	const platform = usePlatform()
	const user = useUser()
	const [searchParams] = useSearchParams()
	const navigate = useNavigate()
	const alert = useAlert()

	const [firstName, setFirstName] = useState("")
	const [lastName, setLastName] = useState("")
	const [email, setEmail] = useState("")
	const [phoneNumber, setPhoneNumber] = useState("")
	const [password, setPassword] = useState("")
	const [passwordConfirmation, setPasswordConfirmation] = useState("")
	const [isSubmitting, setSubmitting] = useState(false)

	const nextUrl = useSearchParams()[0].get("next") ?? undefined

	const handleSignup = async () => {
		if (
			firstName.trim().length === 0 ||
			lastName.trim().length === 0 ||
			email.trim().length === 0 ||
			password.length === 0 ||
			(platform.platform!.isPhoneNumberEnabled && phoneNumber.length === 0)
		)
			return

		if (password !== passwordConfirmation) return

		setSubmitting(true)

		try {
			const response = await trpc.user.registerStudent.mutate({
				firstName: firstName.trim(),
				lastName: lastName.trim(),
				email: email.trim(),
				phoneNumber: platform.platform!.isPhoneNumberEnabled ? phoneNumber : null,
				password,
				platformId: platform.platform!.id,
			})

			if (response.status === "email-taken") {
				await alert.show("E-mail taken", "That e-mail address is already associated with an account.")
				return
			}

			await user.reload()

			navigate(nextUrl ?? "/")
		} catch {
			await alert.show("Error", "Unknown error. Please try again later.")
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<Layout onSubmit={handleSignup}>
			<Title>Sign up</Title>

			<InputFields>
				<InputFieldRow>
					<InputField
						inputType="text"
						label="First name"
						value={firstName}
						onChange={setFirstName}
						autoComplete="given-name"
					/>

					<InputField
						inputType="text"
						label="Last name"
						value={lastName}
						onChange={setLastName}
						autoComplete="family-name"
					/>
				</InputFieldRow>

				<InputField inputType="email" label="Email address" value={email} onChange={setEmail} />

				{platform.platform!.isPhoneNumberEnabled && (
					<div>
						<PhoneNumberInputStyles />
						<Label>Phone number</Label>
						<PhoneInput value={phoneNumber} onChange={setPhoneNumber} />
					</div>
				)}

				<InputFieldRow>
					<InputField inputType="password" label="Password" value={password} onChange={setPassword} />

					<InputField
						inputType="password"
						label="Confirm password"
						value={passwordConfirmation}
						onChange={setPasswordConfirmation}
					/>
				</InputFieldRow>
			</InputFields>

			<TermsOfService>
				By signing up you agree to our{" "}
				<TermsOfServiceButton onClick={routes.policy.termsOfService()} newTab>
					Terms of Service
				</TermsOfServiceButton>{" "}
				and{" "}
				<TermsOfServiceButton onClick={routes.policy.privacy()} newTab>
					Privacy Policy
				</TermsOfServiceButton>
				.
			</TermsOfService>

			{platform.platform!.isSignupsOpen ? (
				<SubmitButton variant="primary" isLoading={isSubmitting}>
					Sign up
				</SubmitButton>
			) : (
				<Button variant="primary" isDisabled>
					Sign-ups are currently closed
				</Button>
			)}

			<NavigationLinks>
				<NavigationLink>
					Already have an account?
					<NavigationLinkButton onClick={`${routes.user.signin()}?${searchParams}`}>
						Sign in
					</NavigationLinkButton>
				</NavigationLink>
			</NavigationLinks>
		</Layout>
	)
}

export default RegularSignup
