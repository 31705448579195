export function getCookieByName(name: string): string | null {
	const cookies = document.cookie.split(";").map(cookie => {
		const parts = cookie.split("=")
		return { name: parts[0]!.trim(), value: parts[1]! }
	})
	return cookies.find(cookie => cookie.name === name)?.value ?? null
}

export function setCookie(args: {
	name: string
	value: string | number
	expireDate?: Date
	maxAge?: number
	domain?: string
	path?: string
	sameSite?: boolean
	secure?: boolean
}) {
	const parts = [`${args.name}=${args.value}`]

	if (args.expireDate) parts.push(`expires=${args.expireDate.toUTCString()}`)
	if (args.maxAge) parts.push(`max-age=${args.maxAge}`)
	if (args.domain) parts.push(`domain=${args.domain}`)
	if (args.path) parts.push(`path=${args.path}`)
	if (args.sameSite) parts.push("samesite")
	if (args.secure) parts.push("secure")

	document.cookie = parts.join(";")
}
