import { type ComponentType, type FC, type ReactNode, createContext, useContext, useMemo } from "react"

import { usePlatform } from "~/contexts/PlatformContext"
import OriginalButton from "~/themes/original/components/Button"
import OriginalInputField from "~/themes/original/components/InputField"
import * as originalModal from "~/themes/original/components/Modal"
import SchoolButton from "~/themes/school/components/Button"
import SchoolInputField from "~/themes/school/components/InputField"
import * as schoolModal from "~/themes/school/components/Modal"

type Components = {
	Button: (props: {
		variant?: "primary" | "secondary" | "danger"
		onClick: (() => void) | string
		isLoading?: boolean
		isDisabled?: boolean
		children: ReactNode
	}) => ReactNode
	InputField: ComponentType<{ label: string; value: string; onChange(value: string): void }>
	Modal: {
		Frame: ComponentType<{ isOpen: boolean; children: ReactNode }>
		Title: ComponentType<{ children?: ReactNode }>
		Text: ComponentType<{ children?: ReactNode; dangerouslySetInnerHTML?: { __html: string | TrustedHTML } }>
		Buttons: ComponentType<{ children?: ReactNode }>
	}
}

const Context = createContext<Components>(undefined!)

export const useThemeComponents = () => useContext(Context)

type Props = { children?: ReactNode }
export const ThemeComponentProvider: FC<Props> = ({ children }) => {
	const theme = usePlatform().platform?.theme ?? 2

	const components: Components = useMemo(() => {
		switch (theme) {
			case 1:
				return {
					Button: props => <OriginalButton {...props} />,
					InputField: props => <OriginalInputField {...props} />,
					Modal: {
						Frame: props => <originalModal.default {...props} />,
						Title: props => <originalModal.ModalTitle {...props} />,
						Text: props => <originalModal.ModalText {...props} />,
						Buttons: props => <originalModal.ModalButtons {...props} />,
					},
				}
			case 2:
				return {
					Button: props => <SchoolButton {...props} />,
					InputField: props => <SchoolInputField {...props} />,
					Modal: {
						Frame: props => <schoolModal.default {...props} />,
						Title: props => <schoolModal.ModalTitle {...props} />,
						Text: props => <schoolModal.ModalText {...props} />,
						Buttons: props => <schoolModal.ModalButtons {...props} />,
					},
				}
		}
	}, [theme])

	return <Context.Provider value={components}>{children}</Context.Provider>
}
