import { useCallback, useLayoutEffect, useState, type Dispatch } from "react"

import { getCookieByName, setCookie } from "@forento/shared/utilities/cookie"
import { parseNumber } from "@forento/shared/utilities/number"

const cookieName = "cookie-option"

export default function useCookieOption(): [number | null, Dispatch<number | null>] {
	const getCookieOption = useCallback(() => {
		const value = getCookieByName(cookieName)
		if (value === null) return null
		return parseNumber(value)
	}, [])

	const [cookieOption, setCookieOption] = useState<number | null>(getCookieOption())

	useLayoutEffect(() => {
		if (cookieOption !== null) {
			setCookie({
				name: cookieName,
				value: cookieOption,
				maxAge: 365 * 24 * 60 * 60,
				domain: window.location.hostname,
				path: "/",
			})
		}
	}, [cookieOption])

	return [cookieOption, setCookieOption]
}

export const cookieOptions = { functional: 1 << 0, analytics: 1 << 1 }
