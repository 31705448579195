import dayjs from "dayjs"

const removeTimeFromDate = (date: Date): Date => new Date(dayjs(date).format("YYYY-MM-DD"))

export const getFirstDayOfWeek = (date: Date = new Date()): Date => {
	const day = date.getDay()
	const diff = date.getDate() - day + (day == 0 ? -6 : 1)
	return removeTimeFromDate(new Date(date.setDate(diff)))
}

export function toDateString(date: Date): string {
	return dayjs(date).format("YYYY-MM-DD")
}

export function toDateTimeString(date: Date): string {
	return dayjs(date).format("YYYY-MM-DD HH:mm")
}

export const toRelativeDateString = (date: Date, locale: string): string => {
	const divisions = [
		{ amount: 60, name: "seconds" },
		{ amount: 60, name: "minutes" },
		{ amount: 24, name: "hours" },
		{ amount: 7, name: "days" },
		{ amount: 4.34524, name: "weeks" },
		{ amount: 12, name: "months" },
		{ amount: Number.POSITIVE_INFINITY, name: "years" },
	] satisfies { amount: number; name: Intl.RelativeTimeFormatUnit }[]

	const formatter = new Intl.RelativeTimeFormat(locale)
	let duration = (date.getTime() - Date.now()) / 1000
	for (const division of divisions) {
		if (Math.abs(duration) < division.amount) {
			return formatter.format(Math.floor(duration), division.name)
		}
		duration /= division.amount
	}
	return ""
}

export function toTimeIntervalString(seconds: number): string {
	const hours = Math.floor(seconds / 3600)
	const minutes = Math.floor((seconds - hours * 3600) / 60)
	const secondsLeft = Math.floor(seconds - hours * 3600 - minutes * 60)

	const parts: string[] = []
	if (hours > 0) parts.push(`${hours}h`)
	if (minutes > 0) parts.push(`${minutes}m`)
	if (secondsLeft > 0) parts.push(`${secondsLeft}s`)

	return parts.join(" ")
}
