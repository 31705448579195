import { type FC, useLayoutEffect, useState } from "react"
import styled, { css } from "styled-components"
import Values from "values.js"

import CommunityProfileAvatar from "@forento/shared/components/CommunityProfileAvatar"
import Form from "@forento/shared/components/Form"
import { type CommunityMessage, type PublicCommunityProfile } from "@forento/shared/models/community"
import { toDateTimeString, toRelativeDateString } from "@forento/shared/utilities/date"

import { useUser } from "~/contexts/UserContext"
import Button, { SubmitButton } from "~/themes/school/components/Button"
import { BackIcon, SendIcon } from "~/themes/school/components/Icon"
import InputField from "~/themes/school/components/InputField"
import { backgroundColor, fonts, lightTextColor } from "~/themes/school/styles"
import { useLocale, useTranslation } from "~/translations"
import { isColorDark } from "~/utilities/color"
import routes from "~/utilities/routes"
import trpc from "~/utilities/trpc"

import { fullPageCutoff } from "./constants"

type Props = {
	conversation: {
		otherProfile: PublicCommunityProfile
		messages: CommunityMessage[]
	}
	reload(): Promise<void>
}

const DetailedConversation: FC<Props> = ({ conversation, reload }) => {
	const user = useUser()
	const t = useTranslation()
	const locale = useLocale()

	const [input, setInput] = useState("")
	const [isSubmitting, setSubmitting] = useState(false)
	const [messagesElement, setMessagesElement] = useState<HTMLDivElement | null>(null)

	const handleSubmit = async () => {
		const trimmedInput = input.trim()
		if (trimmedInput.length === 0) return

		setSubmitting(true)

		try {
			await trpc.community.sendMessage.mutate({
				otherProfileId: conversation.otherProfile.id,
				text: trimmedInput,
			})
			setInput("")
			await reload()
		} finally {
			setSubmitting(false)
		}
	}

	useLayoutEffect(() => {
		if (messagesElement !== null) {
			messagesElement.scrollTop = messagesElement.scrollHeight
		}
	}, [messagesElement, conversation.messages.length])

	return (
		<Container>
			<BackButton onClick={routes.community.conversations()}>
				<BackButtonIcon />
				{t("community.conversation.backToAll")}
			</BackButton>
			<Content>
				<Header>
					<CommunityProfileAvatar profile={conversation.otherProfile} size={52} />
					<Name>
						{conversation.otherProfile.firstName} {conversation.otherProfile.lastName}
					</Name>
					<CloseButton onClick={routes.community.conversations()}>{t("close")}</CloseButton>
				</Header>
				<Messages ref={setMessagesElement}>
					{conversation.messages.map(message => {
						const isAuthor = message.senderProfileId === user.user!.communityProfileId

						return (
							<MessageContainer key={message.id} $isAuthor={isAuthor}>
								<Message $isAuthor={isAuthor}>{message.text}</Message>
								<MessageDate title={toDateTimeString(message.sendDate)}>
									{toRelativeDateString(message.sendDate, locale)}
								</MessageDate>
							</MessageContainer>
						)
					})}
				</Messages>
				<Footer onSubmit={handleSubmit}>
					<StyledInputField
						placeholder={t("community.conversation.writeMessage")}
						value={input}
						onChange={setInput}
					/>
					<SendButton isLoading={isSubmitting}>
						<SendButtonIcon />
					</SendButton>
					<MobileSendButton isLoading={isSubmitting}>{t("community.conversation.send")}</MobileSendButton>
				</Footer>
			</Content>
		</Container>
	)
}

const Container = styled.div`
	height: 100%;
	flex: 2 0 0;
	display: flex;
	flex-direction: column;
	gap: 24px;
`

const Content = styled.div`
	flex: 1;
	background-color: white;
	padding: 24px 0;
	display: flex;
	flex-direction: column;
	gap: 22px;
`

const BackButton = styled(Button).attrs({ variant: "secondary" })`
	@media (min-width: ${fullPageCutoff}px) {
		display: none;
	}
`

const BackButtonIcon = styled(BackIcon)`
	width: 24px;
	height: 24px;
`

const Header = styled.div`
	display: flex;
	align-items: center;
	gap: 22px;
	padding-bottom: 22px;
	margin: 0 42px;
	border-bottom: 1px solid ${backgroundColor};
`

const Name = styled.p`
	flex: 1;
	font-family: ${fonts.quicksand};
	font-size: 20px;
	font-weight: bold;
`

const CloseButton = styled(Button).attrs({ variant: "secondary" })`
	@media (max-width: ${fullPageCutoff - 1}px) {
		display: none;
	}
`

const Messages = styled.div`
	flex: 1 0 0;
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 0 42px;
	overflow-y: auto;
`

const MessageContainer = styled.div<{ $isAuthor: boolean }>`
	width: 100%;
	max-width: 450px;
	font-size: 16px;
	display: flex;
	flex-direction: column;
	gap: 4px;

	${props =>
		props.$isAuthor
			? css`
					align-self: flex-end;
					align-items: flex-end;
					color: ${props => (isColorDark(props.theme.primaryColor) ? "white" : "black")};
				`
			: css`
					align-self: flex-start;
					align-items: flex-start;
					color: ${isColorDark(new Values(props.theme.primaryColor).tint(90).hexString())
						? "white"
						: "black"};
				`}
`

const Message = styled.p<{ $isAuthor: boolean }>`
	padding: 8px 12px;
	position: relative;

	${props =>
		props.$isAuthor
			? css`
					background-color: ${props.theme.primaryColor};
					right: -10px;
					border-width: 0 10px 10px 0;
					border-color: ${props.theme.primaryColor} transparent;
					border-radius: 10px 10px 0 10px;
				`
			: css`
					background-color: ${new Values(props.theme.primaryColor).tint(90).hexString()};
					left: -10px;
					border-width: 10px 10px 0 0;
					border-color: transparent ${new Values(props.theme.primaryColor).tint(90).hexString()};
					border-radius: 10px 10px 10px 0;
				`}
`

const MessageDate = styled.p`
	font-size: 12px;
	color: ${lightTextColor};
`

const Footer = styled(Form)`
	flex: 0 0 auto;
	border-top: 1px solid ${backgroundColor};
	padding: 22px 24px 0;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	gap: 22px;

	@media (min-width: 500px) {
		flex-direction: row;
		align-items: stretch;
	}
`

const StyledInputField = styled(InputField)`
	@media (max-width: 499px) {
		flex-grow: 0;
		width: 100%;
	}

	@media (min-width: 500px) {
		flex-grow: 1;
		height: 58px;
	}
`

const SendButton = styled(SubmitButton).attrs({ variant: "primary", replaceOnLoading: true })`
	flex: 0 0 auto;
	width: 58px;
	height: 58px;
	padding: 0;

	&:hover,
	&:active {
		padding: 0;
	}

	@media (max-width: 499px) {
		display: none;
	}
`

const MobileSendButton = styled(SubmitButton).attrs({ variant: "primary" })`
	width: 100%;

	@media (min-width: 500px) {
		display: none;
	}
`

const SendButtonIcon = styled(SendIcon)`
	width: 24px;
	height: 24px;
`

export default DetailedConversation
