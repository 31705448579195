import InvitedSignup from "./InvitedSignup"
import RegularSignup from "./RegularSignup"

const SignupPage: React.FC = () => {
	const inviteToken = new URLSearchParams(window.location.search).get("invite")

	return inviteToken !== null ? <InvitedSignup inviteToken={inviteToken} /> : <RegularSignup />
}

export default SignupPage
