import { forwardRef, type ReactNode } from "react"
import styled from "styled-components"

interface CommunityProfileAvatarProps {
	profile: {
		avatarFilePath: string
		firstName: string
		lastName: string
		gamification?: {
			currentLevel: { tier: number } | null
		} | null
	}
	size?: number
	backgroundColor?: string
	className?: string
	children?: ReactNode
}

const CommunityProfileAvatar = forwardRef<HTMLDivElement, CommunityProfileAvatarProps>(
	({ profile, size = 42, backgroundColor = "#f8f6f3", className, children }, ref) => (
		<Container $size={size} $backgroundColor={backgroundColor} className={className} ref={ref}>
			{profile.gamification?.currentLevel != null && size >= 82 && (
				<GamificationLevel>{profile.gamification.currentLevel.tier}</GamificationLevel>
			)}
			<Image alt="Profile avatar" src={profile.avatarFilePath} />
			{children}
		</Container>
	),
)

const Container = styled.div<{ $size: number; $backgroundColor: string }>`
	width: ${props => props.$size}px;
	height: ${props => props.$size}px;
	flex: 0 0 auto;
	background-color: ${props => props.$backgroundColor};
	border-radius: 50%;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
`

const Image = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
`

const GamificationLevel = styled.div`
	position: absolute;
	bottom: 4px;
	padding: 4px 8px;
	min-width: 24px;
	text-align: center;
	border-radius: 12px;
	line-height: 1;
	left: 50%;
	transform: translateX(-50%);
	background-color: #79e7cc;
	color: white;
`

export default CommunityProfileAvatar
