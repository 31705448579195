import styled from "styled-components"

import { type PersonalizedCourse } from "@forento/shared/models/course"

import CourseComments from "~/themes/school/components/CourseComments"
import Resources from "~/themes/school/components/Resources"
import ChaptersList from "~/themes/school/pages/course/CourseDetailPage/ChaptersList"
import { lightTextColor } from "~/themes/school/styles"
import { useTranslation } from "~/translations"

export default function useTabs(
	course: PersonalizedCourse,
	reloadCourse: () => Promise<unknown>,
	setLongDescriptionElement: (element: HTMLElement | null) => void,
) {
	const t = useTranslation()

	const tabs = []
	if (course.longDescription !== null) {
		tabs.push({
			title: t("course.overview"),
			content: (
				<AboutContent>
					<div ref={setLongDescriptionElement} />
				</AboutContent>
			),
		})
	}
	tabs.push({
		title: t("course.chapters"),
		content: (
			<ChaptersList
				chapters={course.chapters}
				firstUncompletedPageId={
					course.status === "unlocked" ? (course.firstUncompletedPage?.id ?? null) : undefined
				}
			/>
		),
	})
	if (course.status === "unlocked" && course.resources.length > 0) {
		tabs.push({
			title: t("course.resources"),
			content: <Resources resources={course.resources} />,
		})
	}
	if (course.commentsEnabled) {
		tabs.push({
			title: t("course.comments"),
			content: (
				<CourseComments
					courseId={course.id}
					pageId={null}
					commentsUnderReview={course.status === "unlocked" ? course.commentsUnderReview : 0}
					reloadCourse={reloadCourse}
				/>
			),
		})
	}
	return tabs
}

const AboutContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 12px;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-weight: 600;
		font-size: 20px;
		color: #151d48;
	}

	> p {
		font-size: 16px;
		color: ${lightTextColor};
	}
`
