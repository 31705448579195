import { type FC } from "react"

import { toPriceString } from "@forento/shared/utilities/currency"

import { useUser } from "~/contexts/UserContext"
import Card, { Cards } from "~/themes/original/components/Card"
import Layout from "~/themes/original/components/Layout"
import PartialLoadingPage from "~/themes/original/components/PartialLoadingPage"
import routes from "~/utilities/routes"
import { swr } from "~/utilities/trpc"

const MembershipsPage: FC = () => {
	const user = useUser().user

	const { data: memberships, error } = swr.membership.list.useSWR()

	return (
		<Layout>
			{error ? (
				<p>Failed to load memberships.</p>
			) : memberships === undefined ? (
				<PartialLoadingPage />
			) : (
				<Cards>
					{memberships.map(membership => (
						<Card
							key={membership.id}
							title={membership.title}
							description={membership.shortDescription ?? undefined}
							thumbnailFilePath={membership.thumbnailFilePath}
							sticker={
								user?.membership?.id === membership.id ? { text: "Active", color: "green" } : undefined
							}
							footer={membership.prices.map(price => ({
								text: `${toPriceString(price)}/${price.paymentInterval}`,
								isBold: true,
							}))}
							onClick={routes.membership.detail(membership.id)}
						/>
					))}
				</Cards>
			)}
		</Layout>
	)
}

export default MembershipsPage
