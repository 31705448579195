import { type FC, type ReactNode, createContext, useCallback, useContext, useState } from "react"
import snarkdown from "snarkdown"
import randomUUID from "uuid-random"

import { useThemeComponents } from "~/contexts/ThemeComponentContext"

type ConfirmResult = { result: true; close: () => void } | { result: false; close?: never }
type InputResult = { result: string; close: () => void } | { result: null; close?: never }

interface Value {
	show(title: string, message: string): Promise<void>
	confirm(title: string, message: string): Promise<ConfirmResult>
	input(title: string, message: string, label: string, defaultValue?: string): Promise<InputResult>
}

const AlertContext = createContext<Value>({
	show: () => Promise.resolve(),
	confirm: () => Promise.resolve({ result: false }),
	input: () => Promise.resolve({ result: null }),
})

export const useAlert = () => useContext(AlertContext)

type Modal = {
	id: string
	closeDate?: Date
	title: string
	message: string
} & (
	| {
			type: "alert"
			onResolve?: () => void
			isSubmitting?: never
	  }
	| {
			type: "confirm"
			onResolve: (params: ConfirmResult) => void
			isSubmitting: boolean
	  }
	| {
			type: "input"
			label: string
			text: string
			onResolve: (params: InputResult) => void
			isSubmitting: boolean
	  }
)

export const AlertContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
	const { Button, InputField, Modal } = useThemeComponents()

	const [modals, setModals] = useState<Modal[]>([])

	const closeModalById = (modalId: string) => {
		const modal = modals.find(x => x.id === modalId)
		if (modal === undefined) return

		setModals(current => [{ ...modal, closeDate: new Date() }, ...current.filter(x => x.id !== modalId)])

		setTimeout(() => {
			setModals(current => current.filter(x => x.id !== modalId))
		}, 10_000)
	}

	const setModalTextById = (modalId: string, text: string) => {
		const modal = modals.find(x => x.id === modalId)
		if (modal === undefined || modal.type !== "input") return

		setModals(current => [{ ...modal, text }, ...current.filter(x => x.id !== modalId)])

		setTimeout(() => {
			setModals(current => current.filter(x => x.id !== modalId))
		}, 10_000)
	}

	const setSubmittingByModalById = (modalId: string, value: boolean) => {
		const modal = modals.find(x => x.id === modalId)
		if (modal === undefined || modal.type !== "confirm") return

		setModals(current => [{ ...modal, isSubmitting: value }, ...current.filter(x => x.id !== modalId)])
	}

	const handleShow = useCallback(
		(title: string, message: string) =>
			new Promise<void>(resolve => {
				setModals(current => [
					{ type: "alert", onResolve: resolve, id: randomUUID(), title, message },
					...current,
				])
			}),
		[],
	)

	const handleConfirm = useCallback(
		(title: string, message: string) =>
			new Promise<ConfirmResult>(resolve => {
				setModals(current => [
					{
						type: "confirm",
						onResolve: resolve,
						id: randomUUID(),
						title,
						message,
						isSubmitting: false,
					},
					...current,
				])
			}),
		[],
	)

	const handleInput = useCallback(
		(title: string, message: string, label: string, defaultValue?: string) =>
			new Promise<InputResult>(resolve => {
				setModals(current => [
					{
						type: "input",
						onResolve: resolve,
						id: randomUUID(),
						title,
						message,
						label,
						text: defaultValue ?? "",
						isSubmitting: false,
					},
					...current,
				])
			}),
		[],
	)

	const modalToShow = modals.find(x => x.closeDate === undefined)
	const modalsToRender = [
		...modals.filter(x => x.closeDate !== undefined),
		...(modalToShow !== undefined ? [modalToShow] : []),
	]

	return (
		<AlertContext.Provider value={{ show: handleShow, confirm: handleConfirm, input: handleInput }}>
			{children}
			{modalsToRender.map(modal => (
				<Modal.Frame key={modal.id} isOpen={modal.closeDate === undefined}>
					<Modal.Title>{modal.title}</Modal.Title>
					<Modal.Text dangerouslySetInnerHTML={{ __html: snarkdown(modal.message) }} />
					{modal.type === "alert" ? (
						<Modal.Buttons>
							<Button
								variant="primary"
								onClick={() => {
									closeModalById(modal.id)
									modal.onResolve?.()
								}}
							>
								Close
							</Button>
						</Modal.Buttons>
					) : modal.type === "confirm" ? (
						<Modal.Buttons>
							<Button
								variant="secondary"
								onClick={() => {
									modal.onResolve({ result: false })
									closeModalById(modal.id)
								}}
								isDisabled={modal.isSubmitting}
							>
								No
							</Button>
							<Button
								variant="danger"
								onClick={async () => {
									setSubmittingByModalById(modal.id, true)
									modal.onResolve({ result: true, close: () => closeModalById(modal.id) })
								}}
								isLoading={modal.isSubmitting}
							>
								Yes
							</Button>
						</Modal.Buttons>
					) : modal.type === "input" ? (
						<>
							<InputField
								label={modal.label}
								value={modal.text}
								onChange={value => setModalTextById(modal.id, value)}
							/>
							<Modal.Buttons>
								<Button
									variant="secondary"
									onClick={() => {
										modal.onResolve({ result: null })
										closeModalById(modal.id)
									}}
									isDisabled={modal.isSubmitting}
								>
									Cancel
								</Button>
								<Button
									variant="primary"
									onClick={async () => {
										setSubmittingByModalById(modal.id, true)
										modal.onResolve({
											result: modal.text,
											close: () => closeModalById(modal.id),
										})
									}}
									isLoading={modal.isSubmitting}
								>
									Save
								</Button>
							</Modal.Buttons>
						</>
					) : null}
				</Modal.Frame>
			))}
		</AlertContext.Provider>
	)
}
