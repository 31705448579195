import { type FC } from "react"
import styled, { css } from "styled-components"

type Props = {
	chapters: {
		id: number
		title: string
		pageIds: number[]
	}[]
	firstUncompletedPageId?: number | null
}

const ChaptersList: FC<Props> = ({ chapters, firstUncompletedPageId }) => (
	<Container>
		{getChaptersWithProgress(chapters, firstUncompletedPageId).map(chapter => (
			<Chapter key={chapter.id} $isCompleted={chapter.pages.completed === chapter.pages.total}>
				<div>{chapter.title}</div>
				<ChapterPages>
					{chapter.pages.completed}/{chapter.pages.total}
				</ChapterPages>
			</Chapter>
		))}
	</Container>
)

function getChaptersWithProgress(chapters: Props["chapters"], firstUncompletedPageId?: number | null) {
	if (firstUncompletedPageId === undefined) {
		return chapters.map(chapter => ({
			id: chapter.id,
			title: chapter.title,
			pages: { total: chapter.pageIds.length, completed: 0 },
		}))
	}
	if (firstUncompletedPageId === null) {
		return chapters.map(chapter => ({
			id: chapter.id,
			title: chapter.title,
			pages: { total: chapter.pageIds.length, completed: chapter.pageIds.length },
		}))
	}

	const result = []
	let isCompleted = true
	for (const chapter of chapters) {
		if (!isCompleted) {
			result.push({
				id: chapter.id,
				title: chapter.title,
				pages: { total: chapter.pageIds.length, completed: 0 },
			})
			continue
		}
		let completedPages = 0
		for (const pageId of chapter.pageIds) {
			if (pageId === firstUncompletedPageId) {
				isCompleted = false
				break
			}
			completedPages++
		}
		result.push({
			id: chapter.id,
			title: chapter.title,
			pages: { total: chapter.pageIds.length, completed: completedPages },
		})
	}
	return result
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const ChapterPages = styled.div`
	color: #454d66;
`

const Chapter = styled.div<{ $isCompleted: boolean }>`
	background-color: white;
	color: ${props => (props.$isCompleted ? props.theme.primaryColor : "black")};
	border: 1px solid ${props => (props.$isCompleted ? props.theme.primaryColor : "black")};
	padding: 8px 32px;
	border-radius: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	${props =>
		props.$isCompleted &&
		css`
			${ChapterPages} {
				color: ${props => props.theme.primaryColor};
			}
		`}
`

export default ChaptersList
