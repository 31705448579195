import {
	$isListNode,
	INSERT_ORDERED_LIST_COMMAND,
	INSERT_UNORDERED_LIST_COMMAND,
	ListNode,
	REMOVE_LIST_COMMAND,
} from "@lexical/list"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { $isAtNodeEnd } from "@lexical/selection"
import { $findMatchingParent, $getNearestNodeOfType, mergeRegister } from "@lexical/utils"
import {
	$getSelection,
	$isParagraphNode,
	$isRangeSelection,
	$isRootOrShadowRoot,
	$isTextNode,
	COMMAND_PRIORITY_LOW,
	type ElementNode,
	FORMAT_TEXT_COMMAND,
	type RangeSelection,
	SELECTION_CHANGE_COMMAND,
	type TextFormatType,
	type TextNode,
} from "lexical"
import { type FC, useCallback, useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom"
import styled, { css } from "styled-components"

function setFloatingElemPosition(targetRect: DOMRect | null, floatingElem: HTMLElement): void {
	const verticalGap = 10
	const horizontalOffset = 5
	const scrollerElem = document.body.parentElement

	if (targetRect === null || !scrollerElem) {
		floatingElem.style.opacity = "0"
		floatingElem.style.transform = "translate(-10000px, -10000px)"
		return
	}

	const floatingElemRect = floatingElem.getBoundingClientRect()
	const anchorElementRect = document.body.getBoundingClientRect()
	const editorScrollerRect = scrollerElem.getBoundingClientRect()

	let top = targetRect.top - floatingElemRect.height - verticalGap
	let left = targetRect.left - horizontalOffset

	if (top < editorScrollerRect.top) {
		top += floatingElemRect.height + targetRect.height + verticalGap * 2
	}

	if (left + floatingElemRect.width > editorScrollerRect.right) {
		left = editorScrollerRect.right - floatingElemRect.width - horizontalOffset
	}

	top -= anchorElementRect.top
	left -= anchorElementRect.left

	floatingElem.style.opacity = "1"
	floatingElem.style.transform = `translate(${left}px, ${top}px)`
}

function getSelectedNode(selection: RangeSelection): TextNode | ElementNode {
	const anchorNode = selection.anchor.getNode()
	const focusNode = selection.focus.getNode()
	if (anchorNode === focusNode) return anchorNode

	if (selection.isBackward()) {
		return $isAtNodeEnd(selection.focus) ? anchorNode : focusNode
	} else {
		return $isAtNodeEnd(selection.anchor) ? anchorNode : focusNode
	}
}

const LexicalToolbarPlugin: FC = () => {
	const [editor] = useLexicalComposerContext()

	const containerRef = useRef<HTMLDivElement | null>(null)

	const [isText, setText] = useState(false)
	const [isBold, setBold] = useState(false)
	const [isItalic, setItalic] = useState(false)
	const [isUnderline, setUnderline] = useState(false)
	const [isStrikethrough, setStrikethrough] = useState(false)
	const [isSubscript, setSubscript] = useState(false)
	const [isSuperscript, setSuperscript] = useState(false)
	const [isCode, setCode] = useState(false)
	const [listType, setListType] = useState<"bullet" | "number">()

	const updatePopup = useCallback(() => {
		editor.getEditorState().read(() => {
			// Should not to pop up the floating toolbar when using IME input
			if (editor.isComposing()) {
				return
			}
			const selection = $getSelection()
			const nativeSelection = window.getSelection()
			const rootElement = editor.getRootElement()

			if (
				nativeSelection !== null &&
				(!$isRangeSelection(selection) ||
					rootElement === null ||
					!rootElement.contains(nativeSelection.anchorNode))
			) {
				setText(false)
				return
			}

			if (!$isRangeSelection(selection)) {
				return
			}

			setBold(selection.hasFormat("bold"))
			setItalic(selection.hasFormat("italic"))
			setUnderline(selection.hasFormat("underline"))
			setStrikethrough(selection.hasFormat("strikethrough"))
			setSubscript(selection.hasFormat("subscript"))
			setSuperscript(selection.hasFormat("superscript"))
			setCode(selection.hasFormat("code"))

			const node = getSelectedNode(selection)
			if (selection.getTextContent() !== "") {
				setText($isTextNode(node) || $isParagraphNode(node))
			} else {
				setText(false)
			}

			const rawTextContent = selection.getTextContent().replace(/\n/g, "")
			if (!selection.isCollapsed() && rawTextContent === "") {
				setText(false)
				return
			}
		})
	}, [editor])

	useEffect(() => {
		document.addEventListener("selectionchange", updatePopup)
		return () => {
			document.removeEventListener("selectionchange", updatePopup)
		}
	}, [updatePopup])

	useEffect(() => {
		return mergeRegister(
			editor.registerUpdateListener(() => {
				updatePopup()
			}),
			editor.registerRootListener(() => {
				if (editor.getRootElement() === null) {
					setText(false)
				}
			}),
		)
	}, [editor, updatePopup])

	useEffect(() => {
		function mouseMoveListener(event: MouseEvent) {
			if (containerRef.current && (event.buttons === 1 || event.buttons === 3)) {
				if (containerRef.current.style.pointerEvents !== "none") {
					const x = event.clientX
					const y = event.clientY
					const elementUnderMouse = document.elementFromPoint(x, y)

					if (!containerRef.current.contains(elementUnderMouse)) {
						// Mouse is not over the target element => not a normal click, but probably a drag
						containerRef.current.style.pointerEvents = "none"
					}
				}
			}
		}

		function mouseUpListener() {
			if (containerRef?.current) {
				if (containerRef.current.style.pointerEvents !== "auto") {
					containerRef.current.style.pointerEvents = "auto"
				}
			}
		}

		if (containerRef?.current) {
			document.addEventListener("mousemove", mouseMoveListener)
			document.addEventListener("mouseup", mouseUpListener)

			return () => {
				document.removeEventListener("mousemove", mouseMoveListener)
				document.removeEventListener("mouseup", mouseUpListener)
			}
		}
	}, [containerRef])

	const $updateTextFormatFloatingToolbar = useCallback(() => {
		if (containerRef.current === null) return

		const selection = $getSelection()

		const nativeSelection = window.getSelection()

		const rootElement = editor.getRootElement()
		if (
			selection !== null &&
			nativeSelection !== null &&
			!nativeSelection.isCollapsed &&
			rootElement !== null &&
			rootElement.contains(nativeSelection.anchorNode)
		) {
			let rect
			if (nativeSelection.anchorNode === rootElement) {
				let inner = rootElement
				while (inner.firstElementChild !== null) {
					inner = inner.firstElementChild as HTMLElement
				}
				rect = inner.getBoundingClientRect()
			} else {
				rect = nativeSelection.getRangeAt(0).getBoundingClientRect()
			}

			setFloatingElemPosition(rect, containerRef.current)
		}

		if ($isRangeSelection(selection)) {
			const anchorNode = selection.anchor.getNode()
			let element =
				anchorNode.getKey() === "root"
					? anchorNode
					: $findMatchingParent(anchorNode, e => {
							const parent = e.getParent()
							return parent !== null && $isRootOrShadowRoot(parent)
						})

			if (element === null) {
				element = anchorNode.getTopLevelElementOrThrow()
			}

			const elementDOM = editor.getElementByKey(element.getKey())
			if (elementDOM !== null) {
				if ($isListNode(element)) {
					const parentList = $getNearestNodeOfType<ListNode>(anchorNode, ListNode)
					const type = parentList ? parentList.getListType() : element.getListType()
					if (type !== "check") {
						setListType(type)
					}
				}
			}
		}
	}, [editor])

	useEffect(() => {
		function update() {
			editor.getEditorState().read(() => {
				$updateTextFormatFloatingToolbar()
			})
		}

		const scrollerElem = document.body.parentElement
		window.addEventListener("resize", update)
		if (scrollerElem) {
			scrollerElem.addEventListener("scroll", update)
		}

		return () => {
			window.removeEventListener("resize", update)
			if (scrollerElem) {
				scrollerElem.removeEventListener("scroll", update)
			}
		}
	}, [editor, $updateTextFormatFloatingToolbar])

	useEffect(() => {
		editor.getEditorState().read(() => {
			$updateTextFormatFloatingToolbar()
		})
		return mergeRegister(
			editor.registerUpdateListener(({ editorState }) => {
				editorState.read(() => {
					$updateTextFormatFloatingToolbar()
				})
			}),

			editor.registerCommand(
				SELECTION_CHANGE_COMMAND,
				() => {
					$updateTextFormatFloatingToolbar()
					return false
				},
				COMMAND_PRIORITY_LOW,
			),
		)
	}, [editor, $updateTextFormatFloatingToolbar])

	function formatText(formatType: TextFormatType) {
		editor.dispatchCommand(FORMAT_TEXT_COMMAND, formatType)
	}

	if (!isText || !editor.isEditable()) return null

	return createPortal(
		<Container ref={containerRef}>
			<Option $isActive={isBold} onClick={() => formatText("bold")}>
				<OptionIcon viewBox="0 -960 960 960">
					<path d="M272-200v-560h221q65 0 120 40t55 111q0 51-23 78.5T602-491q25 11 55.5 41t30.5 90q0 89-65 124.5T501-200H272Zm121-112h104q48 0 58.5-24.5T566-372q0-11-10.5-35.5T494-432H393v120Zm0-228h93q33 0 48-17t15-38q0-24-17-39t-44-15h-95v109Z" />
				</OptionIcon>
			</Option>
			<Option $isActive={isItalic} onClick={() => formatText("italic")}>
				<OptionIcon viewBox="0 -960 960 960">
					<path d="M200-200v-100h160l120-360H320v-100h400v100H580L460-300h140v100H200Z" />
				</OptionIcon>
			</Option>
			<Option $isActive={isUnderline} onClick={() => formatText("underline")}>
				<OptionIcon viewBox="0 -960 960 960">
					<path d="M200-120v-80h560v80H200Zm280-160q-101 0-157-63t-56-167v-330h103v336q0 56 28 91t82 35q54 0 82-35t28-91v-336h103v330q0 104-56 167t-157 63Z" />
				</OptionIcon>
			</Option>
			<Option $isActive={isStrikethrough} onClick={() => formatText("strikethrough")}>
				<OptionIcon viewBox="0 -960 960 960">
					<path d="M80-400v-80h800v80H80Zm340-160v-120H200v-120h560v120H540v120H420Zm0 400v-160h120v160H420Z" />
				</OptionIcon>
			</Option>
			<Option $isActive={isSubscript} onClick={() => formatText("subscript")}>
				<OptionIcon viewBox="0 -960 960 960">
					<path d="M760-160v-80q0-17 11.5-28.5T800-280h80v-40H760v-40h120q17 0 28.5 11.5T920-320v40q0 17-11.5 28.5T880-240h-80v40h120v40H760Zm-525-80 185-291-172-269h106l124 200h4l123-200h107L539-531l186 291H618L482-457h-4L342-240H235Z" />
				</OptionIcon>
			</Option>
			<Option $isActive={isSuperscript} onClick={() => formatText("superscript")}>
				<OptionIcon viewBox="0 -960 960 960">
					<path d="M760-600v-80q0-17 11.5-28.5T800-720h80v-40H760v-40h120q17 0 28.5 11.5T920-760v40q0 17-11.5 28.5T880-680h-80v40h120v40H760ZM235-160l185-291-172-269h106l124 200h4l123-200h107L539-451l186 291H618L482-377h-4L342-160H235Z" />
				</OptionIcon>
			</Option>
			<Option $isActive={isCode} onClick={() => formatText("code")}>
				<OptionIcon viewBox="0 -960 960 960">
					<path d="M320-240 80-480l240-240 57 57-184 184 183 183-56 56Zm320 0-57-57 184-184-183-183 56-56 240 240-240 240Z" />
				</OptionIcon>
			</Option>
			<Option
				$isActive={listType === "bullet"}
				onClick={() => {
					if (listType === "bullet") {
						editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
					} else {
						editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined)
					}
				}}
			>
				<OptionIcon viewBox="0 -960 960 960">
					<path d="M280-600v-80h560v80H280Zm0 160v-80h560v80H280Zm0 160v-80h560v80H280ZM160-600q-17 0-28.5-11.5T120-640q0-17 11.5-28.5T160-680q17 0 28.5 11.5T200-640q0 17-11.5 28.5T160-600Zm0 160q-17 0-28.5-11.5T120-480q0-17 11.5-28.5T160-520q17 0 28.5 11.5T200-480q0 17-11.5 28.5T160-440Zm0 160q-17 0-28.5-11.5T120-320q0-17 11.5-28.5T160-360q17 0 28.5 11.5T200-320q0 17-11.5 28.5T160-280Z" />
				</OptionIcon>
			</Option>
			<Option
				$isActive={listType === "number"}
				onClick={() => {
					if (listType === "number") {
						editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
					} else {
						editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined)
					}
				}}
			>
				<OptionIcon viewBox="0 -960 960 960">
					<path d="M120-80v-60h100v-30h-60v-60h60v-30H120v-60h120q17 0 28.5 11.5T280-280v40q0 17-11.5 28.5T240-200q17 0 28.5 11.5T280-160v40q0 17-11.5 28.5T240-80H120Zm0-280v-110q0-17 11.5-28.5T160-510h60v-30H120v-60h120q17 0 28.5 11.5T280-560v70q0 17-11.5 28.5T240-450h-60v30h100v60H120Zm60-280v-180h-60v-60h120v240h-60Zm180 440v-80h480v80H360Zm0-240v-80h480v80H360Zm0-240v-80h480v80H360Z" />
				</OptionIcon>
			</Option>
		</Container>,
		document.body,
	)
}

const Container = styled.div`
	display: flex;
	background: #fff;
	padding: 4px;
	vertical-align: middle;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10000;
	opacity: 0;
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
	border-radius: 8px;
	transition: opacity 0.5s;
	display: flex;
	flex-direction: row;
	gap: 2px;
`

const Option = styled.button.attrs({ type: "button" })<{ $isActive: boolean }>`
	appearance: none;
	text-decoration: none;
	border: none;
	font-weight: normal;
	color: black;
	text-align: left;
	margin: 0;
	display: flex;
	background: none;
	border-radius: 10px;
	padding: 8px;
	cursor: pointer;
	vertical-align: middle;

	${props =>
		props.$isActive &&
		css`
			background-color: rgba(223, 232, 250, 0.3);
		`}

	&:hover {
		background-color: #eee;
	}
`

const OptionIcon = styled.svg.attrs({ xmlns: "http://www.w3.org/2000/svg" })`
	background-size: contain;
	height: 18px;
	width: 18px;
	display: flex;
	opacity: 0.6;
`

export default LexicalToolbarPlugin
