import { type FC, type ReactNode } from "react"
import styled from "styled-components"

import BaseModal from "@forento/shared/components/Modal"

import { fonts } from "~/themes/school/styles"
import { mediaQueries } from "~/utilities/dimensions"

interface Props {
	isOpen: boolean
	width?: string | number
	onSubmit?(): void
	children: ReactNode
}

const Modal: FC<Props> = ({ isOpen, width, onSubmit, children }) => (
	<BaseModal isOpen={isOpen} width={width ?? 500} onSubmit={onSubmit}>
		<Content>{children}</Content>
	</BaseModal>
)

const Content = styled.div`
	padding: 64px;
	text-align: "center";
	display: flex;
	flex-direction: column;
	gap: 16px;
`

export const ModalTitle = styled.h1`
	font-family: ${fonts.quicksand};
	font-size: 20px;
	font-weight: bold;
	text-align: center;
`

export const ModalText = styled.p`
	font-size: 14px;
	text-align: center;
`

export const ModalButtons = styled.div`
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	gap: 16px;
	margin-top: ${24 - 16}px;

	${mediaQueries.tablet} {
		flex-direction: row;
		justify-content: center;
	}

	> * {
		flex: 1 0 auto;
	}
`

export default Modal
