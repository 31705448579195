import { type FC, Fragment } from "react"
import styled from "styled-components"

import { usePrivacyPolicy } from "~/api/privacyPolicy"
import Layout from "~/themes/school/components/Layout"
import { PageTitle } from "~/themes/school/components/PageHeader"
import { fonts } from "~/themes/school/styles"
import { useTranslation } from "~/translations"

const PrivacyPolicyPage: FC = () => {
	const content = usePrivacyPolicy()
	const t = useTranslation()

	return (
		<Layout>
			<PageTitle>{t("privacyPolicy")}</PageTitle>
			<Content>
				{content.introItems.map((item, index) => (
					<Text key={index}>{item}</Text>
				))}
				{content.sections.map((section, index) => (
					<Fragment key={index}>
						<SectionTitle>{section.title}</SectionTitle>
						{section.items.map((item, index) => (
							<Text key={index}>{item}</Text>
						))}
					</Fragment>
				))}
			</Content>
		</Layout>
	)
}

const Content = styled.div`
	background-color: white;
	padding: 42px;
`

const SectionTitle = styled.h2`
	font-family: ${fonts.quicksand};
	font-size: 16px;
	font-weight: bold;
	margin-bottom: 8px;
`

const Text = styled.p`
	font-size: 14px;
	margin-bottom: 16px;
`

export default PrivacyPolicyPage
