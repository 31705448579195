import { type FC } from "react"
import { Navigate, useParams } from "react-router"

import { parseNumber } from "@forento/shared/utilities/number"

import Button from "~/themes/school/components/Button"
import Hero from "~/themes/school/components/Hero"
import Layout from "~/themes/school/components/Layout"
import { PageBreadcrumb } from "~/themes/school/components/PageHeader"
import PartialLoadingPage from "~/themes/school/components/PartialLoadingPage"
import { useTranslation } from "~/translations"
import routes from "~/utilities/routes"
import { swr } from "~/utilities/trpc"

import Thumbnail from "./Thumbnail"

const ExamResultPage: FC = () => {
	const resultId = parseNumber(useParams().resultId) ?? -1
	const t = useTranslation()

	const { data: result, error } = swr.exam.getCompletion.useSWR(resultId)

	if (result === null) return <Navigate to={routes.exam.index()} />

	return (
		<Layout>
			<PageBreadcrumb
				path={[
					{ title: "Exams", link: routes.exam.index() },
					{
						title: result?.exam.name ?? "...",
						link: result?.exam.id !== undefined ? routes.exam.detail(result.exam.id) : undefined,
					},
				]}
				title={t("exam.result")}
			/>
			{error ? (
				<p>{t("failedToLoad")}</p>
			) : result === undefined ? (
				<PartialLoadingPage />
			) : (
				<Hero
					title={t("exam.completed")}
					description={t("exam.score", { score: result.score.score, total: result.score.totalScore })}
					callToAction={
						<Button variant="primary" onClick={routes.exam.index()}>
							{t("exam.goToAll")}
						</Button>
					}
					thumbnail={<Thumbnail />}
				/>
			)}
		</Layout>
	)
}

export default ExamResultPage
