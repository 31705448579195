import { forwardRef, type ReactNode } from "react"
import { Link } from "react-router-dom"
import styled, { css } from "styled-components"
import tinycolor from "tinycolor2"
import Values from "values.js"

import { usePlatform } from "~/contexts/PlatformContext"
import LoadingIndicator from "~/themes/original/components/LoadingIndicator"
import { isColorDark } from "~/utilities/color"
import { dangerColor } from "~/utilities/styles"

type ButtonVariant = "none" | "primary" | "secondary" | "bordered" | "danger" | "danger-bordered"

interface ButtonProps {
	variant?: ButtonVariant
	onClick?: string | (() => void)
	newTab?: boolean
	isLoading?: boolean
	isDisabled?: boolean
	replaceOnLoading?: boolean
	className?: string
	children: ReactNode
}

interface SubmitButtonProps {
	variant?: ButtonVariant
	isLoading?: boolean
	isDisabled?: boolean
	replaceOnLoading?: boolean
	className?: string
	children: ReactNode
}

const Button = forwardRef<HTMLButtonElement & HTMLAnchorElement & HTMLDivElement, ButtonProps>(
	({ variant = "none", onClick, newTab, isLoading, isDisabled, replaceOnLoading, className, children }, ref) => {
		const apiVersion = usePlatform().apiVersion

		if (isDisabled || isLoading) {
			return (
				<Container as="button" ref={ref} type="button" $variant={variant} $isDisabled className={className}>
					{isLoading && replaceOnLoading ? (
						<StyledLoadingIndicator />
					) : (
						<>
							{isLoading && <InlineLoadingIndicator />}
							{children}
						</>
					)}
				</Container>
			)
		}

		const newTabProps = { target: "_blank", rel: "noreferrer noopener" }
		if (onClick === undefined) {
			return (
				<Container ref={ref} $variant={variant} className={className}>
					{children}
				</Container>
			)
		} else if (typeof onClick === "function")
			return (
				<Container
					as="button"
					ref={ref}
					type="button"
					onClick={onClick}
					$variant={variant}
					className={className}
				>
					{children}
				</Container>
			)
		else if (onClick.startsWith("/"))
			return (
				<Container
					as={Link}
					reloadDocument={apiVersion !== null && apiVersion !== CONFIG.apiVersion}
					ref={ref}
					to={onClick}
					$variant={variant}
					className={className}
					{...(newTab ? newTabProps : {})}
				>
					{children}
				</Container>
			)
		else
			return (
				<Container
					as="a"
					ref={ref}
					href={onClick}
					$variant={variant}
					className={className}
					{...(newTab ? newTabProps : {})}
				>
					{children}
				</Container>
			)
	},
)

export const SubmitButton: React.FC<SubmitButtonProps> = ({
	variant = "none",
	isLoading,
	isDisabled,
	replaceOnLoading,
	className,
	children,
}) => {
	if (isDisabled || isLoading) {
		return (
			<Container $variant={variant} $isDisabled className={className}>
				{isLoading && replaceOnLoading ? (
					<StyledLoadingIndicator />
				) : (
					<>
						{isLoading && <InlineLoadingIndicator />}
						{children}
					</>
				)}
			</Container>
		)
	}

	return (
		<Container as="button" type="submit" $variant={variant} className={className}>
			{children}
		</Container>
	)
}

const disabledStyles = css`
	color: black;
	background-color: #f4f4f4;
	border: 1px solid #efefef;
	padding: 15px 23px;
`

const Container = styled.div<{ $variant: ButtonVariant; $isDisabled?: boolean }>`
	display: block;
	appearance: none;
	background-color: transparent;
	font-family: inherit;
	cursor: pointer;
	text-decoration: none;
	border: none;
	font-weight: normal;
	color: black;
	text-align: left;
	margin: 0;
	padding: 0;

	${props =>
		props.$variant === "primary" &&
		css`
			color: ${props => (isColorDark(props.theme.primaryColor) ? "#f9fafb" : "black")};
			font-weight: 600;
			font-size: 16px;
			background-color: ${props => props.theme.primaryColor};
			padding: 16px 24px;
			border-radius: 8px;
			transition: 0.15s;
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 8px;

			&:hover {
				background-color: ${props => tinycolor(props.theme.primaryColor).darken(2).toString()};
			}

			&:active {
				background-color: ${props => tinycolor(props.theme.primaryColor).darken(10).toString()};
			}

			${props.$isDisabled &&
			css`
				&,
				&:hover,
				&:active {
					${disabledStyles}
				}
			`}
		`}

	${props =>
		props.$variant === "secondary" &&
		css`
			color: #444a6d;
			font-weight: 600;
			font-size: 16px;
			background-color: white;
			padding: 16px 24px;
			border-radius: 8px;
			transition: 0.15s;
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 8px;

			&:hover {
				background-color: ${tinycolor("white").darken(2).toString()};
			}

			&:active {
				background-color: ${tinycolor("white").darken(10).toString()};
			}

			${props.$isDisabled &&
			css`
				&,
				&:hover,
				&:active {
					${disabledStyles}
				}
			`}
		`}

	${props =>
		props.$variant === "bordered" &&
		css`
			color: #444a6d;
			font-weight: 600;
			font-size: 16px;
			background-color: white;
			padding: 14px 22px;
			border: 2px solid ${props => props.theme.primaryColor};
			border-radius: 8px;
			transition: 0.15s;
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 8px;

			&:hover {
				background-color: ${props => new Values(props.theme.primaryColor).tint(60).hexString()};
			}

			&:active {
				color: #f9fafb;
				background-color: ${props => props.theme.primaryColor};
			}

			${props.$isDisabled &&
			css`
				&,
				&:hover,
				&:active {
					${disabledStyles}
				}
			`}
		`}

		${props =>
		props.$variant === "danger" &&
		css`
			color: white;
			font-weight: 600;
			font-size: 16px;
			background-color: ${dangerColor};
			padding: 16px 24px;
			border-radius: 8px;
			transition: 0.15s;
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 8px;

			&:hover {
				background-color: ${tinycolor(dangerColor).darken(5).toString()};
			}

			&:active {
				background-color: ${tinycolor(dangerColor).darken(15).toString()};
			}

			${props.$isDisabled &&
			css`
				&,
				&:hover,
				&:active {
					${disabledStyles}
				}
			`}
		`}

	${props =>
		props.$variant === "danger-bordered" &&
		css`
			color: #444a6d;
			font-weight: 600;
			font-size: 16px;
			background-color: white;
			padding: 14px 22px;
			border: 2px solid ${dangerColor};
			border-radius: 8px;
			transition: 0.15s;
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 8px;

			&:hover {
				background-color: ${new Values(dangerColor).tint(60).hexString()};
			}

			&:active {
				color: #f9fafb;
				background-color: ${dangerColor};
			}

			${props.$isDisabled &&
			css`
				&,
				&:hover,
				&:active {
					${disabledStyles}
				}
			`}
		`}
`

const InlineLoadingIndicator = styled(LoadingIndicator)`
	width: 12px;
	height: 12px;
`

const StyledLoadingIndicator = styled(LoadingIndicator)`
	width: 24px;
	height: 24px;
`

export default Button
