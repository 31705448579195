import { type FC } from "react"
import { Navigate, useNavigate, useParams } from "react-router"
import styled from "styled-components"
import Values from "values.js"

import AspectRatio from "@forento/shared/components/AspectRatio"
import { parseNumber } from "@forento/shared/utilities/number"

import { useAlert } from "~/contexts/AlertContext"
import { useUser } from "~/contexts/UserContext"
import Button from "~/themes/original/components/Button"
import Layout from "~/themes/original/components/Layout"
import PartialLoadingPage from "~/themes/original/components/PartialLoadingPage"
import routes from "~/utilities/routes"
import { lightTextColor } from "~/utilities/styles"
import { swr } from "~/utilities/trpc"

import Thumbnail from "./Thumbnail"

const ExamPage: FC = () => {
	const examId = parseNumber(useParams().examId) ?? -1
	const user = useUser().user
	const alert = useAlert()
	const navigate = useNavigate()

	const { data: exam, error } = swr.exam.getPersonalized.useSWR(examId)

	if (exam === null) return <Navigate to={routes.exam.index()} />

	const handleStart = async () => {
		if (exam === undefined) return

		if (user === null) {
			return navigate(`${routes.user.signup()}?next=${encodeURIComponent(location.pathname)}`)
		}

		const dialog = await alert.confirm("Start exam", "Are you sure you want to start this exam?")
		if (!dialog.result) return
		dialog.close()
		navigate(routes.exam.start(exam.id))
	}

	return (
		<Layout>
			{error ? (
				<p>Failed to load exam.</p>
			) : exam === undefined ? (
				<PartialLoadingPage />
			) : (
				<Content>
					<Details>
						<Title>{exam.name}</Title>
						{exam.longDescription && (
							<Description dangerouslySetInnerHTML={{ __html: exam.longDescription.html }} />
						)}
						{exam.userScore === null ? (
							<Button variant="primary" onClick={handleStart}>
								Start exam
							</Button>
						) : (
							<ExamCompletedText>You have already completed this exam.</ExamCompletedText>
						)}
					</Details>
					<ThumbnailContainer>
						<AspectRatio width={{ value: 100, unit: "percent" }} aspectRatio={16 / 12}>
							<Thumbnail />
						</AspectRatio>
					</ThumbnailContainer>
				</Content>
			)}
		</Layout>
	)
}

const Content = styled.div`
	background-color: ${props => new Values(props.theme.primaryColor).tint(90).hexString()};
	border-radius: 16px;
	display: flex;
	padding: 42px;
	gap: ${42 * 2}px;
	margin-bottom: 42px;
`

const Details = styled.div`
	flex: 1 0 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 22px;
`

const Title = styled.h1`
	font-weight: 500;
	font-size: 24px;
	color: #151d48;
`

const Description = styled.div`
	font-size: 16px;
	color: ${lightTextColor};
`

const ExamCompletedText = styled.p`
	font-style: italic;
`

const ThumbnailContainer = styled.div`
	flex: 1 0 0;
	border-radius: 8px;
	overflow: hidden;

	@media (max-width: 999px) {
		display: none;
	}
`

export default ExamPage
