import { useState } from "react"
import { Trans } from "react-i18next"
import { PhoneInput } from "react-international-phone"
import { useNavigate } from "react-router"
import { useSearchParams } from "react-router-dom"

import { useAlert } from "~/contexts/AlertContext"
import { usePlatform } from "~/contexts/PlatformContext"
import { useUser } from "~/contexts/UserContext"
import Button, { SubmitButton } from "~/themes/school/components/Button"
import InputField, { InputLabel } from "~/themes/school/components/InputField"
import PartialLoadingPage from "~/themes/school/components/PartialLoadingPage"
import { useTranslation } from "~/translations"
import routes, { getHomeRoute } from "~/utilities/routes"
import trpc, { swr } from "~/utilities/trpc"

import Layout, { InputFields, Buttons, Divider } from "../Layout"
import PhoneNumberInputStyles from "./PhoneNumberInputStyles"
import { TermsOfService, TermsOfServiceButton } from "./styles"

const InvitedSignup: React.FC<{ inviteToken: string }> = ({ inviteToken }) => {
	const platform = usePlatform()
	const user = useUser()
	const [searchParams] = useSearchParams()
	const navigate = useNavigate()
	const alert = useAlert()
	const t = useTranslation()

	const [firstName, setFirstName] = useState("")
	const [lastName, setLastName] = useState("")
	const [phoneNumber, setPhoneNumber] = useState("")
	const [password, setPassword] = useState("")
	const [passwordConfirmation, setPasswordConfirmation] = useState("")

	const [isSubmitting, setSubmitting] = useState(false)

	const { data: invite, error } = swr.user.verifyStudentInviteToken.useSWR(inviteToken)

	const isValid =
		invite?.invite != null &&
		firstName.trim().length > 0 &&
		lastName.trim().length > 0 &&
		password.length > 0 &&
		password === passwordConfirmation &&
		(!platform.platform!.isPhoneNumberEnabled || phoneNumber.length > 0)

	const handleSignup = async () => {
		if (!isValid) return

		setSubmitting(true)

		try {
			const response = await trpc.user.regiterInvitedStudent.mutate({
				inviteToken,
				firstName: firstName.trim(),
				lastName: lastName.trim(),
				phoneNumber: platform.platform!.isPhoneNumberEnabled ? phoneNumber : null,
				password,
				platformId: platform.platform!.id,
			})

			if (response.status !== "success") {
				switch (response.status) {
					case "email-taken":
						await alert.show(t("user.signUp.emailTaken"), t("user.signUp.emailTakenMessage"))
						break
					case "expired-invite-token":
						await alert.show(t("general.error"), "That invite link has expired.")
						break
					case "claimed-invite-token":
						await alert.show(t("general.error"), "That invite link has already been claimed.")
						break
					case "invalid-invite-token":
						await alert.show(t("general.error"), "That invite link is invalid.")
						break
				}
				return
			}

			await user.reload()

			if (invite.invite.membership !== null) {
				navigate(`${routes.user.invitedSignupMembership()}?invite=${inviteToken}`)
			} else {
				navigate(getHomeRoute(platform.platform!))
			}
		} catch {
			await alert.show(t("general.error"), t("general.unknownError"))
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<Layout title={t("user.signUp.title")} onSubmit={handleSignup}>
			{error ? (
				<p>{t("failedToLoad")}</p>
			) : invite === undefined ? (
				<PartialLoadingPage />
			) : invite.status === "invalid" ? (
				<p>{t("user.signUp.inviteLinkInvalid")}</p>
			) : invite.status === "expired" ? (
				<p>{t("user.signUp.inviteLinkExpired")}</p>
			) : invite.invite.isClaimed ? (
				<p>{t("user.signUp.inviteLinkUsed")}</p>
			) : (
				<>
					<InputFields>
						<InputField
							inputType="email"
							label={t("user.general.email")}
							value={invite.invite.email}
							disabled
						/>

						<InputField
							inputType="text"
							label={t("user.signUp.firstName")}
							value={firstName}
							onChange={setFirstName}
							autoComplete="given-name"
						/>

						<InputField
							inputType="text"
							label={t("user.signUp.lastName")}
							value={lastName}
							onChange={setLastName}
							autoComplete="family-name"
						/>

						{platform.platform!.isPhoneNumberEnabled && (
							<div>
								<PhoneNumberInputStyles />
								<InputLabel>{t("user.signUp.phoneNumber")}</InputLabel>
								<PhoneInput value={phoneNumber} onChange={setPhoneNumber} />
							</div>
						)}

						<InputField
							inputType="password"
							label={t("user.general.password")}
							value={password}
							onChange={setPassword}
						/>

						<InputField
							inputType="password"
							label={t("user.signUp.confirmPassword")}
							value={passwordConfirmation}
							onChange={setPasswordConfirmation}
						/>
					</InputFields>

					<TermsOfService>
						<Trans
							i18nKey="user.signUp.agreeToTerms"
							components={{
								terms: <TermsOfServiceButton onClick={routes.policy.termsOfService()} newTab />,
								privacy: <TermsOfServiceButton onClick={routes.policy.privacy()} newTab />,
							}}
						/>
					</TermsOfService>

					<Buttons>
						<SubmitButton variant="primary" isLoading={isSubmitting} isDisabled={!isValid}>
							{t("user.signUp.title")}
						</SubmitButton>

						<Divider />

						<Button variant="secondary" onClick={`${routes.user.signin()}?${searchParams}`}>
							{t("user.signIn.title")}
						</Button>
					</Buttons>
				</>
			)}
		</Layout>
	)
}

export default InvitedSignup
