import { type ReactNode, forwardRef } from "react"

interface Props {
	onSubmit?(): void
	className?: string
	children: ReactNode
}

const Form = forwardRef<HTMLFormElement, Props>(({ onSubmit, className, children }, ref) =>
	onSubmit ? (
		<form
			ref={ref}
			className={className}
			onSubmit={event => {
				event.preventDefault()
				onSubmit()
			}}
		>
			{children}
		</form>
	) : (
		<form ref={ref} className={className}>
			{children}
		</form>
	),
)

export default Form
