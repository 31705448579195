import { type FC } from "react"
import styled from "styled-components"

import Button from "~/themes/school/components/Button"
import { LockedIcon, PlayIcon } from "~/themes/school/components/Icon"
import PartialLoadingPage from "~/themes/school/components/PartialLoadingPage"
import { swr } from "~/utilities/trpc"

type Props = {
	courseId: number
	currentPageId: number | null
	firstUncompletedPageId: number | null
	setPage(pageId: number): void
}

const ChaptersList: FC<Props> = ({ courseId, currentPageId, firstUncompletedPageId, setPage }) => {
	const content = swr.course.listContent.useSWR({ courseId })

	if (content.error) {
		return (
			<Container>
				<Header>Chapters</Header>
				<p>Failed to load content.</p>
			</Container>
		)
	}

	if (!content.data) {
		return (
			<Container>
				<Header>Chapters</Header>
				<PartialLoadingPage />
			</Container>
		)
	}

	const firstUncompletedPage =
		content.data
			.flatMap(chapter =>
				chapter.pages.map(page => ({ ...page, chapter: { id: chapter.id, order: chapter.order } })),
			)
			.find(page => page.id === firstUncompletedPageId) ?? null

	const isPageUnlocked = (chapter: { order: number }, page: { order: number }): boolean =>
		firstUncompletedPage === null ||
		firstUncompletedPage.chapter.order > chapter.order ||
		(firstUncompletedPage.chapter.order === chapter.order && firstUncompletedPage.order >= page.order)

	return (
		<Container>
			{content.data.map(chapter => {
				const isActive = currentPageId !== null && chapter.pages.some(page => page.id === currentPageId)
				const isStarted = chapter.pages.some(page => isPageUnlocked(chapter, page))

				return (
					<div key={chapter.id}>
						<Chapter $isStarted={isStarted}>
							<Title
								onClick={
									chapter.pages.length > 0 && isPageUnlocked(chapter, chapter.pages[0])
										? () => setPage(chapter.pages[0].id)
										: undefined
								}
							>
								{chapter.title}
							</Title>
						</Chapter>
						{isActive && (
							<Pages>
								{chapter.pages.map(page => {
									const isUnlocked = isPageUnlocked(chapter, page)
									return (
										<Page key={page.id} onClick={isUnlocked ? () => setPage(page.id) : undefined}>
											<PlayIconContainer>
												{page.id === currentPageId ? (
													<PlayIcon />
												) : !isUnlocked ? (
													<LockedIcon />
												) : null}
											</PlayIconContainer>
											<PageTitle>{page.title}</PageTitle>
										</Page>
									)
								})}
							</Pages>
						)}
					</div>
				)
			})}
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const Chapter = styled.div<{ $isStarted?: boolean }>`
	background-color: white;
	color: ${props => (props.$isStarted ? props.theme.primaryColor : "black")};
	border: 1px solid ${props => (props.$isStarted ? props.theme.primaryColor : "black")};
	padding: 8px 32px;
	border-radius: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`

const Header = styled(Chapter)`
	font-weight: 600;
	font-size: 20px;
	color: #151d48;
	padding: 16px 24px;
`

const Title = styled(Button)`
	font-weight: 500;
	font-size: 18px;
	color: #302d3a;
`

const Pages = styled.div`
	margin: 8px 8px 0;
`

const Page = styled(Button)`
	width: 100%;
	padding: 4px 0;
	display: flex;
	gap: 4px;
	align-items: center;

	&:not(:first-child) {
		border-top: 1px solid #e8e8ed;
	}
`

const PlayIconContainer = styled.div`
	width: 18px;
	height: 18px;
`

const PageTitle = styled.h2`
	font-weight: normal;
	font-size: 14px;
	color: #302d3a;
`

export default ChaptersList
