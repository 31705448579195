import { type FC } from "react"
import styled from "styled-components"

import Button from "~/themes/original/components/Button"
import { AttachmentIcon, LinkIcon } from "~/themes/original/components/Icon"

type Props = { resources: { type: "link" | "attachment"; label: string; href: string }[] }

const Resources: FC<Props> = ({ resources }) =>
	resources.length > 0 ? (
		<Container>
			<Header>Resources</Header>
			<List>
				{resources.map((resource, index) => (
					<ListItem key={index} onClick={resource.href} newTab>
						{resource.type === "link" ? (
							<Icon as={LinkIcon} />
						) : resource.type === "attachment" ? (
							<Icon as={AttachmentIcon} />
						) : null}
						{resource.label}
					</ListItem>
				))}
			</List>
		</Container>
	) : null

const Container = styled.div`
	width: 100%;
	grid-area: resources;
	background-color: white;
	border: 1px solid #e8e8ed;
	border-radius: 8px;
	overflow: hidden;
	align-self: flex-start;
`

const Header = styled.div`
	border-bottom: 1px solid #e8e8ed;
	color: #151d48;
	font-weight: 600;
	font-size: 20px;
	padding: 16px 24px;
`

const List = styled.div`
	max-height: 400px;
	overflow-y: auto;
	position: relative;
	padding: 8px 0;
`

const ListItem = styled(Button)`
	padding: 8px 24px;
	display: flex;
	align-items: center;
	gap: 8px;
`

const Icon = styled.div`
	width: 24px;
	height: 24px;
`

export default Resources
