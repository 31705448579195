import { PayPalScriptProvider, PayPalButtons as BaseButtons } from "@paypal/react-paypal-js"
import { type FC } from "react"

import { type ProductType } from "@forento/shared/models/product"

import { useAlert } from "~/contexts/AlertContext"
import { usePlatform } from "~/contexts/PlatformContext"
import { usePurchaseSuccessModal } from "~/contexts/PurchaseSuccessModalContext"
import trpc from "~/utilities/trpc"

interface Props {
	item: {
		id: number
		type: ProductType
		price: { amount: number; currency: string }
	}
	onPurchase(): void
}

const PayPalButtons: FC<Props> = ({ item, onPurchase }) => {
	const platform = usePlatform()
	const purchaseSuccesModal = usePurchaseSuccessModal()
	const alert = useAlert()

	if (platform.platform?.paymentGateway?.type !== "paypal") return null

	return (
		<PayPalScriptProvider
			options={{
				clientId: CONFIG.payPal.clientId,
				merchantId: platform.platform.paymentGateway.id,
				dataPartnerAttributionId: CONFIG.payPal.bnCode,
				currency: platform.platform.currency,
			}}
		>
			<BaseButtons
				style={{ layout: "horizontal", label: "buynow" }}
				createOrder={async () => {
					const order = await trpc.payment.paypal.createOrder.mutate({ item })
					return order.id
				}}
				onApprove={async (data, actions) => {
					const { status } = await trpc.payment.paypal.captureOrderByPayPalOrderId.mutate(data.orderID)
					if (status === "instrumentDeclined") {
						actions.restart()
						return
					}
					purchaseSuccesModal.show()
					onPurchase()
				}}
				onError={error => {
					console.error("PayPal payment failed!", error)
					alert.show("Payment failed", "PayPal payment failed. Please try again later.")
				}}
			/>
		</PayPalScriptProvider>
	)
}

export default PayPalButtons
