import { useState } from "react"
import { useDropzone } from "react-dropzone"
import styled from "styled-components"
import Values from "values.js"

import CommunityProfileAvatar from "@forento/shared/components/CommunityProfileAvatar"
import { getDataUrlByFile, supportedFileExtensions } from "@forento/shared/utilities/file"

import { useUser } from "~/contexts/UserContext"
import { CloseIcon, UploadIcon } from "~/themes/original/components/Icon"
import LoadingIndicator from "~/themes/original/components/LoadingIndicator"
import trpc from "~/utilities/trpc"

const ProfileAvatar: React.FC = () => {
	const user = useUser()

	const [isUploading, setUploading] = useState(false)

	const handleDrop = async (acceptedFiles: File[]) => {
		const file = acceptedFiles?.[0]
		if (file === undefined) return

		setUploading(true)
		try {
			await trpc.community.setAvatar.mutate({ dataUrl: await getDataUrlByFile(file) })
			await user.reload()
		} catch (error) {
			console.error(error)
		} finally {
			setUploading(false)
		}
	}

	const dropzone = useDropzone({
		onDrop: handleDrop,
		accept: Object.fromEntries(supportedFileExtensions.image.map(x => [`image/${x.toLowerCase()}`, []])),
	})

	return (
		<Container {...dropzone.getRootProps()}>
			<input {...dropzone.getInputProps()} />
			<CommunityProfileAvatar size={96} profile={user.user!}>
				<Upload $isVisible={dropzone.isDragActive || isUploading}>
					<StyledIcon
						as={isUploading ? LoadingIndicator : dropzone.isDragReject ? CloseIcon : UploadIcon}
						$isVisible={dropzone.isDragActive || isUploading}
					/>
				</Upload>
			</CommunityProfileAvatar>
		</Container>
	)
}

const Container = styled.div`
	cursor: pointer;
`

const StyledIcon = styled.div<{ $isVisible: boolean }>`
	width: 32px;
	height: 32px;
	transform: scale(${props => (props.$isVisible ? 1 : 0)});
	transition: transform 0.15s;
`

const Upload = styled.div<{ $isVisible: boolean }>`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	transition: 0.15s background-color;
	background-color: ${props => (props.$isVisible ? new Values("#f8f6f3").shade(20).hexString() : "transparent")};
	display: flex;
	justify-content: center;
	align-items: center;

	&:hover {
		background-color: ${new Values("#f8f6f3").shade(20).hexString()};

		${StyledIcon} {
			transform: scale(1);
		}
	}
`

export default ProfileAvatar
