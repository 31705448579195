import { createSWRProxyHooks } from "@trpc-swr/client"
import { createTRPCProxyClient, httpBatchLink, httpLink } from "@trpc/client"

import trpcTransformer from "@forento/shared/utilities/trpcTransformer"

import type { AppRoutes } from "../../../api/src/routes"

export const trpcOptions = {
	links: [CONFIG.isDevelopment ? httpLink({ url: "/api/_" }) : httpBatchLink({ url: "/api/_" })],
	transformer: trpcTransformer,
}

const trpc = createTRPCProxyClient<AppRoutes>(trpcOptions)

export const swr = createSWRProxyHooks<AppRoutes>(trpcOptions)

export default trpc
