import { type ReactNode } from "react"
import styled, { css } from "styled-components"

const AspectRatio: React.FC<{
	aspectRatio: number
	width?: { unit: "pixels" | "percent"; value: number }
	className?: string
	children: ReactNode
}> = ({ aspectRatio, width, className, children }) => {
	const widthString =
		width?.unit === "pixels" ? `${width.value}px` : width?.unit === "percent" ? `${width.value}%` : undefined
	const heightCss =
		width?.unit === "pixels"
			? `${width.value / aspectRatio}px`
			: width?.unit === "percent"
				? `${(1 / aspectRatio) * width.value}%`
				: `${(1 / aspectRatio) * 100}%`

	return (
		<Wrapper $widthCss={widthString} $heightCss={heightCss} className={className}>
			<Container>{children}</Container>
		</Wrapper>
	)
}

const Wrapper = styled.div<{ $widthCss?: string; $heightCss: string }>`
	position: relative;
	${props =>
		props.$widthCss !== undefined
			? css`
					width: ${props.$widthCss};
					padding-top: ${props.$heightCss};
				`
			: css`
					&:before {
						content: "";
						float: left;
						padding-top: ${props.$heightCss};
					}
				`}
`

const Container = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	> * {
		width: 100%;
		height: 100%;
	}
`

export default AspectRatio
