import { type FC, useState } from "react"

import { type CoursePageInputType } from "@forento/shared/models/course"

import InputField, { InputTextArea } from "~/themes/school/components/InputField"

type Props = {
	inputType: CoursePageInputType
	onChange(input: string): void
}

const InputAnswerType: FC<Props> = ({ inputType, onChange }) => {
	const [text, setText] = useState("")

	const handleChange = (value: string) => {
		setText(value)
		onChange(value)
	}

	if (inputType === "single-line")
		return <InputField placeholder="Enter input..." value={text} onChange={handleChange} />
	else if (inputType === "multi-line")
		return <InputTextArea placeholder="Enter input..." value={text} onChange={handleChange} />
	else return null
}

export default InputAnswerType
