import { type PublicPlatform } from "@forento/shared/models/platform"
import routes from "@forento/shared/routes"

export function getHomeRoute(platform: PublicPlatform) {
	if (platform.content.courses) return routes.platform.course.index()
	if (platform.content.exams) return routes.platform.exam.index()
	if (platform.content.videos) return routes.platform.video.index()
	if (platform.content.downloadables) return routes.platform.downloadable.index()
	if (platform.content.events) return routes.platform.event.index()
	if (platform.content.memberships) return routes.platform.membership.index()

	return routes.platform.course.index()
}

export default routes.platform
