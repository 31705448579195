import styled from "styled-components"
import Values from "values.js"

import AspectRatio from "@forento/shared/components/AspectRatio"
import { type PersonalizedVideo } from "@forento/shared/models/video"

import Button from "~/themes/original/components/Button"
import PurchaseItem from "~/themes/original/components/PurchaseItem"
import routes from "~/utilities/routes"
import { lightTextColor } from "~/utilities/styles"

const Hero: React.FC<{ video: PersonalizedVideo; reloadVideo(): void }> = ({ video, reloadVideo }) => (
	<Container>
		<Details>
			<Title>{video.title}</Title>
			{video.longDescription && <Description dangerouslySetInnerHTML={{ __html: video.longDescription.html }} />}
			{video.status === "membership-required" ? (
				<>
					<MembershipText>This video is only avaiable through a membership.</MembershipText>
					<Button variant="primary" onClick={routes.membership.index()}>
						Memberships
					</Button>
				</>
			) : video.price !== null ? (
				<PurchaseItem item={{ type: "video", id: video.id, price: video.price }} onPurchase={reloadVideo} />
			) : video.isSignInRequired ? (
				<>
					<MembershipText>This video is only available to signed in users.</MembershipText>
					<ButtonsRow>
						<Button variant="primary" onClick={routes.user.signin()}>
							Sign in
						</Button>
						<Button variant="primary" onClick={routes.user.signup()}>
							Sign up
						</Button>
					</ButtonsRow>
				</>
			) : null}
		</Details>
		<ThumbnailContainer>
			<AspectRatio width={{ value: 100, unit: "percent" }} aspectRatio={16 / 9}>
				{video.thumbnailFilePath !== null && <Thumbnail src={video.thumbnailFilePath} />}
			</AspectRatio>
		</ThumbnailContainer>
	</Container>
)

const Container = styled.div`
	background-color: ${props => new Values(props.theme.primaryColor).tint(90).hexString()};
	border-radius: 16px;
	display: flex;
	padding: 42px;
	gap: ${42 * 2}px;
	margin-bottom: 42px;
`

const Details = styled.div`
	flex: 1 0 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 22px;
`

const Title = styled.h1`
	font-weight: 500;
	font-size: 24px;
	color: #151d48;
`

const Description = styled.p`
	font-size: 16px;
	color: ${lightTextColor};
`

const MembershipText = styled.p`
	font-size: 16px;
	color: ${lightTextColor};
	font-style: italic;
`

const ThumbnailContainer = styled.div`
	flex: 1 0 0;
	border-radius: 8px;
	overflow: hidden;

	@media (max-width: 999px) {
		display: none;
	}
`

const Thumbnail = styled.img.attrs({ alt: "Video thumbnail" })`
	object-fit: cover;
`

const ButtonsRow = styled.div`
	display: flex;
	gap: 16px;
`

export default Hero
