import dayjs from "dayjs"
import generateIcal from "generate-ical"
import { type FC } from "react"
import styled from "styled-components"

import { type PersonalizedEvent } from "@forento/shared/models/event"

import { usePlatform } from "~/contexts/PlatformContext"
import Button from "~/themes/original/components/Button"
import routes from "~/utilities/routes"

const AddToCalendar: FC<{ event: PersonalizedEvent }> = ({ event }) => {
	const platform = usePlatform().platform!

	const link =
		(event.isUnlocked ? event.videoCallLink : null) ?? platform.domain.platform + routes.event.detail(event.id)
	const description = event.longDescription ? `${event.longDescription.plain}\n\n${link}` : link

	const googleParams = new URLSearchParams({
		action: "TEMPLATE",
		text: event.title,
		details: description,
		dates: `${dayjs(event.startDate).format("YYYYMMDDTHHmmSSZ")}/${dayjs(event.endDate).format(
			"YYYYMMDDTHHmmSSZ",
		)}`,
		...(event.location && { location: event.location }),
	})
	const outlookParams = new URLSearchParams({
		rru: "addevent",
		subject: event.title,
		body: description,
		startdt: event.startDate.toISOString(),
		enddt: event.endDate.toISOString(),
		...(event.location && { location: event.location }),
	})
	return (
		<Container>
			<Button variant="bordered" onClick={`https://calendar.google.com/calendar/render?${googleParams}`} newTab>
				Add to Google Calendar
			</Button>
			<Button
				variant="bordered"
				onClick={`https://outlook.office.com/calendar/0/deeplink/compose?${outlookParams}`}
				newTab
			>
				Add to Outlook
			</Button>
			<Button
				variant="bordered"
				onClick={() => {
					const ical = generateIcal({
						title: event.title,
						description: {
							plain: event.longDescription?.plain ?? "",
							html: event.longDescription?.html,
						},
						isAllDay: false,
						startDate: event.startDate,
						endDate: event.endDate,
						location: event.location !== null ? { title: event.location } : undefined,
						url: link,
						organizer: { name: platform.name },
					})
					const element = document.createElement("a")
					element.setAttribute("href", encodeURI(`data:text/calendar;charset=utf-8,${ical}`))
					element.setAttribute("download", "Users.csv")
					document.body.appendChild(element)
					element.click()
					document.body.removeChild(element)
				}}
			>
				Add to calendar
			</Button>
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	gap: 16px;
	margin-top: 16px;
`

export default AddToCalendar
