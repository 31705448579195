import { type FC } from "react"
import styled from "styled-components"

import Button from "~/themes/school/components/Button"
import { AttachmentIcon, LinkIcon } from "~/themes/school/components/Icon"

type Props = { resources: { type: "link" | "attachment"; label: string; href: string }[] }

const Resources: FC<Props> = ({ resources }) => (
	<Container>
		{resources.map((resource, index) => (
			<Item key={index} onClick={resource.href} newTab>
				{resource.type === "link" ? (
					<Icon as={LinkIcon} />
				) : resource.type === "attachment" ? (
					<Icon as={AttachmentIcon} />
				) : null}
				{resource.label}
			</Item>
		))}
	</Container>
)

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const Item = styled(Button)`
	background-color: white;
	color: black;
	border: 1px solid black;
	padding: 8px 32px;
	border-radius: 10px;
	display: flex;
	align-items: center;
	gap: 8px;
`

const Icon = styled.div`
	width: 24px;
	height: 24px;
`

export default Resources
