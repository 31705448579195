import { type FC } from "react"
import { Navigate, useNavigate, useParams } from "react-router"
import styled from "styled-components"

import { type EventResponse } from "@forento/shared/models/event"
import { toDateTimeString } from "@forento/shared/utilities/date"
import { parseNumber } from "@forento/shared/utilities/number"

import { PurchaseSuccessModalContextProvider } from "~/contexts/PurchaseSuccessModalContext"
import { useUser } from "~/contexts/UserContext"
import Button from "~/themes/original/components/Button"
import Layout from "~/themes/original/components/Layout"
import PartialLoadingPage from "~/themes/original/components/PartialLoadingPage"
import PurchaseItem from "~/themes/original/components/PurchaseItem"
import { toRelativeDateString } from "~/themes/original/utilities/date"
import routes from "~/utilities/routes"
import trpc, { swr } from "~/utilities/trpc"

import AddToCalendar from "./AddToCalendar"

const EventPage: FC = () => {
	const eventId = parseNumber(useParams().eventId ?? "")
	const user = useUser()
	const navigate = useNavigate()

	const { data: event, error, mutate } = swr.event.getPersonalized.useSWR(eventId ?? -1)

	if (event === null) return <Navigate to={routes.event.index()} />

	async function handleRespond(response: EventResponse | null) {
		if (eventId === null) return
		if (user.user === null) {
			return navigate(`${routes.user.signup()}?next=${encodeURIComponent(location.pathname)}`)
		}

		await trpc.event.setResponse.mutate({ eventId, response })
		await mutate()
	}

	return (
		<PurchaseSuccessModalContextProvider
			content={{
				type: "event",
				isUnlocked: event?.isUnlocked ?? false,
				payPalOrderId: event?.isUnlocked && event.order?.type === "paypal" ? event.order.id : null,
			}}
		>
			<Layout>
				{error ? (
					<p>Failed to load event.</p>
				) : event === undefined ? (
					<PartialLoadingPage />
				) : (
					<Container>
						<Title>{event.title}</Title>
						<DateTime>
							{toDateTimeString(event.startDate)} ({toRelativeDateString(event.startDate)})
						</DateTime>
						{event.longDescription && (
							<Description dangerouslySetInnerHTML={{ __html: event.longDescription.html }} />
						)}
						{!event.isUnlocked ? (
							<PurchaseItem
								item={{ id: event.id, price: event.price, type: "event" }}
								onPurchase={mutate}
							/>
						) : event.response === "attending" ? (
							<>
								<Actions>
									{event.videoCallLink !== null && (
										<Button variant="primary" onClick={event.videoCallLink} newTab>
											Enter event
										</Button>
									)}
									<Button key="cancel" variant="danger-bordered" onClick={() => handleRespond(null)}>
										Cancel attendance
									</Button>
								</Actions>
								<AddToCalendar event={event} />
							</>
						) : (
							<Actions>
								<Button key="attend" variant="primary" onClick={() => handleRespond("attending")}>
									Attend event
								</Button>
								{event.response !== "interested" && (
									<Button
										key="interested"
										variant="secondary"
										onClick={() => handleRespond("interested")}
									>
										I'm interested
									</Button>
								)}
								{event.response !== "not-interested" && (
									<Button
										key="not-interested"
										variant="secondary"
										onClick={() => handleRespond("not-interested")}
									>
										I'm not interested
									</Button>
								)}
							</Actions>
						)}
					</Container>
				)}
			</Layout>
		</PurchaseSuccessModalContextProvider>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`

const Title = styled.h1`
	margin-bottom: 8px;
`

const DateTime = styled.time`
	margin-bottom: 16px;
`

const Description = styled.div`
	margin-bottom: 16px;
`

const Actions = styled.div`
	display: flex;
	gap: 16px;
`

export default EventPage
