import styled from "styled-components"

import { type CoursePageAttachment, type CoursePageLink } from "@forento/shared/models/course"

import Button from "~/themes/original/components/Button"
import { AttachmentIcon, LinkIcon } from "~/themes/original/components/Icon"
import { lightTextColor } from "~/utilities/styles"

const Attachments: React.FC<{ links: CoursePageLink[]; attachments: CoursePageAttachment[] }> = ({
	links,
	attachments,
}) => {
	if (links.length === 0 && attachments.length === 0) return null

	return (
		<Container>
			{links.map(link => (
				<Item key={`link-${link.id}`} onClick={link.url} newTab>
					<Icon as={LinkIcon} />
					{link.label}
				</Item>
			))}
			{attachments.map(attachment => (
				<Item key={`attachment-${attachment.id}`} onClick={attachment.url} newTab>
					<Icon as={AttachmentIcon} />
					{attachment.label}
				</Item>
			))}
		</Container>
	)
}

const Container = styled.div`
	font-size: 16px;
	color: ${lightTextColor};
	margin-bottom: 32px;
	display: flex;
	flex-direction: column;
	gap: 8px;

	@media (min-width: 1000px) {
		flex-direction: row;
		flex-wrap: wrap;
	}
`

const Item = styled(Button)`
	background-color: white;
	border-radius: 8px;
	padding: 12px 24px;
	display: flex;
	align-items: center;
	gap: 4px;

	@media (max-width: 999px) {
		width: 100%;
		justify-content: center;
	}
`

const Icon = styled.div`
	width: 24px;
	height: 24px;
`

export default Attachments
