import { useEffect } from "react"
import { Navigate, useParams } from "react-router"
import styled from "styled-components"

import VideoPlayer from "@forento/shared/components/VideoPlayer"

import { PurchaseSuccessModalContextProvider } from "~/contexts/PurchaseSuccessModalContext"
import { useUser } from "~/contexts/UserContext"
import Layout from "~/themes/original/components/Layout"
import PartialLoadingPage from "~/themes/original/components/PartialLoadingPage"
import routes from "~/utilities/routes"
import { lightTextColor } from "~/utilities/styles"
import { swr } from "~/utilities/trpc"

import Hero from "./Hero"

const VideoPage: React.FC = () => {
	const { videoId } = useParams()
	const user = useUser()

	const { data: video, error, mutate } = swr.video.getPersonalized.useSWR({ videoId: Number(videoId) })

	useEffect(() => {
		mutate()
	}, [mutate, user.user])

	if (video === null) return <Navigate to={routes.video.index()} />

	return (
		<Layout>
			{error ? (
				<p>Failed to load video.</p>
			) : video === undefined ? (
				<PartialLoadingPage />
			) : (
				<PurchaseSuccessModalContextProvider
					content={{
						type: "video",
						isUnlocked: video.status === "unlocked",
						payPalOrderId:
							video.status === "unlocked" &&
							video.unlockedCause === "purchased" &&
							video.order.type === "paypal"
								? video.order.id
								: null,
					}}
				>
					{video.status === "unlocked" ? (
						<>
							<StyledVideoPlayer video={video} />
							<Title>{video.title}</Title>
							{video.longDescription && (
								<Description dangerouslySetInnerHTML={{ __html: video.longDescription.html }} />
							)}
						</>
					) : (
						<Hero video={video} reloadVideo={mutate} />
					)}
				</PurchaseSuccessModalContextProvider>
			)}
		</Layout>
	)
}

const StyledVideoPlayer = styled(VideoPlayer)`
	background-color: #999;
	margin-bottom: 16px;
`

const Title = styled.h1`
	font-weight: bold;
	font-size: 28px;
	color: #444a6d;
	margin-bottom: 12px;

	@media (max-width: 999px) {
		padding: 0 16px;
	}
`

const Description = styled.div`
	font-size: 16px;
	color: ${lightTextColor};

	@media (max-width: 999px) {
		padding: 0 16px;
	}
`

export default VideoPage
