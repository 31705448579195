import styled from "styled-components"

import { useUser } from "~/contexts/UserContext"
import Button from "~/themes/original/components/Button"
import { BillingIcon, LogoutIcon, SettingsIcon, UsersIcon } from "~/themes/original/components/Icon"
import routes from "~/utilities/routes"

import useNavigationItems from "./useNavigationItems"

const NavigationMenu: React.FC<{ isOpen: boolean }> = ({ isOpen }) => {
	const user = useUser()

	const navigationItems = useNavigationItems()

	return (
		<Container $isOpen={isOpen}>
			{navigationItems.map(item => (
				<StyledButton key={item.label} onClick={item.onClick}>
					<StyledIcon as={item.icon} />
					{item.label}
				</StyledButton>
			))}
			{user.user ? (
				<>
					<StyledButton onClick={routes.billing.index()}>
						<StyledIcon as={BillingIcon} />
						Billing
					</StyledButton>
					<StyledButton onClick={routes.user.settings()}>
						<StyledIcon as={SettingsIcon} />
						Settings
					</StyledButton>

					<StyledButton onClick={user.signOut}>
						<StyledIcon as={LogoutIcon} />
						Sign out
					</StyledButton>
				</>
			) : (
				<StyledButton onClick={routes.user.signin()}>
					<StyledIcon as={UsersIcon} />
					Sign in
				</StyledButton>
			)}
		</Container>
	)
}

const Container = styled.nav<{ $isOpen: boolean }>`
	position: absolute;
	background-color: #f7f4fa;
	top: 56px;
	left: 0;
	right: 0;
	min-height: calc(100vh - 56px);
	padding: 22px;
	transform-origin: top;
	transform: ${props => (props.$isOpen ? "translateX(0)" : "translateX(-100%)")};
	transition: transform 0.15s;

	@media (min-width: 1000px) {
		display: none;
	}
`

const StyledButton = styled(Button)`
	width: 100%;
	font-size: 16px;
	display: flex;
	align-items: center;
	gap: 16px;
	padding: 12px 0;
`

const StyledIcon = styled.div`
	width: 24px;
	height: 24px;
`

export default NavigationMenu
