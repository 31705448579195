export const removeNonDigits = (string: string): string => string.replace(/\D/g, "")

export const removeDashes = (string: string): string => string.replace(/-/g, "")

const escapeRegularExpressions = (string: string): string => string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")

export const replaceAll = (string: string, oldValue: string, newValue: string) =>
	string.replace(new RegExp(escapeRegularExpressions(oldValue), "g"), newValue)

export const toNullIfEmpty = (string: string): string | null => (string.length > 0 ? string : null)

export function toSlug(text: string): string {
	return text
		.normalize("NFD")
		.toLowerCase()
		.replace(/\s+/g, "-")
		.replace(/[^a-z0-9-]/g, "")
}

export const replacePlaceholders = ({
	text,
	placeholders,
	surroundingChars = { start: "{", end: "}" },
}: {
	text: string
	placeholders: { key: string; value: string }[]
	surroundingChars?: { start: string; end: string }
}) =>
	placeholders.reduce(
		(value, current) =>
			replaceAll(value, surroundingChars.start + current.key + surroundingChars.end, current.value),
		text,
	)

export function getAlphabetString(index: number): string {
	const alphabet = [
		"A",
		"B",
		"C",
		"D",
		"E",
		"F",
		"G",
		"H",
		"I",
		"J",
		"K",
		"L",
		"M",
		"N",
		"O",
		"P",
		"Q",
		"R",
		"S",
		"T",
		"U",
		"V",
		"W",
		"X",
		"Y",
		"Z",
	]
	return alphabet[index % alphabet.length]
}
