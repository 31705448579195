import { type FC } from "react"
import styled from "styled-components"

import { useUser } from "~/contexts/UserContext"
import { DeleteIcon } from "~/themes/original/components/Icon"
import MoreActions from "~/themes/original/components/MoreActions"
import { toRelativeDateString } from "~/themes/original/utilities/date"
import { lightTextColor } from "~/utilities/styles"

interface Props {
	comment: {
		user: { id: number; firstName: string; lastName: string }
		page: { chapter: { title: string }; title: string } | null
		text: string
		createDate: Date
	}
	onDelete(): void
}

const Comment: FC<Props> = ({ comment, onDelete }) => {
	const user = useUser().user

	return (
		<Container>
			<Header>
				<HeaderGroup>
					<Author>
						{comment.user.firstName} {comment.user.lastName}
					</Author>
					{comment.page !== null && (
						<>
							•
							<Page>
								{comment.page.chapter.title} &gt; {comment.page.title}
							</Page>
						</>
					)}
				</HeaderGroup>
				{(user?.isCreator || user?.id === comment.user.id) && (
					<MoreActions actions={[{ label: "Delete", icon: DeleteIcon, isDanger: true, onClick: onDelete }]} />
				)}
			</Header>
			<Text>{comment.text}</Text>
			<Date>{toRelativeDateString(comment.createDate)}</Date>
		</Container>
	)
}

const Container = styled.div`
	background-color: white;
	padding: 8px 16px;
	border-radius: 8px;
`

const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 8px;
	margin-bottom: 4px;
`

const HeaderGroup = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`

const Author = styled.span`
	font-weight: 600;
`

const Page = styled.span`
	color: ${lightTextColor};
`

const Text = styled.div`
	color: ${lightTextColor};
	margin-bottom: 4px;
`

const Date = styled.p`
	color: ${lightTextColor};
	font-size: 14px;
`

export default Comment
