import { type FC } from "react"

import Tooltip from "@forento/shared/components/Tooltip"
import { type PublicMembership } from "@forento/shared/models/membership"
import { type SubscriptionPaymentInterval } from "@forento/shared/models/payment"
import { toPriceString } from "@forento/shared/utilities/currency"

import { usePlatform } from "~/contexts/PlatformContext"
import { useUser } from "~/contexts/UserContext"
import Button from "~/themes/original/components/Button"

type Props = {
	price: { id: number; amount: number; currency: string; paymentInterval: SubscriptionPaymentInterval }
	membership: PublicMembership
	onSelect(): void
	onCancel(): void
	submittingPriceId: number | null
}
const PriceButton: FC<Props> = ({ price, membership, onSelect, onCancel, submittingPriceId }) => {
	const platform = usePlatform().platform!
	const user = useUser().user

	if (user?.membership?.type === "external") {
		return (
			<Button key={price.id} variant="bordered" isDisabled>
				{user.membership.id === membership.id ? "Active" : "Managed externally"}
			</Button>
		)
	}
	const isActiveMembership =
		user?.membership?.id === membership.id &&
		(user.membership.endDate === null || user.membership.endDate > new Date())
	const isActivePrice = user?.membership?.priceId === price.id
	const isMembershipRenewing = user?.membership?.endDate === null

	if (!platform.paymentGateway?.isEnabled) {
		return (
			<Tooltip key={price.id} tooltip="Payments are not enabled">
				<Button variant="primary" isDisabled>
					{toPriceString(price)}/{price.paymentInterval}
				</Button>
			</Tooltip>
		)
	}

	const options = (() => {
		if (!isActiveMembership) {
			return { variant: "primary" as const, text: `${toPriceString(price)}/${price.paymentInterval}` }
		}
		if (!isActivePrice) {
			return { variant: "bordered" as const, text: `Switch to ${toPriceString(price)}/${price.paymentInterval}` }
		}
		if (isMembershipRenewing) {
			return { variant: "danger" as const, text: "Cancel membership" }
		}
		return { variant: "primary" as const, text: "Resume membership" }
	})()

	return (
		<Button
			key={price.id}
			variant={options.variant}
			onClick={isActivePrice && isMembershipRenewing ? onCancel : onSelect}
			isLoading={submittingPriceId === price.id}
			isDisabled={submittingPriceId !== null}
		>
			{options.text}
		</Button>
	)
}

export default PriceButton
