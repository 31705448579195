import { type FC } from "react"
import { useLocation } from "react-router"
import styled, { css } from "styled-components"

import Button from "~/themes/original/components/Button"
import routes from "~/utilities/routes"
import { lightTextColor } from "~/utilities/styles"

const navItems = [
	{ label: "Terms of service", onClick: routes.policy.termsOfService() },
	{ label: "Privacy policy", onClick: routes.policy.privacy() },
]

const Footer: FC = () => {
	const location = useLocation()

	return (
		<Container>
			{navItems.map(item => (
				<NavItem key={item.label} onClick={item.onClick} isActive={location.pathname === item.onClick}>
					{item.label}
				</NavItem>
			))}
		</Container>
	)
}

const Container = styled.div`
	padding: 42px;
	display: flex;
	align-items: center;
	gap: 16px;

	@media (max-width: 449px) {
		flex-direction: column;
	}
`

const NavItem = styled(Button)<{ isActive?: boolean }>`
	font-size: 16px;
	color: ${lightTextColor};
	letter-spacing: 0.6px;

	${props =>
		props.isActive &&
		css`
			font-weight: 700;
			letter-spacing: 0;
			color: ${props => props.theme.primaryColor};
		`}
`

export default Footer
