import styled from "styled-components"

import CommunityProfileAvatar from "@forento/shared/components/CommunityProfileAvatar"
import { type Conversation } from "@forento/shared/models/community"
import { toRelativeDateString } from "@forento/shared/utilities/date"

import Button from "~/themes/school/components/Button"
import { backgroundColor } from "~/themes/school/styles"
import { useLocale } from "~/translations"
import routes from "~/utilities/routes"
import { lightTextColor } from "~/utilities/styles"

const ConversationListItem: React.FC<{ conversation: Conversation }> = ({ conversation }) => {
	const locale = useLocale()

	return (
		<Container onClick={routes.community.conversation(conversation.otherProfile.id)}>
			<Group>
				<CommunityProfileAvatar profile={conversation.otherProfile} />
				<div>
					<Name>
						{conversation.otherProfile.firstName} {conversation.otherProfile.lastName}
					</Name>
					<LastMessage>{conversation.lastMessage.text}</LastMessage>
				</div>
			</Group>
			<Date>{toRelativeDateString(conversation.lastMessage.sendDate, locale)}</Date>
		</Container>
	)
}

const Container = styled(Button)`
	width: 100%;
	padding: 24px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 16px;

	&:not(:last-child) {
		border-bottom: 1px solid ${backgroundColor};
	}
`

const Group = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 16px;
`

const Name = styled.h2`
	font-weight: 500;
	font-size: 18px;
	color: #444a6d;
	margin-bottom: 2px;
`

const LastMessage = styled.p`
	font-size: 16px;
	color: ${lightTextColor};
`

const Date = styled.p`
	font-size: 16px;
	color: #d0d2da;
`

export default ConversationListItem
