import { type FC, useState } from "react"
import styled from "styled-components"

import CommunityProfileAvatar from "@forento/shared/components/CommunityProfileAvatar"
import { type CommunityPostReaction } from "@forento/shared/models/community"

import ProfilePopup from "../ProfilePopup"

const Reaction: FC<{ reaction: CommunityPostReaction }> = ({ reaction }) => {
	const [authorElement, setAuthorElement] = useState<HTMLDivElement | null>(null)

	return (
		<Container>
			<ProfilePopup authorElement={authorElement} profile={reaction.profile} />
			<CommunityProfileAvatar size={32} profile={reaction.profile} ref={setAuthorElement} />
			<p key={reaction.profile.id}>
				{reaction.profile.firstName} {reaction.profile.lastName}
			</p>
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`

export default Reaction
